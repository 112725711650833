import axios, { AxiosRequestConfig } from "axios";
import { useEffect } from "react";
import {useHistory, useLocation} from "react-router-dom";
import UserHeader from "../components/UserHeader"
const API_URL = process.env.REACT_APP_API_URL || "";


export default function ConfirmPayment(){
    //take parametrs from url
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const confirmation_id = query.get("confirmation_id");
    const history = useHistory();
    useEffect(()=>{
        const BookingId = sessionStorage.getItem("BookingId");
        let options:AxiosRequestConfig = {
            method: "POST",
            url: `${API_URL}/v1/book/update_booking`,
            data: {
                confirmation_id,
                order_id: BookingId,
                coupon : sessionStorage.getItem("CouponCode"),
            }
        }
        axios(options)
            .then((res) => {
                if(res.data.payment_status){
                    history.replace({
                        pathname: "/paymentconfirm",
                        search: `?confirmation_id=${confirmation_id}&booking_id=${BookingId}`,
                    });
                }
                else{
                    history.replace({
                        pathname: "/bookingcancelled",
                        search: `?confirmation_id=${confirmation_id}`,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    })
    return <div >
        <UserHeader />
        <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
                <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
                </div>
                <h1 className="mt-3">Confirming Payment..</h1>
            </div>
        </div>
    </div>
}