import React from "react";
import UserHeader from "../../app/modules/user/components/UserHeader";

// Define the type of the props using React.PropsWithChildren
const Layout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div>
      <UserHeader /> {/* Static header */}
      <main>{children}</main> {/* Dynamic content */}
      {/* <UserFooter /> */}
    </div>
  );
};

export default React.memo(Layout);