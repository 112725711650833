import React from "react";
import "../User.scss";
import "./userHeader.scss";
import { Link } from "react-router-dom";
import {
	Button,
	Carousel as Multicarousel,
	Col,
	Container,
	Form,
	Modal,
	Nav,
	Navbar,
	NavDropdown,
	Row,
	Tab,
	Dropdown,
} from "react-bootstrap-v5";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { useState, useEffect, useRef, useReducer } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { colors } from "react-select/dist/declarations/src/theme";

const API_URL = process.env.REACT_APP_API_URL || "";

const initialValues = {
	number: "",
};

const signUpSchema = Yup.object({
	number: Yup.string()
		.required("Mobile Number is Required")
		.max(10, "Enter Valid Mobile Number")
		.phone("IN", true, "Phone Number is Invalid"),
});

export default function UserHeader() {
	const [show, setShow] = useState(false);
	const [resMsg, setResMsg] = useState<string>(
		"Please Enter your Mobile Number for Login"
	);
	const [resStatus, setResStatus] = useState("");
	const [userId, setUserId] = useState<any>("");
	const [userNum, setUserNum] = useState<string>("");
	const [localUserId, setLocalUserId] = useState<any>("");
	const [verifyOtp, setVerifyOtp] = useState("");
	const [userName, setUserName] = useState<any>("");
	// const [searchBar, setSearchBar] = useState<boolean>(false);

	// ========== Click Outside Navbar Closed Code Start's here ===============
	const [isExpanded, setIsExpanded] = useState(false);
	const navbarRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// setUserName(localStorage.getItem("UserFirstname"));
		if (localStorage.getItem("UserFirstname")) {
			setUserName(localStorage.getItem("UserFirstname"));
		}
		const handleOutsideClick = (event: MouseEvent) => {
			if (
				navbarRef.current &&
				!navbarRef.current.contains(event.target as Node)
			) {
				setIsExpanded(false);
			}
		};
		document.addEventListener("click", handleOutsideClick);
		return () => {
			document.removeEventListener("click", handleOutsideClick);
		};
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			if (isExpanded) {
				setIsExpanded(false);
			}
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [isExpanded]);
	// ========== Click Outside Navbar Closed Code End's here ============

	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			initialValues: initialValues,
			validationSchema: signUpSchema,

			onSubmit: (values, action) => {
				axios
					.post(`${API_URL}/v1/customer/register_customer`, {
						mobile: values.number,
					})
					.then((res) => {
						// console.log(res.data, values.number, res.data.data);
						setResMsg(
							"An OTP has been sent to your mobile. Please enter it to verify"
						);
						// setResMsg(res.data.message)
						setResStatus(res.data.status);
						setUserNum(values.number);
						setUserId(res.data.data);
						// console.log(res.data.data, "userid")
					});
				// action.resetForm()   //to clear the user info after submitting the form
			},
		});

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleOtpVerify = () => {
		const data = {
			mobile: userNum,
			otp: verifyOtp,
		};
		axios.post(`${API_URL}/v1/customer/verify_customer`, data).then((res) => {
			setResMsg(res.data.message);

			if (res.data.status === "Success") {
				res.data.data.map((value: any) => {
					return (
						value.firstname
							? localStorage.setItem("UserFirstname", value.firstname)
							: "",
						value.lastname
							? localStorage.setItem("UserLastname", value.lastname)
							: "",
						value.email ? localStorage.setItem("UserEmail", value.email) : ""
					);
				});
			}

			if (res.data.status === "Success") {
				localStorage.setItem("UserNum", userNum);
				localStorage.setItem("UserId", userId);
				setTimeout(handleClose, 1500);
			}
		});
	};

	const handleResendOtp = () => {
		setResMsg("");
		axios
			.post(`${API_URL}/v1/customer/register_customer`, { mobile: userNum })
			.then((res) => {
				setResMsg("OTP Sent to Your Mobile Number");
			});
	};

	useEffect(() => {
		setLocalUserId(localStorage.getItem("UserId"));
	});

	// ================ Only Number can Type IN Input Field ==============
	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		// Allow only numbers and backspace
		if (!/^\d$/.test(e.key) && e.key !== "Backspace") {
			e.preventDefault();
		}
	};
	// ================ Only Number can Paste in Input Field ==============
	const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		// Prevent pasting any text
		e.preventDefault();
		// Get the pasted text
		const pastedText = e.clipboardData.getData("text");

		// Filter out non-numeric characters
		const numericText = pastedText.replace(/[^\d]/g, "");

		// Set the filtered text back into the input field
		document.execCommand("insertText", false, numericText);
	};

	// ================ Only Number can Type IN Input Field ==============

	// const ShowSearchBar = () => {
	//   setSearchBar(!searchBar)
	// }

	const handleUserLogout = () => {
		localStorage.removeItem("UserId");
		localStorage.clear();

		const url = `/home`;
		window.location.href = url;
	};

	const [profilePic, setProfilePic] = useState<any>("");
	const usersId = localStorage.getItem("UserId") || "";
	const getProfileData = () => {
		axios
			.get(`${API_URL}/v1/book/booking_by_cat?userId=${usersId}`)
			.then((res) => {
				// console.log(res.data.user_image)
				setProfilePic(res.data.user_image);
			});
	};

	useEffect(() => {
		if (usersId) getProfileData();
	}, [usersId]);

	return (
		<>
			<div className="fixed-top-nav">
				<div className="nav-top">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="nav-top-bar">
									<a 
										href="http://vendor.myresorts.in/"
										className="partner-login-bt"
										target="_blank"
										rel="noopener noreferrer"
									>
										Partner Login
									</a>
									<a href="tel:+91-9325922961">+91-9325922961 </a>
									<a href="mailto:contact@myresorts.in">contact@myresorts.in</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="navigation navigation-bar">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<Navbar
									collapseOnSelect
									expand="lg"
									expanded={isExpanded}
									onToggle={() => setIsExpanded(!isExpanded)}
									ref={navbarRef}
								>
									<Container className="cont-manu">
										{/* <Navbar.Brand href="/home">
											<img
												src={toAbsoluteUrl("/media/img/logo.svg")}
												alt="Myresorts.in"
											/>
										</Navbar.Brand> */}
										<Navbar.Brand>
											<Link to="/home">
											<img
												src={toAbsoluteUrl("/media/img/logo.svg")}
												alt="Myresorts.in"
											/>
											</Link>
										</Navbar.Brand>
										<Navbar.Toggle aria-controls="responsive-navbar-nav" />

										<Navbar.Collapse id="responsive-navbar-nav show">
											<Nav className="me-auto mid-nav">
												{/* <Nav.Link href="/user">Home</Nav.Link>
												<Nav.Link href="/rooms">Find Resorts</Nav.Link>
												<Nav.Link href="/blog">Blog</Nav.Link>
												<Nav.Link href="/contactus#faq">FAQ's</Nav.Link>
												{/* <Nav.Link href="#pricing">Gallery</Nav.Link> */}
												{/*<Nav.Link href="/contactus">Contact Us</Nav.Link> */}
												<Nav.Link><Link to="/home">Home</Link></Nav.Link>
												<Nav.Link><Link to="/rooms">Find Resorts</Link></Nav.Link>
												<Nav.Link><Link to="/blog">Blog</Link></Nav.Link>
												<Nav.Link><Link to="/contactus#faq">FAQ's</Link></Nav.Link>
												{/* <Nav.Link href="#pricing">Gallery</Nav.Link> */}
												<Nav.Link><Link to="/contactus">Contact Us</Link></Nav.Link>
											</Nav>
											<Nav>
												{/* <Nav.Link eventKey={2} href="/profile" style={{ display: `${localUserId ? "block" : "none"}`, transform: "translateY(5px)" }}>
                          <img
                            className="d-block profile"
                            src={toAbsoluteUrl("/media/img/profile.svg")}
                            alt="First slide"
                          />
                        </Nav.Link> */}

												<div className="logout-section">
													<div className="search-container">
														<form>
															<input
																className="search expandright"
																id="searchright"
																type="search"
																name="q"
																placeholder="Search"
															/>
															<label
																className="button searchbutton"
																htmlFor="searchright"
															>
																<span className="mglass">&#9906;</span>
															</label>
														</form>
													</div>

													<Dropdown
														style={{
															display: `${localUserId ? "block" : "none"}`,
															transform: "translateY(10px)",
														}}
													>
														<Dropdown.Toggle variant="" id="dropdown-basic">
															<div
																style={{
																	display: "flex",
																	flexDirection: "row",
																}}
															>
																<img
																	className="d-block profile"
																	src={
																		profilePic !== ""
																			? profilePic
																			: toAbsoluteUrl(
																					"/media/img/profile-vector-1.svg"
																			  )
																	}
																	alt="First slide"
																/>
																<label
																	style={{
																		fontSize: "14px",
																		color: "#009933",
																		textTransform: "capitalize",
																	}}
																>
																	{userName}
																</label>
															</div>
														</Dropdown.Toggle>
														<Dropdown.Menu className="dropdown-menu-container">
															{/* <Dropdown.Item href="/profile">
																Profile
															</Dropdown.Item> */}
															<Dropdown.Item>
																<Link to="/profile" style={{color: "#181C32"}}>
																	Profile
																</Link>
															</Dropdown.Item>
															<Dropdown.Item onClick={handleUserLogout}>
																Logout
															</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>

													<Nav.Link
														eventKey={2}
														href="#memes"
														style={{
															display: `${localUserId ? "none" : "block"}`,
														}}
													>
														<a
															href="#!"
															className="login-bt"
															onClick={handleShow}
														>
															Login / SignUp
														</a>
													</Nav.Link>
												</div>
											</Nav>
										</Navbar.Collapse>
									</Container>
								</Navbar>

								<Modal show={show} onHide={handleClose} centered size="lg">
									<Modal.Body className="loginModal">
										<div className="login-body-content">
											{/* <div className="social-login">
												<div className="soc-ic">
												<a href="#">
													<img
													src={toAbsoluteUrl("/media/img/google-icon.svg")}
													alt=""
													/>
												</a>
												<a href="#">
													<img
													src={toAbsoluteUrl("/media/img/facebook-icon.svg")}
													alt=""
													/>
												</a>
												</div>
												<p>or use your email account :</p>
											</div> */}

											{<div className="res-msg">{resMsg}</div>}

											{resStatus === "" ? (
												<>
													<form onSubmit={handleSubmit}>
														<div className="login-form-user">
															<div className="input-box">
																<img
																	src={toAbsoluteUrl("/media/img/user.svg")}
																	alt=""
																/>
																<input
																	type="text"
																	autoComplete="off"
																	name="number"
																	id="number"
																	placeholder="Enter Your Mobile Number"
																	value={values.number}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	maxLength={10}
																	onKeyPress={handleKeyPress}
																	onPaste={handlePaste}
																/>
															</div>

															{errors.number && touched.number ? (
																<div className="err-msg"> {errors.number} </div>
															) : null}
															<div className="login-bt-box">
																<button className="login-bt-user" type="submit">
																	Proceed
																</button>
															</div>
														</div>
													</form>
												</>
											) : (
												<>
													<div className="login-form-user">
														<div className="input-box">
															<img
																src={toAbsoluteUrl("/media/img/user.svg")}
																alt=""
															/>
															<input
																type="number"
																autoComplete="off"
																name="number"
																id="number"
																placeholder="Enter Your Mobile Number"
																value={userNum}
																maxLength={10}
															/>
														</div>
														<div className="input-box">
															<img
																src={toAbsoluteUrl("/media/img/call.png")}
																// src={toAbsoluteUrl("/media/img/user.svg")}
																alt=""
															/>
															<input
																type="text"
																onKeyPress={handleKeyPress}
																maxLength={6}
																placeholder="Enter Your OTP Here"
																onChange={(e: any) => {
																	setVerifyOtp(e.target.value);
																}}
															/>
														</div>
														<div className="consent-text">
															By Proceeding you are accepting our{" "}
															<a
																href="/privacyPolicy"
																style={{
																	color: "grey",
																	textDecoration: "underline",
																}}
															>
																Privacy Policy
															</a>{" "}
															and{" "}
															<a
																href="/termsofuse"
																style={{
																	color: "grey",
																	textDecoration: "underline",
																}}
															>
																Terms of Use
															</a>
															.
														</div>
														<div
															style={{
																display: "flex",
																justifyContent: "right",
															}}
														>
															<div
																className="login-bt-box"
																style={{ marginRight: "10px" }}
															>
																<button
																	className="login-bt-user"
																	onClick={handleResendOtp}
																>
																	Resend OTP
																</button>
															</div>
															<div className="login-bt-box">
																<button
																	className="login-bt-user"
																	onClick={handleOtpVerify}
																>
																	Verify & Sign-In
																</button>
															</div>
														</div>
													</div>
												</>
											)}
										</div>
									</Modal.Body>
									{/* <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Save Changes
              </Button>
            </Modal.Footer> */}
								</Modal>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
