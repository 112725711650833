import "./Blog.scss";
import {
	Carousel as Multicarousel,
	Col,
	Container,
	Form,
	Nav,
	Navbar,
	NavDropdown,
	Row,
	Tab,
	Tabs,
} from "react-bootstrap-v5";
import Collapsible from "react-collapsible";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import ImageGallery from "react-image-gallery";
import UserHeader from "../components/UserHeader";
import UserFooter from "../components/UserFooter";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL || "";

// const Img_Path = "https://myresortsbucket.s3.amazonaws.com/";
const Img_Path = "https://myresortsbucket-prod1.s3.ap-south-1.amazonaws.com/";

export default function Blog() {
	const [blogList, setBlogList] = useState<any>("");
	const [blogListFilter, setBlogListFilter] = useState<any>("");

	const handelBlogList = () => {
		const url = `${API_URL}/v1/user/blog`;
		axios.get(url).then((res) => {
			setBlogList(res.data.data);
			setBlogListFilter(res.data.data);
		});
	};

	useEffect(() => {
		handelBlogList();

		if (window.innerWidth < 550) {
			window.scrollTo({ top: 10, behavior: "smooth" });
		} else if (window.innerWidth > 700 && window.innerWidth < 1200) {
			window.scrollTo({ top: 10, behavior: "smooth" });
		} else if (window.innerWidth >= 1200) {
			window.scrollTo({ top: 10, behavior: "smooth" });
		}
	}, []);

	const handleSearch = (val: any) => {
		// console.log(val, val.length);
		if (val && val.length > 2) {
			// console.log(blogList);
			const filter = {
				blog_title: val,
			};
			let res = blogList.filter(
				({ blog_title }: any) =>
					blog_title.toLowerCase().indexOf(filter.blog_title) !== -1
			);
			if (res) {
				setBlogList(res);
			}
		} else {
			setBlogList(blogListFilter);
		}
	};
	const findByQuery = (obj: any, query: any) => {
		const keys = Object.keys(obj);
		// console.log(keys);

		const res: any = {};
		keys.forEach((key: any) => {
			// case insensitive search
			if (obj[key].toLowerCase().includes(query.toLowerCase())) {
				res[key] = obj[key];
			}
		});
		return res;
	};

	return (
		<>
			{/* <UserHeader /> */}
			<div className="payment-confirmation-wrapper blog-main fix-top-nav-padd">
				<div className="room-details-banner">
					<img src={toAbsoluteUrl("/media/img/banner-2.jpg")} alt="MyResorts" />
					<div className="room-details-banner-info">
						<h1>Blogs</h1>
						<h5>
							Get the inside scoop on travel destinations, accommodation
							options, and activities with our resort booking website's blog.
						</h5>
					</div>
					<div className="overlay-hy">
						<img
							src={toAbsoluteUrl("/media/img/tree-shape.png")}
							alt="MyResorts"
						/>
					</div>
				</div>

				<div className="container">
					<Row>
						<Col lg="12">
							<div className="breadcum-wrapper">
								<div className="breadcum">
									<ul>
										<li>
											<a href="home">Home</a>
										</li>
										<li>
											<a href="blog">Blogs</a>
										</li>
									</ul>
								</div>
							</div>
						</Col>
					</Row>
				</div>

				<div className="container">
					<Row>
						<Col lg="8" className="col-blogs">
							<div className="blog-list-left">
								<Row className="blog-list-row">
									{blogList !== ""
										? blogList.map((data: any) => {
												const blogData =
													data.blog_data && data.blog_data.length > 3
														? `${data.blog_data.substring(0, 350)}...`
														: data.blog_data;

												const ellipsisStyle =
													data.blog_data && data.blog_data.length > 300
														? { textOverflow: "ellipsis" }
														: {};

												return (
													<>
														<Col
															className="blog-list-column"
															md="6"
															lg="6"
															key={data._id}
														>
															<div className="blog-list-box">
																<div className="blog-image-wrap">
																	<Link
																		to={{
																			pathname: "/blogdetails",
																			search: `blogId=${data._id}`,
																		}}
																	>
																		<img
																			src={
																				data?.blog_image_id === undefined
																					? "/media/img/blog-img.jpg"
																					: `${Img_Path}${data?.blog_image_id}`
																			}
																			style={{
																				maxWidth: "100%",
																				minWidth: "100%",
																			}}
																			alt={`${data.blog_title}`}
																		/>
																	</Link>
																</div>
																<div className="blog-list-info">
																	<h3>{data.blog_date}</h3>
																	<h2 className="blog-title">
																		{data.blog_title}
																	</h2>
																	<p
																		style={ellipsisStyle}
																		dangerouslySetInnerHTML={{
																			__html: blogData,
																		}}
																	></p>

																	<Link
																		to={{
																			pathname: "/blogdetails",
																			search: `blogId=${data._id}`,
																		}}
																	>
																		Read More
																	</Link>
																</div>
															</div>
														</Col>
													</>
												);
										  })
										: ""}
								</Row>
							</div>
						</Col>

						<Col lg="4">
							<div className="category-wrapper-blog">
								{/* <input
									type="text"
									placeholder="Enter Serch Keywords"
									onChange={(e) => handleSearch(e.target.value)}
								/> */}

								{/* <div className="category-box-right-blog">
									<h2>Categories</h2>
									<ul>
										<li>
											<img
												src={toAbsoluteUrl("/media/img//pagination-right.svg")}
												alt="MyResorts"
											/>
											<a href="#">Beautiful Day 1</a>
										</li>

										<li>
											<img
												src={toAbsoluteUrl("/media/img//pagination-right.svg")}
												alt="MyResorts"
											/>
											<a href="#">Beautiful Day 1</a>
										</li>

										<li>
											<img
												src={toAbsoluteUrl("/media/img//pagination-right.svg")}
												alt="MyResorts"
											/>
											<a href="#">Beautiful Day 1</a>
										</li>

										<li>
											<img
												src={toAbsoluteUrl("/media/img//pagination-right.svg")}
												alt="MyResorts"
											/>
											<a href="#">Beautiful Day 1</a>
										</li>

										<li>
											<img
												src={toAbsoluteUrl("/media/img//pagination-right.svg")}
												alt="MyResorts"
											/>
											<a href="#">Beautiful Day 1</a>
										</li>
									</ul>
								</div> */}
								<div className="instagram-stream">
									<h2>Social</h2>
									<ul>
										<li>
											<a href="http://www.instagram.com/myresorts.in/?hl=en">
												<img
													src={toAbsoluteUrl("/media/img/insta-img-1.jpg")}
													alt="MyResorts"
												/>
											</a>
										</li>

										<li>
											<a href="http://www.instagram.com/myresorts.in/?hl=en">
												<img
													src={toAbsoluteUrl("/media/img/insta-img-2.jpg")}
													alt="MyResorts"
												/>
											</a>
										</li>

										<li>
											<a href="http://www.instagram.com/myresorts.in/?hl=en">
												<img
													src={toAbsoluteUrl("/media/img/insta-img-3.jpg")}
													alt="MyResorts"
												/>
											</a>
										</li>

										<li>
											<a href="http://www.instagram.com/myresorts.in/?hl=en">
												<img
													src={toAbsoluteUrl("/media/img/insta-img-1.jpg")}
													alt="MyResorts"
												/>
											</a>
										</li>

										<li>
											<a href="http://www.instagram.com/myresorts.in/?hl=en">
												<img
													src={toAbsoluteUrl("/media/img/insta-img-2.jpg")}
													alt="MyResorts"
												/>
											</a>
										</li>

										<li>
											<a href="http://www.instagram.com/myresorts.in/?hl=en">
												<img
													src={toAbsoluteUrl("/media/img/insta-img-3.jpg")}
													alt="MyResorts"
												/>
											</a>
										</li>
									</ul>

									<a
										className="instaFollow"
										href="http://www.instagram.com/myresorts.in/?hl=en"
									>
										{" "}
										<img
											src={toAbsoluteUrl("/media/img/insta-icon.svg")}
											alt="MyResorts"
										/>
										Follow Us on Instagram
									</a>
								</div>

								{/* <div className="latest-news">
                  <h2>Latest News</h2>
                  <div className="news-list">
                    <div className="news-box">
                      <div className="news-box-img">
                        <img
                          src={toAbsoluteUrl("/media/img/news.jpg")}
                          alt="MyResorts"
                        />
                      </div>
                      <div className="news-info">
                        <p>29 October</p>
                        <span>Post with Gallery</span>
                        <h3>Robin Miles</h3>
                      </div>
                    </div>

                    <div className="news-box">
                      <div className="news-box-img">
                        <img
                          src={toAbsoluteUrl("/media/img/news2.jpg")}
                          alt="MyResorts"
                        />
                      </div>
                      <div className="news-info">
                        <p>29 October</p>
                        <span>Post with Gallery</span>
                        <h3>Robin Miles</h3>
                      </div>
                    </div>

                    <div className="news-box">
                      <div className="news-box-img">
                        <img
                          src={toAbsoluteUrl("/media/img/news3.jpg")}
                          alt="MyResorts"
                        />
                      </div>
                      <div className="news-info">
                        <p>29 October</p>
                        <span>Post with Gallery</span>
                        <h3>Robin Miles</h3>
                      </div>
                    </div>

                    <a href="#" className="allNews">ALL NEWS</a>
                  </div>
                </div> */}
							</div>
						</Col>

						{/* <Col lg="8">
              <div className="pagination-wrap-blog">
                <ul>
                  <li>
                    <a href="#">
                      <img
                        src={toAbsoluteUrl(
                          "/media/img/pagination-left.svg"
                        )}
                        alt="MyResorts"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="active">
                      1
                    </a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#">3</a>
                  </li>
                  <li>
                    <a href="#">4</a>
                  </li>
                  <li>
                    <a href="#">5</a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={toAbsoluteUrl(
                          "/media/img/pagination-right.svg"
                        )}
                        alt="MyResorts"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </Col> */}
					</Row>
				</div>

				<UserFooter />
			</div>
		</>
	);
}
