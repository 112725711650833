import "./Contactus.scss";
import {
	Carousel as Multicarousel,
	Col,
	Container,
	Form,
	Nav,
	Navbar,
	NavDropdown,
	Row,
	Tab,
} from "react-bootstrap-v5";
import Collapsible from "react-collapsible";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import UserHeader from "../components/UserHeader";
import UserFooter from "../components/UserFooter";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { useEffect, useState } from "react";
import { useRef } from "react";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "";

const initialValues = {
	name: "",
	email: "",
	number: "",
	subject: "",
	message: "",
};

export const signUpSchema = Yup.object({
	name: Yup.string().min(2).max(25).required("Your Name is Required !"),
	email: Yup.string()
		.email("Email is Invalid")
		.required("Email Id is Required !"),
	number: Yup.string()
		.required("Mobile Number is Required")
		.max(10, "Mobile Number is Too Long")
		.phone("IN", true, "Mobile Number is Invalid"),
	subject: Yup.string().min(5).required("Subject is Required !"),
	message: Yup.string().min(5).required("Message is Required !"),
});

export default function Contactus() {
	const [contactData, setContactData] = useState<any>("");

	const [contactAlert, setContactAlert] = useState<any>("");
	const [faq, setFaq] = useState<any>("");

	// form code starts here
	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			initialValues: initialValues,
			validationSchema: signUpSchema,
			onSubmit: (values, action) => {
				axios
					.post(`${API_URL}/v1/contact/contact_us`, {
						name: values.name,
						email: values.email,
						mobile: values.number,
						message: values.message,
						subject: values.subject,
					})
					.then((res) => {
						// console.log(res, "res", res.status);
						if (res.data.status === "success") {
							setContactAlert("Your Message Sent Successfully !!!");
						} else {
							setContactAlert("There is a Problem ");
						}
					})
					.then(() => {
						setTimeout(() => {
							setContactAlert("");
						}, 3000);
					});

				// console.log(values, "values")
				action.resetForm(); //to clear the user info after submitting the form
			},
		});
	// form code ends here

	// =============== Faq Code Start's here =========================
	const handleFaq = () => {
		const url = `${API_URL}/v1/user/faqs`;
		axios.get(url).then((res) => {
			return console.log(res.data.data, "faq response"), setFaq(res.data.data);
		});
	};
	// =============== Faq Code Start's here =========================

	// =========== handle contact data code start's here ===================
	const handleContactData = () => {
		const url = `${API_URL}/v1/user/getcontact`;
		axios.get(url).then((res) => {
			return setContactData(res.data.data);
		});
	};
	// =========== handle contact data code end's  here  ===================

	const faqRef = useRef<HTMLDivElement>(null); // Declare faqRef here

	useEffect(() => {
		handleFaq();
		handleContactData();
	// }, []);

	    // Check the URL hash
		if (window.location.hash === "#faq" && faqRef.current) {
			faqRef.current.scrollIntoView({ behavior: "smooth" });
		  } else {
			window.scrollTo({ top: 0, behavior: "smooth" });
		  }
		}, []);

	// useEffect(() => {
	// 	window.scrollTo({ top: 0, behavior: "smooth" });
	//   }, []);

	return (
		<>
			{/* <UserHeader /> */}
			<div className="contact-us-main fix-top-nav-padd">
				<div className="contact-hero-section">
					<div className="contact-left-section">
						<img
							className="contact-hero-img"
							src="/media/img/Contacthero.png"
							alt=""
						/>
					</div>
					<div className="contact-right-section">
						<h2>Contact Us</h2>
						<p>
							Contact our customer support team for assistance with booking a
							stay at our resort or for any questions or concerns.
						</p>
					</div>
				</div>

				<div className="get-touch-cont-wrap">
					<div className="get-touch-cont-wrap-col-left">
						<h3>CONTACT US</h3>
						<h4>Get touch with us</h4>

						<p>
							Reach out to us for any inquiries or reservations through our
							convenient contact Information on our website.
						</p>

						{contactData !== ""
							? contactData.map((value: any) => {
									return (
										<>
											<div className="call-us-box">
												<h3>CALL US</h3>
												{/* <h5>Office : {value.office}</h5> */}
												<h5>Phone : {value.mobile}</h5>
											</div>

											<div className="call-us-box call-us-address">
												<h3 className="callus-heading">Address</h3>
												<h5>
													{
														"Plot No - B12 Umiya Colony near Amazon warehouse Wathoda Nagpur 440008"
													}
												</h5>
											</div>

											<div className="follow-us-box">
												<h3>FOLLOW US</h3>
												<h5>{value.follow}</h5>
											</div>
										</>
									);
							  })
							: " "}
					</div>

					<div className="get-touch-cont-wrap-col-right">
						<img
							src={toAbsoluteUrl("/media/img/contact-us-img.jpg")}
							alt="MyResorts.in"
							className="get-resort-img"
						/>
						<img
							src="/media/img/rectangle.png"
							alt="MyResorts.in"
							className="get-rectangle-img"
						/>
					</div>
				</div>

				<div className="contact-form-wrapper">
					<div className="map">
						<img src={toAbsoluteUrl("/media/img/map.jpg")} alt="MyResorts.in" />
					</div>
					<div className="container">
						<Row>
							<Col>
								<div className="contact-form">
									<h3>CONTACT FORM</h3>
									<h4>Lets Get In Touch</h4>

									<p>
										Contact us for Personalized Assistance in Booking your Ideal
										Resort Getaway.
									</p>

									<div className="cont-form-list-box">
										{/* <form > */}
										<form onSubmit={handleSubmit}>
											<div className="contf">
												<label htmlFor="name">
													Your Name <sup>*</sup>
												</label>
												{errors.name && touched.name ? (
													<span style={{ color: "red", textAlign: "left" }}>
														{" "}
														{errors.name}{" "}
													</span>
												) : null}
												<input
													type="name"
													autoComplete="on"
													name="name"
													id="name"
													value={values.name}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
											</div>

											<div className="contf">
												<label htmlFor="email">
													Your Email<sup>*</sup>
												</label>
												{errors.email && touched.email ? (
													<span style={{ color: "red", textAlign: "left" }}>
														{" "}
														{errors.email}{" "}
													</span>
												) : null}
												<input
													type="email"
													autoComplete="on"
													name="email"
													id="email"
													value={values.email}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
											</div>

											<div className="contf">
												<label htmlFor="number">
													Mobile No.<sup>*</sup>
												</label>
												{errors.number && touched.number ? (
													<span style={{ color: "red", textAlign: "left" }}>
														{" "}
														{errors.number}{" "}
													</span>
												) : null}
												<input
													type="number"
													autoComplete="on"
													name="number"
													id="number"
													value={values.number}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
											</div>

											<div className="contf">
												<label htmlFor="subject">Subject</label>
												{errors.subject && touched.subject ? (
													<span style={{ color: "red", textAlign: "left" }}>
														{" "}
														{errors.subject}{" "}
													</span>
												) : null}
												<input
													type="text"
													autoComplete="on"
													name="subject"
													id="subject"
													value={values.subject}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
											</div>

											<div className="contf">
												<label htmlFor="message">Message</label>
												{errors.message && touched.message ? (
													<span style={{ color: "red", textAlign: "left" }}>
														{" "}
														{errors.message}{" "}
													</span>
												) : null}
												<textarea
													autoComplete="on"
													name="message"
													id="message"
													value={values.message}
													onChange={handleChange}
													onBlur={handleBlur}
												></textarea>
											</div>

											<button className="send-msg-cont" id="faq">
												SEND MESSAGE
											</button>
											<div className="contact-alert-msg"> {contactAlert} </div>
										</form>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>

				<div className="cont-faq-bg" ref={faqRef}>
					<img src={toAbsoluteUrl("/media/img/faq-bg.jpg")} alt="MyResorts.in" />

					{/* <img className="contactLeaf-left" src="/media/img/about-leaf-bg.png" alt="MyResorts.in" />
            <img className="contactLeaf-right" src="/media/img/leaf-two.png" alt="MyResorts.in" /> */}
				</div>

				<div className="container">
					<Row>
						<Col>
							<div className="cont-faq-ques-box">
								<h2>Frequently Asked Questions</h2>

								<div className="faq-accor">
									{faq !== "" ? (
										faq.map((data: any) => {
											return (
												<>
													<div className="fq-wrap" key={data._id}>
														<Collapsible trigger={data.question}>
															<div
																className="faq-content"
																style={{ transition: "0.1s ease-in-out" }}
															>
																{data.answer}
															</div>
														</Collapsible>
													</div>
												</>
											);
										})
									) : (
										<div className="fq-wrap">
											<Collapsible trigger="Lorem Ipsum is simply dummy text of the printing and typesetting industry ?">
												<div className="faq-content">
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy text ever since the 1500s,
													when an unknown printer took a galley of type and
													scrambled it to make
												</div>
											</Collapsible>
										</div>
									)}
								</div>
							</div>
						</Col>
					</Row>
				</div>

				<UserFooter />
			</div>
		</>
	);
}
