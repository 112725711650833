import "./BlogDetails.scss";
import {
	Carousel as Multicarousel,
	Col,
	Container,
	Form,
	Nav,
	Navbar,
	NavDropdown,
	Row,
	Tab,
	Tabs,
} from "react-bootstrap-v5";
import Collapsible from "react-collapsible";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import ImageGallery from "react-image-gallery";
import UserHeader from "../components/UserHeader";
import UserFooter from "../components/UserFooter";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL || "";

// const Img_Path = 'https://myresortsbucket.s3.amazonaws.com/'
const Img_Path = "https://myresortsbucket-prod1.s3.ap-south-1.amazonaws.com/";

const signUpSchema = Yup.object({
	comment: Yup.string()
		.max(300, "Your Comment is Too Long")
		.required("Your Message is Required !"),
	name: Yup.string().min(2).max(25).required("User Name is Required !"),
	email: Yup.string()
		.email("Email is Invalid")
		.required("Email Id is Required !"),
});

let UserFirstName = localStorage.getItem("UserFirstname") || "";
let UserLastName = localStorage.getItem("UserLastname") || "";
let UserName = `${UserFirstName} ${UserLastName}`;
let UserEmail = localStorage.getItem("UserEmail") || "";
// let UserNum = localStorage.getItem('UserNum') || '';
const userId = localStorage.getItem("UserId") || "";

export default function Blogdetails() {
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const blogId = params.get("blogId")
		? params.get("blogId")
		: "644ceffc71dece3a9ab9e0ff";

	const [blogDetails, setBlogDetails] = useState<any>("");
	const [commentMsg, setCommentMsg] = useState<any>("");
	const [commentData, setCommentData] = useState<any>("");

	const handleGetBlogDetails = () => {
		axios.get(`${API_URL}/v1/user/blogdetails?id=${blogId}`).then((res) => {
			setBlogDetails(res.data.data);
		});
	};

	const handleCommentMsg = (message: any) => {
		setCommentMsg(message);
		setTimeout(() => {
			setCommentMsg("");
		}, 3000);
	};

	const handleGetComment = () => {
		axios.get(`${API_URL}/v1/user/getblog?blog_id=${blogId}`).then((res) => {
			setCommentData(res.data.data);
			// console.log(res, "get comments");
		});
	};

	const initialValues = {
		comment: "",
		name: UserName,
		email: UserEmail,
		blog_id: blogId,
	};

	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			initialValues: initialValues,
			validationSchema: signUpSchema,
			onSubmit: (values, action) => {
				axios
					.post(`${API_URL}/v1/user/blogcomment`, {
						message: values.comment,
						name: values.name,
						email: values.email,
						blog_id: blogId,
						user_id: userId,
					})
					.then((res) => {
						handleCommentMsg(res.data.message);
						handleGetComment();
					});
				action.resetForm(); //to clear the user info after submitting the form
			},
		});

	useEffect(() => {
		handleGetBlogDetails();
		handleGetComment();

		if (window.innerWidth < 550) {
			window.scrollTo({ top: 140, behavior: "smooth" });
		} else if (window.innerWidth > 700 && window.innerWidth < 1200) {
			window.scrollTo({ top: 280, behavior: "smooth" });
		} else if (window.innerWidth >= 1200) {
			window.scrollTo({ top: 410, behavior: "smooth" });
		}
	}, []);

	return (
		<>
		 	{/* <UserHeader /> */}
			<div className="payment-confirmation-wrapper blog-details-container fix-top-nav-padd">
				<div className="room-details-banner">
					<img src={toAbsoluteUrl("/media/img/banner-2.jpg")} alt="MyResorts.in" />
					<div className="room-details-banner-info">
						<h1>Blog Details</h1>
						<h5>
							The resort booking website's blog details page provides
							informative and engaging content about various resorts and travel
							destinations.
						</h5>
					</div>
					<div className="overlay-hy">
						<img
							src={toAbsoluteUrl("/media/img/tree-shape.png")}
							alt="MyResorts.in"
						/>
					</div>
				</div>

				<div className="container">
					<Row>
						<Col lg="12">
							<div className="breadcum-wrapper">
								<div className="breadcum">
									<ul>
										<li>
											<a href="home">Home</a>
										</li>
										<li>
											<a href="blog">Blog</a>
										</li>
										<li>
											<a>Blog Details</a>
										</li>
									</ul>
								</div>
							</div>
						</Col>
					</Row>
				</div>

				<div className="container">
					<Row>
						<Col lg="8">
							<div className="blog-list-left">
								<Row>
									<Col lg="12">
										<div className="blog-details-wrapper">
											{blogDetails !== "" ? (
												<>
													<div className="blog-details-banner">
														<div className="banner-section">
															{/* <Multicarousel>
                                <Multicarousel.Item>
                                  <img
                                    className="d-block w-100"
                                    src={toAbsoluteUrl("/media/img/blog-details-banner.jpg")}
                                    alt="First slide"
                                  />

                                </Multicarousel.Item>
                                <Multicarousel.Item>
                                  <img
                                    className="d-block w-100"
                                    src={toAbsoluteUrl("/media/img/blog-details-banner.jpg")}
                                    alt="Second slide"
                                  />


                                </Multicarousel.Item>
                                <Multicarousel.Item>
                                  <img
                                    className="d-block w-100"
                                    src={toAbsoluteUrl("/media/img/blog-details-banner.jpg")}
                                    alt="Third slide"
                                  />

                                </Multicarousel.Item>
                              </Multicarousel> */}
															<img
																src={
																	blogDetails?.blog_image_id === undefined
																		? "/media/img/blog-img.jpg"
																		: `${Img_Path}${blogDetails?.blog_image_id}`
																}
																alt={blogDetails?.blog_title}
																style={{ width: "100%" }}
															/>
														</div>
													</div>

													<div className="post-date">
														<h3>{blogDetails?.blog_date}</h3>
														<h2>{blogDetails?.blog_title}</h2>
													</div>

													<div className="blog-deails-content">
														<p
															dangerouslySetInnerHTML={{
																__html: blogDetails?.blog_data,
															}}
														></p>
													</div>
												</>
											) : (
												""
											)}

											{/* <div className="like">
                        <div className="like-bt">Like</div>
                        <p>3</p>
                      </div>

                      <div className="comment">
                        <div className="like-bt">comment</div>
                      </div> */}
										</div>
									</Col>

									<Col lg="12">
										<form onSubmit={handleSubmit}>
											<div
												className="leave-comment-wrap"
												style={{ marginTop: "20px" }}
											>
												<h3>Leave a comment</h3>
												<ul>
													<li>
														<textarea
															name="comment"
															id="comment"
															placeholder="Write message"
															value={values.comment}
															onChange={handleChange}
															onBlur={handleBlur}
														></textarea>
													</li>
													<li>
														{" "}
														{errors.comment && touched.comment ? (
															<span
																style={{ color: "red", marginBottom: "10px" }}
															>
																{errors.comment}{" "}
															</span>
														) : null}{" "}
													</li>

													<li>
														<input
															type="text"
															name="name"
															id="name"
															placeholder="Your Name"
															value={values.name}
															onChange={handleChange}
															onBlur={handleBlur}
														/>
														<input
															type="email"
															name="email"
															id="email"
															placeholder="Email address"
															value={values.email}
															onChange={handleChange}
															onBlur={handleBlur}
														/>
													</li>
													<li>
														{" "}
														{errors.name && touched.name ? (
															<span
																style={{ color: "red", margin: "10px 0px" }}
															>
																{errors.name}{" "}
															</span>
														) : (
															<span> </span>
														)}
														{errors.email && touched.email ? (
															<span
																style={{ color: "red", margin: "10px 0px" }}
															>
																{errors.email}{" "}
															</span>
														) : null}{" "}
													</li>
												</ul>
												<span>
													<input
														type="checkbox"
														name="checkboxValue"
														id="saveEmail"
													/>
													<label htmlFor="saveEmail">
														<p id="saveEmails">
															Save my name, email, and website in this browser
															for the next time I comment.
														</p>
													</label>
												</span>

												<div className="submit-bt-wrap">
													<button type="submit" className="submit-bt">
														Submit now
													</button>
												</div>

												<span style={{ color: "red" }}> {commentMsg} </span>
											</div>
										</form>
									</Col>

									<Col lg="12">
										<div className="blog-comments-section">
											{commentData !== ""
												? commentData.map((data: any) => {
														return (
															<>
																<div
																	className="blog-details-profile"
																	key={data._id}
																>
																	<div className="blog-details-pro-img">
																		<img
																			alt={data?.name}
																			src={
																				data?.user_image.length > 0
																					? data?.user_image
																					: toAbsoluteUrl(
																							"/media/img/icons/userblog5.png"
																					  )
																			}
																		/>
																	</div>
																	<span>
																		<h2>{data.name}</h2>
																		<p>{data.message}</p>
																	</span>
																</div>
															</>
														);
												  })
												: ""}
										</div>
									</Col>
								</Row>
							</div>
						</Col>

						<Col lg="4">
							<div className="category-wrapper-blog">
								{/* <input type="text" placeholder="Enter Serch Keywords" /> */}

								{/* <div className="category-box-right-blog">
									<h2>Categories</h2>
									<ul>
										<li>
											<img
												src={toAbsoluteUrl("/media/img//pagination-right.svg")}
												alt="MyResorts.in"
											/>
											<a href="#">Beautiful Day 1</a>
										</li>

										<li>
											<img
												src={toAbsoluteUrl("/media/img//pagination-right.svg")}
												alt="MyResorts.in"
											/>
											<a href="#">Beautiful Day 1</a>
										</li>

										<li>
											<img
												src={toAbsoluteUrl("/media/img//pagination-right.svg")}
												alt="MyResorts.in"
											/>
											<a href="#">Beautiful Day 1</a>
										</li>

										<li>
											<img
												src={toAbsoluteUrl("/media/img//pagination-right.svg")}
												alt="MyResorts.in"
											/>
											<a href="#">Beautiful Day 1</a>
										</li>

										<li>
											<img
												src={toAbsoluteUrl("/media/img//pagination-right.svg")}
												alt="MyResorts.in"
											/>
											<a href="#">Beautiful Day 1</a>
										</li>
									</ul>
								</div> */}
								<div className="instagram-stream">
									<h2>Social</h2>
									<ul>
										<li>
											<a href="http://www.instagram.com/myresorts.in/?hl=en">
												<img
													src={toAbsoluteUrl("/media/img/insta-img-1.jpg")}
													alt="MyResorts"
												/>
											</a>
										</li>

										<li>
											<a href="http://www.instagram.com/myresorts.in/?hl=en">
												<img
													src={toAbsoluteUrl("/media/img/insta-img-2.jpg")}
													alt="MyResorts"
												/>
											</a>
										</li>

										<li>
											<a href="http://www.instagram.com/myresorts.in/?hl=en">
												<img
													src={toAbsoluteUrl("/media/img/insta-img-3.jpg")}
													alt="MyResorts"
												/>
											</a>
										</li>

										<li>
											<a href="http://www.instagram.com/myresorts.in/?hl=en">
												<img
													src={toAbsoluteUrl("/media/img/insta-img-1.jpg")}
													alt="MyResorts"
												/>
											</a>
										</li>

										<li>
											<a href="http://www.instagram.com/myresorts.in/?hl=en">
												<img
													src={toAbsoluteUrl("/media/img/insta-img-2.jpg")}
													alt="MyResorts"
												/>
											</a>
										</li>

										<li>
											<a href="http://www.instagram.com/myresorts.in/?hl=en">
												<img
													src={toAbsoluteUrl("/media/img/insta-img-3.jpg")}
													alt="MyResorts"
												/>
											</a>
										</li>
									</ul>

									<a
										className="instaFollow"
										href="http://www.instagram.com/myresorts.in/?hl=en"
									>
										{" "}
										<img
											src={toAbsoluteUrl("/media/img/insta-icon.svg")}
											alt="MyResorts"
										/>
										Follow Us on Instagram
									</a>
								</div>

								{/* <div className="latest-news">
                  <h2>Latest News</h2>
                  <div className="news-list">
                    <div className="news-box">
                      <div className="news-box-img">
                        <img
                          src={toAbsoluteUrl("/media/img/news.jpg")}
                          alt="MyResorts.in"
                        />
                      </div>
                      <div className="news-info">
                        <p>29 October</p>
                        <span>Post with Gallery</span>
                        <h3>Robin Miles</h3>
                      </div>
                    </div>

                    <div className="news-box">
                      <div className="news-box-img">
                        <img
                          src={toAbsoluteUrl("/media/img/news2.jpg")}
                          alt="MyResorts.in"
                        />
                      </div>
                      <div className="news-info">
                        <p>29 October</p>
                        <span>Post with Gallery</span>
                        <h3>Robin Miles</h3>
                      </div>
                    </div>

                    <div className="news-box">
                      <div className="news-box-img">
                        <img
                          src={toAbsoluteUrl("/media/img/news3.jpg")}
                          alt="MyResorts.in"
                        />
                      </div>
                      <div className="news-info">
                        <p>29 October</p>
                        <span>Post with Gallery</span>
                        <h3>Robin Miles</h3>
                      </div>
                    </div>

                    <a href="#" className="allNews">
                      ALL NEWS
                    </a>
                  </div>
                </div> */}
							</div>
						</Col>
					</Row>
				</div>
				<UserFooter />
			</div>
		</>
	);
}
