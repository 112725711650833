import React from "react";
import { useEffect } from "react";
import UserFooter from "../UserFooter";
import UserHeader from "../UserHeader";

const Refund: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      {/* <UserHeader /> */}
      <div style={{ marginLeft: '19rem', marginTop: '9rem', marginRight: '19rem', padding: '5rem' }}>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '19px', lineHeight: '107%' }}>Refund and Cancellation Policy</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            Thank you for choosing MyResorts.in, your preferred resort booking platform in India. Our Refund and Cancellation Policy aims to provide clarity and transparency regarding the terms and conditions associated with cancellations and refunds for resort bookings made through MyResorts.in.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '16px', lineHeight: '107%' }}>Cancellation Policy:</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>a.</strong> <strong>Free Cancellation Period:</strong> - Many resorts/hotels listed on MyResorts.in offer a free cancellation period. The duration of this period varies between properties. Please refer to the specific cancellation policy displayed during the booking process for accurate details.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>b.</strong> <strong>Non-Refundable Bookings:</strong> - Some resorts/hotels may offer non-refundable bookings, indicating that cancellations are not eligible for a refund. It is crucial to review the booking details carefully before confirming your reservation.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>c.</strong> <strong>Cancellation Requests:</strong> - If you need to cancel your reservation, you can initiate the cancellation process by logging into your MyResorts.in account. Alternatively, our dedicated customer support team is available to assist you with the cancellation process.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            &nbsp;
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '16px', lineHeight: '107%' }}>Refund Policy:</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>a.</strong> <strong>Refund Eligibility:</strong> - Refund eligibility is contingent on the specific cancellation policy of the booked resort/hotel. If you cancel within the free cancellation period or if the property offers a refund for cancellations, you may be eligible for a refund.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>b.</strong> <strong>Refund Processing Time:</strong> - Refunds, when applicable, will be processed within a reasonable timeframe. The actual processing time may vary based on factors such as the payment method used and banking institutions. Please allow an appropriate period for the refund to reflect in your account.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>c.</strong> <strong>Refunds for Non-Refundable Bookings:</strong> - Non-refundable bookings are explicitly excluded from refunds. It is essential to thoroughly review the booking terms before confirming a reservation to understand the refund implications.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            &nbsp;
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '16px', lineHeight: '107%' }}>Special Circumstances:</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>a.</strong> <strong>Force Majeure:</strong> - In instances of force majeure events, including natural disasters, government regulations, or other unforeseen circumstances, resorts and MyResorts.in reserve the right to modify or adjust cancellation and refund policies. We appreciate your understanding and flexibility in such situations.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '16px', lineHeight: '107%' }}>&nbsp;</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '16px', lineHeight: '107%' }}>Modification or Cancellation by the Resort:</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>a.</strong> <strong>Resort/hotel Changes:</strong> - In rare cases, a resort/hotel may need to modify or cancel a booking due to unforeseen circumstances. In such instances, MyResorts.in is committed to working collaboratively with both guests and resorts to find an equitable solution.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            &nbsp;
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '16px', lineHeight: '107%' }}>Contact Information:</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>a.</strong> <strong>Customer Support:</strong> - For any inquiries or assistance regarding cancellations and refunds, please reach out to our customer support team at contact@myresorts.in. We are here to help you navigate through the process and address any concerns you may have.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            &nbsp;
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong><span style={{ fontSize: '16px', lineHeight: '107%' }}>Changes to this Policy:</span></strong>
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            <strong>a.</strong> <strong>Notification:</strong> - MyResorts.in reserves the right to modify this Refund and Cancellation Policy at any time. Changes will be effective immediately upon posting on the Website. Users will be notified of significant changes through the Website or other communication channels.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            By using MyResorts.in and making a reservation through our platform, you agree to comply with the terms outlined in this Refund and Cancellation Policy. It is your responsibility to review this policy periodically for any updates or changes. We appreciate your trust in MyResorts.in for your resort booking needs.
          </p>

          <p style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '8.0pt', marginLeft: '0cm', fontSize: '11.0pt', fontFamily: '"Montserrat", sans-serif !important;' }}>
            &nbsp;
          </p>
      </div>
      <UserFooter />
    </>
  );
};

export default Refund;
