import "./Confirmation.scss";
import {
	Carousel as Multicarousel,
	Col,
	Container,
	Form,
	Nav,
	Navbar,
	NavDropdown,
	Row,
	Tab,
	Tabs,
} from "react-bootstrap-v5";
import Collapsible from "react-collapsible";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import ImageGallery from "react-image-gallery";
import UserHeader from "../components/UserHeader";
import UserFooter from "../components/UserFooter";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import StarRating from "../components/StarRating";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { cashfreeSandbox } from "cashfree-pg-sdk-javascript";
import { cashfreeProd } from "cashfree-pg-sdk-javascript";
import { enqueueSnackbar } from "notistack";

const API_URL = process.env.REACT_APP_API_URL || "";

const userId = localStorage.getItem("UserId") || "";
const userNum = localStorage.getItem("UserNum") || "";
const userFirstname = localStorage.getItem("UserFirstname") || "";
const userLastname = localStorage.getItem("UserLastname") || "";
const userEmail = localStorage.getItem("UserEmail") || "";

const initialValues = {
	fname: userFirstname,
	lname: userLastname,
	email: userEmail,
	number: userNum,
};

const signUpSchema = Yup.object({
	fname: Yup.string().min(2).max(25).required("User Name is Required"),
	lname: Yup.string().min(2).max(25).required("User Last Name is Required"),
	email: Yup.string()
		.email("Email is Invalid")
		.required("Email Id is Required"),
	number: Yup.string()
		.required("Mobile Number is Required")
		.max(10, "Enter Valid Mobile Number")
		.phone("IN", true, "Phone Number is Invalid"),
});

export default function Confirmation() {
	// const [confirmId, setConfirmId] = useState<any>();
	// const [otpMsg, setOtpMsg] = useState("");
	// const [userNumber, setUserNumber] = useState("");
	// const [userOTP, setUserOTP] = useState("");
	// const [verifyMsg, setVerifyMsg] = useState("")
	// const [verifyStatus, setVerifyStatus] = useState<any>()

	const [infoUpdate, setInfoUpdate] = useState<any>("");

	const PerPersonCost = sessionStorage.getItem("PerPersonCost");
	const CouponType = sessionStorage.getItem("CouponType") || "Empty";
	const CouponCode = sessionStorage.getItem("CouponCode") || null;
	const CouponPrice = sessionStorage.getItem("CouponPrice") || 0;
	const TotalRoomCharge = sessionStorage.getItem("TotalRoomCharge");
	const TotalPrice = sessionStorage.getItem("TotalPrice");
	const TaxFees = sessionStorage.getItem("TaxFees");
	// console.log("Tax Fees", TaxFees);

	const Commission = sessionStorage.getItem("Commission");

	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const roomsNum = params.get("rooms") ? params.get("rooms") : 1;
	const adultNum = params.get("adults") ? params.get("adults") : 2;
	const groupAdults = params.get("groupAdults") ? params.get("groupAdults") : 4;
	const childRange1Num = params.get("childRange1")
		? params.get("childRange1")
		: 0;
	const childRange2Num = params.get("childRange2")
		? params.get("childRange2")
		: 0;
	const childRange3Num = params.get("childRange3")
		? params.get("childRange3")
		: 0;
	const catId = params.get("catId")
		? params.get("catId")
		: "633665f110e91088cb403da9";
	const coupleGroupString = params.get("coupleGroupVal")
		? params.get("coupleGroupVal")
		: false; //cgsbc
	const coupleGroupVal =
		coupleGroupString === "true" ? coupleGroupString === "true" : false; //cgsbc
	const catName = params.get("catName") ? params.get("catName") : "Empty";
	const propName = params.get("propName") ? params.get("propName") : "Empty";
	const propId = params.get("propId") ? params.get("propId") : "Empty";
	const vendorId = params.get("vendorId") ? params.get("vendorId") : "Empty";
	// const catPrice = params.get("catPrice") || '000';
	const checkIn =
		params.get("checkIn") ||
		"Thu Mar 02 2023 16:49:16 GMT+0530 (India Standard Time)";
	const checkOut =
		params.get("checkOut") ||
		"Sat Mar 05 2023 16:49:16 GMT+0530 (India Standard Time)";
	function parseMonth(month1: any) {
		month1 = month1.toLowerCase();
		var months = [
			"jan",
			"feb",
			"mar",
			"apr",
			"may",
			"jun",
			"jul",
			"aug",
			"sep",
			"oct",
			"nov",
			"dec",
		];
		return months.indexOf(month1);
	}
	let dateIn = new Date(checkIn);
	let dateOut = new Date(checkOut);
	// console.log("trace1", dateIn);
	// console.log("trace2", dateOut);
	// console.log("trace1 1", typeof dateIn);
	// console.log("trace2 2", typeof dateOut);
	// console.log("trace1 11", typeof checkIn);
	// console.log("trace2 22", typeof checkIn);

	if (typeof checkIn === "string") {
		// console.log("vmp", checkIn);

		const checkInDay: any = checkIn?.slice(8, 10);
		const checkInMonth: any = checkIn.slice(4, 7);
		const checkInYear: any = checkIn.slice(11, 15);
		const fullDateIn = checkInDay + checkInMonth + checkInYear;
		const dateObj = new Date(
			Number(checkInYear),
			parseMonth(checkInMonth),
			Number(checkInDay)
		);
		// console.log("dateObj", dateObj);
		dateIn = new Date(dateObj);
	}
	if (typeof checkOut === "string") {
		// console.log("vmp2", checkOut);
		const checkInDay: any = checkOut?.slice(8, 10);
		const checkInMonth: any = checkIn.slice(4, 7);
		const checkInYear: any = checkOut.slice(11, 15);
		const fullDateIn = checkInDay + checkInMonth + checkInYear;
		const dateObj = new Date(
			Number(checkInYear),
			parseMonth(checkInMonth),
			Number(checkInDay)
		);
		dateOut = new Date(dateObj);
	}

	// const dateIn = new Date(checkIn);
	const CheckInDate = dateIn.toLocaleDateString("en-GB"); // Assuming you want "DD/MM/YYYY" format

	const CheckOutDate = dateOut.toLocaleDateString("en-GB"); // Assuming you want "DD/MM/YYYY" format

	// const date = new Date(checkOut);
	// const formattedDate = date.toLocaleDateString('en-IN', {
	//   day: '2-digit',
	//   month: '2-digit',
	//   year: 'numeric',
	// });

	// let trying = new Date(checkOut).toLocaleDateString('en-GB')
	// console.log(checkOut, checkIn, "checkOut", CheckOutDate);

	// ------------------ Date format for api -------------------------
	const dateInApi = new Date(checkIn);
	const dateInFormat = dateInApi.toLocaleDateString("en-US", {
		month: "2-digit",
		day: "2-digit",
		year: "numeric",
	});
	const CheckInDateApi = dateInFormat.startsWith("0")
		? dateInFormat.slice(1)
		: dateInFormat;

	const dateOutApi = new Date(checkOut);
	const dateOutFormat = dateOutApi.toLocaleDateString("en-US", {
		month: "2-digit",
		day: "2-digit",
		year: "numeric",
	});
	const CheckOutDateApi = dateOutFormat.startsWith("0")
		? dateOutFormat.slice(1)
		: dateOutFormat;

	// ------------------ Date format for api -------------------------

	const totalGuestsNum =
		Number(adultNum) + Number(childRange1Num) + Number(childRange2Num);

	const totalGroupNum = Number(groupAdults) + Number(childRange3Num);

	const couponDiscount =
		CouponType === "Empty"
			? 0
			: CouponType === "Fixed"
			? `₹${CouponPrice}`
			: CouponType === "Percentage"
			? `${CouponPrice}%`
			: 0;

	useEffect(() => {
		if (window.innerWidth < 550) {
			window.scrollTo({ top: 140, behavior: "smooth" });
		} else if (window.innerWidth > 700 && window.innerWidth < 1200) {
			window.scrollTo({ top: 280, behavior: "smooth" });
		} else if (window.innerWidth >= 1200) {
			window.scrollTo({ top: 410, behavior: "smooth" });
		}
	}, []);

	// ========   Enter Your Details Section  START'S HERE ===============

	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			initialValues: initialValues,
			validationSchema: signUpSchema,
			onSubmit: (values, action) => {
				axios
					.post(`${API_URL}/v1/customer/register_booking_customer`, {
						mobile: values.number,
						firstname: values.fname,
						lastname: values.lname,
						email: values.email,
					})
					.then((res) => {
						// setUserNumber(values.number)
						localStorage.setItem("UserFirstname", values.fname);
						localStorage.setItem("UserLastname", values.lname);
						localStorage.setItem("UserEmail", values.email);
						setInfoUpdate(res.data.message);
						// console.log(values, res.data.message, "handle submit form");
					});
			},
		});

	// const handleVerifyOtp = () => {
	//   axios.post(`${API_URL}/v1/customer/verify_customer`,
	//     {
	//       "mobile": userNumber,
	//       "otp": userOTP
	//     })
	//     .then((res) => {
	//       setVerifyMsg(res.data.message)
	//       setVerifyStatus(res.data.status)
	//       console.log(res.data.status, "otp status")
	//     })
	// }

	// const handleResendOtp = () => {
	//   setVerifyMsg('');
	//   axios.post(`${API_URL}/v1/customer/register_customer`, { "mobile": userNumber })
	//     .then((res) => {
	//       setOtpMsg(res.data.message)
	//     })
	// }
	// ======== Enter Your Details Section END'S HERE ===============

	// ====== PAY NOW BUTTON START'S HERE ===============

	const handlePayNow = () => {
		const Url = `${API_URL}/v1/book/create_booking`;

		let UserNum = localStorage.getItem("UserNum") || "";
		let UserFirstname = localStorage.getItem("UserFirstname") || "";
		let UserLastname = localStorage.getItem("UserLastname") || "";
		let UserName = UserFirstname + " " + UserLastname;

		const bookNowData = {
			booking_user_id: userId,
			booking_vendor_id: vendorId,
			booking_property_id: propId,
			booking_room_id: catId,
			booking_type: coupleGroupVal === false ? "Couple" : "Group",
			booking_amount: TotalPrice,
			booking_status: "",
			payment_status: "",
			checkIn_date_time: CheckInDate,
			checkOut_date_time: CheckOutDate,
			no_of_adult: coupleGroupVal ? "0" : adultNum,
			no_of_child: coupleGroupVal ? "0" : Number(childRange1Num),
			no_of_bigchild: coupleGroupVal ? "0" : Number(childRange2Num),
			total_room: roomsNum,
			total_guest: coupleGroupVal ? totalGroupNum : totalGuestsNum,
			total_room_price: TotalRoomCharge,
			group_adult: coupleGroupVal ? groupAdults : "0",
			group_child: coupleGroupVal ? childRange3Num : "0",
			total_group_num: totalGroupNum,
			tax_fees: TaxFees,
			coupon_discount: couponDiscount,
			booking_full_name: UserName,
			vendor_payble: TotalRoomCharge,
			commission: Commission,
			coupon : CouponCode
		};

		axios.post(Url, bookNowData).then((res) => {
			// console.log("booking api id", res, bookNowData);
			if (res.data.message !== "Booking created successfully") {
				// alert(res.data.message);
				enqueueSnackbar(res.data.message, {
					variant: "error",
					anchorOrigin: {
						horizontal: "right",
						vertical: "top",
					},
				});
			} else {
				if (res.data.booking._id) {

					sessionStorage.setItem("BookingId", res.data.booking._id);
					axios
						.post(`${API_URL}/v1/book/create_session_id`, {
							booking_id: res.data.booking._id,
							user_id: userId,
							number: UserNum,
							amount: TotalPrice,
						})
						.then((res) => {
							// console.log(res.data, "session booking");
							if (res.data.message === "Success") {
								let paymentSessionId = res.data.session_Id;
								//let cashfree = new cashfreeSandbox.Cashfree(paymentSessionId);
								let cashfree = new cashfreeProd.Cashfree(paymentSessionId);
								cashfree.redirect();
							}
						});
				}
			}
		});
	};
	// ====== PAY NOW BUTTON END'S HERE ===============

	// ================ Only Number can Type IN Input Field ==============
	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		// Allow only numbers and backspace
		if (!/^\d$/.test(e.key) && e.key !== "Backspace") {
			e.preventDefault();
		}
	};
	// ================ Only Number can Type IN Input Field ==============

	return (
		<>
			{/* <UserHeader /> */}
			<div className="room-detail-container resort-details-page">
				<div className="room-details-banner banner-info-box">
					<img src={toAbsoluteUrl("/media/img/banner-2.jpg")} alt="MyResorts.in" />
					<div className="room-details-banner-info ">
						<h1>Booking</h1>
						<h5>Please fill up the details below</h5>
					</div>
					<div className="overlay-hy">
						<img
							src={toAbsoluteUrl("/media/img/tree-shape.png")}
							alt="MyResorts.in"
						/>
					</div>
				</div>

				<div className="container">
					<Row>
						<Col lg="12">
							<div className="breadcum-wrapper">
								<div className="breadcum">
									<ul>
										<li>
											<a href="home">Home</a>
										</li>
										<li>
											<a href="rooms">Resorts / Rooms</a>
										</li>
										<li>
											<a href="resortdetails">Resorts</a>
										</li>
										<li>
											{/* <a href="roomdetails">Flagship 8499...</a> */}
											<a href="roomdetails">{catName}</a>
										</li>
										<li>
											<a href="confirmation">Confirm Booking</a>
										</li>
									</ul>
								</div>
							</div>
						</Col>
					</Row>
				</div>

				<div className="confirmation-container">
					<div className="container">
						<Row>
							<Col lg="7">
								<div className="welcome-notes">
									<h1>Review Your Details</h1>
									<div className="resort-name">
										<h3>
											{catName} - {propName}
										</h3>
									</div>
									<div className="star-rats-wrap">
										<div className="star-rat">
											<StarRating rating={4.7} color="#009933" />
										</div>

										<div className="by-rat">
											<span>{4.7} / 5</span>
											{/* <p>332 Ratings</p> */}
										</div>
									</div>
								</div>

								<div className="final-dates-guest-info">
									<span>
										{dateIn.toLocaleString("en-IN", {
											day: "2-digit",
											month: "short",
											year: "numeric",
										})}
										<span
											style={{
												color: "#e63148",
												marginLeft: "11px",
												marginRight: "7px",
												fontWeight: 500,
											}}
										>
											to
										</span>{" "}
										{dateOut.toLocaleString("en-IN", {
											day: "2-digit",
											month: "short",
											year: "numeric",
										})}
									</span>
									{/* <span>{roomsNum} Room, {totalGuestsNum} Guests</span> */}
									<span>
										{roomsNum} Room,{" "}
										{coupleGroupVal ? totalGroupNum : totalGuestsNum} Guests
									</span>
								</div>
								<div className="payble-amount-table">
									<ul>
										{/* {coupleGroupVal ? <li>
                      <h3>Per Person Cost </h3>
                      <h3>₹ {PerPersonCost}</h3>
                    </li>: "" } */}

										<li>
											<h3>Total Room Price </h3>
											<h3>₹ {TotalRoomCharge}</h3>
										</li>
										<li>
											<h3>Tax & Fees</h3>
											<h3>₹ {TaxFees ? TaxFees : TaxFees}</h3>
										</li>
										<li>
											<h3>Coupon Discount</h3>
											<h3>- {couponDiscount}</h3>
											{/* <h3> - { CouponType === 'Empty'? 0 : CouponType === 'Fixed' ? `₹${CouponPrice}` : CouponType === 'Percentage' ? `${CouponPrice}%` : 0}</h3> */}
										</li>
										<li>
											<span>
												<h2>Payable Amount</h2>
												<p>Inclusive of all taxes</p>
											</span>
											<h2>₹ {TotalPrice}</h2>
										</li>
									</ul>
								</div>
							</Col>
							<Col lg="5">
								<div className="confirmation-right-image-box">
									<img
										src={toAbsoluteUrl("/media/img/confirmation-image2.svg")}
										alt="Myresorts"
										className="confirmation-right-image"
									/>
								</div>
							</Col>
						</Row>

						<Row>
							<Col lg="12">
								<div className="enter-your-details-wrap">
									<h2>1. Enter your details</h2>
									<div className="your-details-form">
										<form onSubmit={handleSubmit}>
											<div className="form-box">
												<label>First Name</label>
												<input
													type="text"
													placeholder="Enter first Name"
													autoComplete="off"
													name="fname"
													id="fname"
													value={values.fname}
													onChange={handleChange}
													onBlur={handleBlur}
													disabled={values.fname ? true : false}
												/>
												{errors.fname && touched.fname ? (
													<div className="error-msg"> {errors.fname} </div>
												) : null}
											</div>

											<div className="form-box">
												<label>Last Name</label>
												<input
													type="text"
													placeholder="Enter last Name"
													autoComplete="off"
													name="lname"
													id="lname"
													value={values.lname}
													onChange={handleChange}
													onBlur={handleBlur}
													disabled={values.lname ? true : false}
												/>
												{errors.lname && touched.lname ? (
													<div className="error-msg"> {errors.lname} </div>
												) : null}
											</div>

											<div className="form-box">
												<label>Email Address</label>
												<input
													type="email"
													placeholder="Enter Your Email Address"
													autoComplete="off"
													name="email"
													id="email"
													value={values.email}
													onChange={handleChange}
													onBlur={handleBlur}
													disabled={values.email ? true : false}
												/>
												{errors.email && touched.email ? (
													<div className="error-msg"> {errors.email} </div>
												) : null}
											</div>

											<div className="form-box">
												<label>Mobile Number</label>
												<span>
													<select name="" id="">
														<option value="volvo">+91</option>
														<option value="saab">+58</option>
														<option value="mercedes">+6</option>
														<option value="audi">-58</option>
													</select>
													<input
														type="text"
														style={{ width: "100%" }}
														placeholder="Enter Your Mobile Number"
														autoComplete="off"
														name="number"
														id="number"
														value={values.number}
														onChange={handleChange}
														onBlur={handleBlur}
														onKeyPress={handleKeyPress}
														maxLength={10}
														disabled={values.number ? true : false}
													/>
												</span>
												{errors.number && touched.number ? (
													<div className="error-msg"> {errors.number} </div>
												) : null}
											</div>

											<div
												className="form-box"
												style={{
													display: `${userFirstname === "" ? "" : "none"}`,
												}}
											>
												<p className="alert-msg">{infoUpdate}</p>
												<div
													className="send-otp"
													style={{
														display: `${infoUpdate === "" ? "" : "none"}`,
													}}
												>
													<button className="send-otp-bt" type="submit">
														Submit Data
													</button>
												</div>
											</div>

											{/* <div className="form-box" style={{
                          opacity: `${otpMsg ? "1" : "0"}`,
                          visibility: `${verifyMsg === "Your OTP is Verified !!" ? "hidden" : "visible"}`
                        }}>
                          <label> Verify Otp </label>
                          <input type="text" placeholder="Verify Otp" autoComplete='off' name='otp' id='otp'
                            onChange={(e) => setUserOTP(e.target.value)} onKeyPress={handleKeyPress} maxLength={5} />
                        </div> */}

											{/* <div className="form-box">
                          <p className="alert-msg">
                            {verifyMsg ? verifyMsg : otpMsg}
                          </p>

                          <div className="send-otp" style={{ display: `${otpMsg ? "none" : "block"}`, visibility: `${userFirstname !== ""? "hidden": "visible"}` }}>
                            <button className="send-otp-bt" type="submit" >Send OTP</button>
                          </div>

                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className="send-otp" style={{ width: '47%', display: `${otpMsg ? "block" : "none"}`, visibility: `${verifyMsg === "Your OTP is Verified !!" ? "hidden" : "visible"}` }}>
                              <button className="send-otp-bt" style={{ marginRight: "2px" }} onClick={handleVerifyOtp} >Verify OTP</button>
                            </div>
                            <div className="send-otp" style={{ width: '47%', display: `${otpMsg ? "block" : "none"}`, visibility: `${verifyMsg === "Your OTP is Verified !!" ? "hidden" : "visible"}` }}>
                              <button className="send-otp-bt" onClick={handleResendOtp} >Resend OTP</button>
                            </div>

                          </div>

                        </div> */}
										</form>
									</div>
								</div>
							</Col>
							<Col lg="12">
								<div
									className="complete-final-booking"
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
									}}
								>
									<h2>2. Complete the booking</h2>
									<div className="pay-divider"></div>
									<div className="pay-now-section">
										<button
											onClick={handlePayNow}
											className="pay-now"
											disabled={!(userFirstname || infoUpdate)}
										>
											Pay Now
										</button>

										{/* <div className="pay-now-list">
                      <ul>
                        <li className="active">
                          <img
                            src={toAbsoluteUrl("/media/img/wallet.svg")}
                            alt="MyResorts.in"
                          />
                          <p> Credit/Debit/ATM Cards</p>

                        </li>

                        <li>
                          <img
                            src={toAbsoluteUrl("/media/img/wallet.svg")}
                            alt="MyResorts.in"
                          />
                          <p>Wallets</p>

                        </li>

                        <li>
                          <img
                            src={toAbsoluteUrl("/media/img/upi.svg")}
                            alt="MyResorts.in"
                          />
                          <p> Pay using UPI</p>

                        </li>

                        <li>
                          <img
                            src={toAbsoluteUrl("/media/img/netbanking.svg")}
                            alt="MyResorts.in"
                          />
                          <p> Net banking</p>

                        </li>
                        <li>
                          <img
                            src={toAbsoluteUrl("/media/img/paylater.svg")}
                            alt="MyResorts.in"
                          />
                          <p> Pay Later</p>

                        </li>
                      </ul>
                    </div> */}
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				<div style={{ padding: "60px" }}></div>
			</div>
			<UserFooter />
		</>
	);
}
