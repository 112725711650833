import "./Rooms.scss";
import {
	Carousel as Multicarousel,
	Col,
	Container,
	Form,
	Nav,
	Navbar,
	NavDropdown,
	Row,
	Tab,
	Spinner,
} from "react-bootstrap-v5";
import Collapsible from "react-collapsible";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import UserHeader from "../components/UserHeader";
import UserFooter from "../components/UserFooter";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import filtersState from "../userglobalstate/filterstate";
import { none, useState as useGlobalState } from "@hookstate/core";
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Persistence } from "@hookstate/persistence";
import { relative } from "path";
import { FaCoffee } from "react-icons/fa";
import { IoMdRestaurant } from "react-icons/io";
import { GiCoffeePot } from "react-icons/gi";
import { AiFillStar } from "react-icons/ai";
import { AiFillCaretDown } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import StarRating from "../components/StarRating";

const initialAmenities = [
	{ id: "kingBed", value: "King Beds" },
	{ id: "drink", value: "Welcome Drink" },
	{ id: "bike", value: "Bike Rental" },
	{ id: "privateBathroom", value: "Private Bathroom" },
	{ id: "swimmingPool", value: "Swimming Pool" },
	{ id: "noSmoking", value: "No Smoking" },
	{ id: "television", value: "Television" },
];

const API_URL = process.env.REACT_APP_API_URL || "";

// const Img_Path = `https://myresort.s3.ap-south-1.amazonaws.com/`;
// const Img_Path = "https://myresortsbucket.s3.amazonaws.com/";
const Img_Path = "https://myresortsbucket-prod1.s3.ap-south-1.amazonaws.com/";

//const filterOption = createFilter({})

const customStyles = {
	control: (provided: any) => ({
		...provided,
		color: "#fff",
		background: "#94D4A9",
		borderRadius: "6px !important",
		alignContent: "center",
		border: 0,
		boxShadow: "none",
	}),
	input: (provided: any) => ({ ...provided, color: "#fff" }),
	menu: (provided: any, state: any) => ({
		...provided,
		marginTop: "5px",
		borderRadius: "6px",
	}),
	menuList: (provided: any) => ({ ...provided, borderRadius: "15px" }),
	option: (provided: any, state: any) => ({
		...provided,
		background: state.isSelected
			? "#8dc73f"
			: state.isFocused
			? "rgb(234, 250, 234)"
			: "",
		":active": { ...provided[":active"], backgroundColor: "#c2edc2" },
	}),
	singleValue: (provided: any) => ({
		...provided,
		fontWeight: "600",
		fontSize: "14px",
	}),
};

interface dateRangeType {
	startDate?: Date | undefined;
	endDate?: Date | undefined;
	key?: string | undefined;
}

// Date Range Picker Click Outside Close Start's here
function useClickOutside(callback: () => void) {
	const ref = useRef<HTMLDivElement>(null);
	const handleClick = (event: MouseEvent) => {
		if (ref.current && !ref.current.contains(event.target as Node)) {
			callback();
		}
	};
	useEffect(() => {
		document.addEventListener("click", handleClick);
		return () => {
			document.removeEventListener("click", handleClick);
		};
	});
	return ref;
}
// Date Range Picker Click Outside Close End's here

export default function Rooms() {
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const roomsNum = params.get("rooms") ? params.get("rooms") : 1;
	const adultNum = params.get("adults") ? params.get("adults") : 2;
	const GroupNum = params.get("groupAdults") ? params.get("groupAdults") : 4;
	const childRange1Num = params.get("childRange1")
		? params.get("childRange1")
		: 0;
	const childRange2Num = params.get("childRange2")
		? params.get("childRange2")
		: 0;
	const childRange3Num = params.get("childRange3")
		? params.get("childRange3")
		: 0;
	const dPlace = params.get("dPlace") ? params.get("dPlace") : "";
	const ExcitingPlaces = params.get("excitingPlaces")
		? params.get("excitingPlaces")
		: "";
	const TravelMoods = params.get("travelMoods")
		? params.get("travelMoods")
		: "";
	const coupleGroupString = params.get("coupleGroupVal")
		? params.get("coupleGroupVal")
		: false; //cgsbc
	const coupleGroupVal =
		coupleGroupString === "true" ? coupleGroupString === "true" : false; //cgsbc

	const checkInDate: string | number | null = params.get("checkIn");
	const checkOutDate: string | number | null = params.get("checkOut");

	const [weekend, setWeekend] = useState<any>(false);
	const [weekendFlag, setWeekendFlag] = useState<any>(false);

	let FirstDate: Date;
	if (typeof checkInDate === "string") {
		// alert("thre");
		// console.log("Trace from room", checkInDate);
		FirstDate = new Date(checkInDate);
	} else {
		FirstDate = new Date();
	}

	let SecondDate: Date;
	if (typeof checkOutDate === "string") {
		SecondDate = new Date(checkOutDate);
	} else {
		const today = new Date();
		const nextDay = new Date(today);
		nextDay.setDate(nextDay.getDate() + 1);
		SecondDate = nextDay;
	}

	const [coupleGroup, setCoupleGroup] = useState(coupleGroupVal); //cgsbc

	const [roomSearchText, setRoomSearchText] = useState<any>(
		ExcitingPlaces || dPlace
	);

	// const filters = useGlobalState(filtersState.filters);
	// const [resultPlaceOptions, setResultPlaceOptions] = useState<any[]>([]);

	const filters = useGlobalState(filtersState.filters);
	const [defaultPlaceOptions, setdefaultPlaceOptions] = useState(
		filters.defaultPlaceOptions.get()
	);
	const [resultPlaceOptions, setResultPlaceOptions] = useState<any[]>([]);

	const [guestsFilterShow, setGuestsFilterShow] = useState(false);
	const [defPlace, setDefPlace] = useState<any>({
		value: dPlace,
		label: dPlace,
		data: dPlace,
	});

	// ========== date range picker code start's here ==========

	const [checkedInDate, setCheckedInDate] = useState<any>("");
	const [checkedOutDate, setCheckedOutDate] = useState<any>("");

	const [apiInDate, setApiInDate] = useState<any>("");
	const [apiOutDate, setApiOutDate] = useState<any>("");

	const [dateRange, setDateRange] = useState<dateRangeType[]>([
		{
			startDate: new Date(FirstDate),
			endDate: new Date(SecondDate),
			key: "selection",
		},
	]);
	const [openCalendar, setOpenCalendar] = useState(false);
	const calenderRef = useClickOutside(() => {
		return setOpenCalendar(false), setGuestsFilterShow(false);
	});

	const InDate = dateRange.map((data) => data.startDate);
	const OutDate = dateRange.map((data) => data.endDate);

	const dateString = InDate.toString();
	const checkInDay = dateString.slice(8, 10);
	const checkInMonth = dateString.slice(4, 7);
	const checkInYear = dateString.slice(13, 15);

	const DateStringEnd = OutDate.toString();
	const checkOutDay = DateStringEnd.slice(8, 10);
	const checkOutMonth = DateStringEnd.slice(4, 7);
	const checkOutYear = DateStringEnd.slice(13, 15);

	// console.log(dateString, "checking date string", typeof (dateString), filters.checkIn.get())

	// =========== date range picker code end's here ==============

	//filters.attach(Persistence('gbl-fls'));
	const [guestsFilter, setGuestFilter] = useState<any>({
		rooms: roomsNum,
		adults: adultNum,
		groupAdults: GroupNum,
		childRange1: childRange1Num,
		childRange2: childRange2Num,
		childRange3: childRange3Num,
	});

	const totalGuestsNum = coupleGroup
		? Number(guestsFilter.groupAdults) + Number(guestsFilter.childRange3)
		: Number(guestsFilter.adults) +
		  Number(guestsFilter.childRange1) +
		  Number(guestsFilter.childRange2);

	const [searchResult, setSearchResult] = useState<any[]>([{}]);
	const [searchResultFilter, setSearchResultFilter] = useState<any[]>([{}]);

	const [selectType, setSelectType] = useState<any>("popular");
	const [filterFlag, setFilterFlag] = useState<boolean>(false);

	// ============ filters code start's here =============
	const [amenitiesArray, setAmenitiesArray] = useState<any>([]);
	const [activitiesArray, setActivitiesArray] = useState<any>([]);
	const [cancelTimeArray, setCancelTimeArray] = useState<any>([]);
	const [starRatingArray, setStarRatingArray] = useState<any>([]);
	const [trvlMood, setTrvlMood] = useState<any>([]);

	const [loader, setLoader] = useState<any>(true);
	// if(today.getDay() == 6 || today.getDay() == 0) alert('Weekend!');

	const [amenities, setAmenities] = useState(initialAmenities);
	const handleAmenitiesName = (e: any) => {
		// console.log("amenities names of handle amenties", e.target.value)

		if (e.target.checked === true) {
			setAmenitiesArray((prevNames: any) => [...prevNames, e.target.value]);
			// console.log(amenitiesArray, "amenties array")
			setFilterFlag(true);
		} else if (e.target.checked === false) {
			setAmenitiesArray(
				amenitiesArray.filter((item: any) => item !== e.target.value)
			);
			setFilterFlag(false);
		}
	};

	const handleTrvlMood = (e: any) => {
		// console.log("amenities names of handle amenties", e.target.value)

		if (e.target.checked === true) {
			setTrvlMood((prevNames: any) => [...prevNames, e.target.value]);
			// console.log(amenitiesArray, "amenties array")
			setFilterFlag(true);
		} else if (e.target.checked === false) {
			setTrvlMood(trvlMood.filter((item: any) => item !== e.target.value));
			setFilterFlag(false);
		}
	};

	const handleActivitiesName = (e: any) => {
		if (e.target.checked === true) {
			setActivitiesArray((prevNames: any) => [...prevNames, e.target.value]);
		} else if (e.target.checked === false) {
			setActivitiesArray(
				activitiesArray.filter((item: any) => item !== e.target.value)
			);
		}
	};

	const handleCancelTime = (e: any) => {
		// console.log(e);

		if (cancelTimeArray[0] === "0") {
			cancelTimeArray.shift();
		}
		if (e.target.checked === true) {
			setCancelTimeArray((prevNames: any) => [...prevNames, e.target.value]);
			setFilterFlag(true);
		} else if (e.target.checked === false) {
			setCancelTimeArray(
				cancelTimeArray.filter((item: any) => item !== e.target.value)
			);
			setFilterFlag(false);
		}
	};

	const handleStarRating = (e: any) => {
		if (e.target.checked === true) {
			setStarRatingArray((prevNames: any) => [...prevNames, e.target.value]);
			setFilterFlag(true);
		} else if (e.target.checked === false) {
			setStarRatingArray(
				starRatingArray.filter((item: any) => item !== e.target.value)
			);
			setFilterFlag(false);
		}
	};
	// ============= filters code end's here ===========

	// ================ date range picker ==========================
	const handleDateChange = (item: any) => {
		setDateRange([item.selection]);
		const startDate: Date = item.selection.startDate as Date;
		const endDate: Date = item.selection.endDate as Date;

		let tempDate = startDate
			.toLocaleString("en-US", {
				timeZone: process.env.LOCAL_TZ,
				hour12: false,
			})
			.split(",")[0]
			.split("/");

		let startDateStr =
			tempDate[2] +
			"-" +
			(parseInt(tempDate[0]) < 10 ? "0" : "") +
			tempDate[0] +
			"-" +
			(parseInt(tempDate[1]) < 10 ? "0" : "") +
			tempDate[1];

		tempDate = endDate
			.toLocaleString("en-US", {
				timeZone: process.env.LOCAL_TZ,
				hour12: false,
			})
			.split(",")[0]
			.split("/");

		let endDateStr: any =
			tempDate[2] +
			"-" +
			(parseInt(tempDate[0]) < 10 ? "0" : "") +
			tempDate[0] +
			"-" +
			(parseInt(tempDate[1]) < 10 ? "0" : "") +
			tempDate[1];

		if (startDateStr === endDateStr) {
			let FirstDate = new Date(startDateStr);

			let nextDay = new Date(startDateStr);
			nextDay.setDate(nextDay.getDate() + 1);
			let SecondDate = nextDay;

			const dateString = FirstDate.toString();
			const checkInDay = dateString.slice(8, 10);
			const checkInMonth = dateString.slice(4, 7);
			const checkInYear = dateString.slice(13, 15);

			const DateStringEnd = SecondDate.toString();
			const checkOutDay = DateStringEnd.slice(8, 10);
			const checkOutMonth = DateStringEnd.slice(4, 7);
			const checkOutYear = DateStringEnd.slice(13, 15);

			const fullDateIn = checkInDay + checkInMonth + checkInYear;
			const fullDateStart = new Date(fullDateIn);
			setApiInDate(fullDateStart);

			const fullDateOut = checkOutDay + checkOutMonth + checkOutYear;
			const fullDateEnd = new Date(fullDateOut);
			setApiOutDate(fullDateEnd);

			setCheckedInDate({
				day: checkInDay,
				month: checkInMonth,
				year: checkInYear,
			});
			setCheckedOutDate({
				day: checkOutDay,
				month: checkOutMonth,
				year: checkOutYear,
			});
		} else {
			let FirstDate = new Date(startDateStr);
			let SecondDate = new Date(endDateStr);

			const dateString = FirstDate.toString();
			const checkInDay = dateString.slice(8, 10);
			const checkInMonth = dateString.slice(4, 7);
			const checkInYear = dateString.slice(13, 15);

			const DateStringEnd = SecondDate.toString();
			const checkOutDay = DateStringEnd.slice(8, 10);
			const checkOutMonth = DateStringEnd.slice(4, 7);
			const checkOutYear = DateStringEnd.slice(13, 15);

			const fullDateIn = checkInDay + checkInMonth + checkInYear;
			const fullDateStart = new Date(fullDateIn);
			setApiInDate(fullDateStart);

			const fullDateOut = checkOutDay + checkOutMonth + checkOutYear;
			const fullDateEnd = new Date(fullDateOut);
			setApiOutDate(fullDateEnd);

			setCheckedInDate({
				day: checkInDay,
				month: checkInMonth,
				year: checkInYear,
			});
			setCheckedOutDate({
				day: checkOutDay,
				month: checkOutMonth,
				year: checkOutYear,
			});
			filters.checkIn.set(startDateStr);
			filters.checkOut.set(endDateStr);
			startDateStr !== endDateStr && setOpenCalendar(false);
		}
		filters.checkIn.set(startDateStr);
		filters.checkOut.set(endDateStr);
		startDateStr !== endDateStr && setOpenCalendar(false);
	};
	// ========== date range picker ================================

	const handleGuestsNumberChange = (type: string, arith: string) => {
		let obj = { ...guestsFilter };
		if (arith === "add") obj[type]++;
		if (
			arith === "sub" &&
			(type === "adults" || type === "rooms") &&
			obj[type] !== 1
		)
			obj[type]--;
		if (
			arith === "sub" &&
			(type === "childRange1" ||
				type === "childRange2" ||
				type === "childRange3") &&
			obj[type] !== 0
		)
			obj[type]--;
		if (arith === "sub" && type === "groupAdults" && obj[type] !== 4)
			obj[type]--;

		// console.log("object", obj);
		setGuestFilter(obj);
	};

	const guestsFilterApply = () => {
		/* let obj = { ...filters }
    obj['guests'] = guestsFilter */
		// console.log("guestsFilter===>", guestsFilter);
		filters.guests.set(guestsFilter);
		setGuestsFilterShow(false);
	};

	// const getPlaceByInput = (placeInput: string) => {
	//   placeInput = placeInput.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
	//   let searchPattern = new RegExp(placeInput, "i")
	//   if (placeInput.length == 2)
	//     return axios.get(`${API_URL}/v1/user/location?place=${placeInput}`)
	//       .then((res) => {

	//         let result = res.data.data;
	//         let city: string[] = [];
	//         let resort: string[] = [];
	//         for (let item of result) {
	//           if (searchPattern.test(item.property_city)) {
	//             if (!city.some(e => e.toLowerCase() === item.property_city.toLowerCase()) &&
	//               !defaultPlaceOptions.some((e: any) => e.value.toLowerCase() === item.property_city.toLowerCase())
	//             )
	//               city.push(item.property_city)
	//           }
	//           if (searchPattern.test(item.property_name)) {
	//             !resort.includes(item.property_name + ", " + item.property_city) && resort.push(item.property_name + ", " + item.property_city)
	//           }
	//         }
	//         let cityOptions = city.map(e => ({ label: e, value: e, data: e }));
	//         let resortOptions = resort.map(e => ({ label: e + " (Resort)", value: e, data: e }));
	//         setResultPlaceOptions([...cityOptions, ...resortOptions]);
	//         return [...cityOptions, ...resortOptions];
	//       });
	//   else {
	//     let matchingPlace = resultPlaceOptions.filter((e: any) => {
	//       if (searchPattern.test(e.value))
	//         return e
	//     })

	//     return Promise.resolve(matchingPlace);
	//   }
	// }

	// const handlePlaceChange = (newValue: any) => {
	//   filters.defaultPlaceOptions.set(d => {
	//     if (!d.some(o => o.value === newValue.value)) {
	//       d.unshift(newValue);
	//       return d;
	//     }
	//     return d;
	//   })
	//   filters.place.set({ ...newValue });
	//   setDefPlace({ ...newValue });
	// }

	const handleFindResorts = () => {
		setWeekendFlag(true);
		// console.log("we are here", filters);
		// console.log("we are here", coupleGroup);
		let MainUrl = `${API_URL}/v1/user/roomcategory?place=${roomSearchText}&checkin_date=${filters.checkIn.get()}&checkout_date=${filters.checkOut.get()}&no_of_guests=${
			coupleGroup ? filters.guests.adults.get() : filters.guests.adults.get()
		}&max_price=${filters.maxPrice.get()}&amenities=${amenitiesArray}&activities=${activitiesArray}&starRating=${starRatingArray}&cancellation=${cancelTimeArray}`;

		setLoader(true);

		if (TravelMoods) {
			const Url = `${API_URL}/v1/user/travel_mood?travelMood=Adventure`;
			axios.get(Url).then((res) => {
				// console.log(res.data, "travel moods");
				console.log("");
			});
		}
		axios.get(MainUrl).then((res) => {
			setLoader(false);
			// console.log("---------->", res.data.data);
			let d: any = [];
			res.data.data.map((value: any) => {
				value.property &&
					value.property[0] &&
					value.property[0].property_status === "Published" &&
					d.push(value);
			});
			const searchArray: any = roomSearchText.split(" ");
			const searchArrayLength: number = searchArray.length;
			// console.log("Data", searchArray);

			let results: any = [];

			if (searchArray && searchArray.length > 0) {
				searchArray.map((key: any) => {
					results.push(
						d.filter((e: any) => {
							return (
								e?.catdetails?.name.toLowerCase().includes(key.toLowerCase()) ||
								e?.catdetails?.description
									.toLowerCase()
									.includes(key.toLowerCase().trim()) ||
								e?.property[0]?.property_basic_info?.property_name
									.toLowerCase()
									.includes(key.toLowerCase().trim()) ||
								e?.property[0]?.property_location?.property_address
									.toLowerCase()
									.includes(key.toLowerCase().trim()) ||
								e?.property[0]?.property_location?.property_city
									.toLowerCase()
									.includes(key.toLowerCase().trim()) ||
								e?.property[0]?.property_location?.property_nearest_gate
									.toLowerCase()
									.includes(key.toLowerCase().trim()) ||
								e?.property[0]?.property_location?.property_locality
									.toLowerCase()
									.includes(key.toLowerCase().trim()) ||
								e?.property[0]?.property_location?.property_state
									.toLowerCase()
									.includes(key.toLowerCase())
							);
						})
					);
				});
				// results.push(res);
			}
			// results = d.filter((e: any) => {
			// 	return (
			// 		e?.catdetails?.name
			// 			.toLowerCase()
			// 			.includes(roomSearchText.toLowerCase()) ||
			// 		e?.catdetails?.description
			// 			.toLowerCase()
			// 			.includes(roomSearchText.toLowerCase()) ||
			// 		e?.property[0]?.property_basic_info?.property_name
			// 			.toLowerCase()
			// 			.includes(roomSearchText.toLowerCase()) ||
			// 		e?.property[0]?.property_location?.property_address
			// 			.toLowerCase()
			// 			.includes(roomSearchText.toLowerCase()) ||
			// 		e?.property[0]?.property_location?.property_city
			// 			.toLowerCase()
			// 			.includes(roomSearchText.toLowerCase()) ||
			// 		e?.property[0]?.property_location?.property_nearest_gate
			// 			.toLowerCase()
			// 			.includes(roomSearchText.toLowerCase()) ||
			// 		e?.property[0]?.property_location?.property_locality
			// 			.toLowerCase()
			// 			.includes(roomSearchText.toLowerCase()) ||
			// 		e?.property[0]?.property_location?.property_state
			// 			.toLowerCase()
			// 			.includes(roomSearchText.toLowerCase())
			// 	);
			// });
			// console.log("results", results);

			// var foo = [];

			// for (var i = 0; i < searchArrayLength; i++) {
			// 	foo.push("...result[" + i + "]");
			// 	// Array.prototype.push.apply(arr1,arr2);
			// }
			// console.log("foo", foo);
			const flattened = results.flat();

			// console.log(flattened);
			const unique2 = flattened.filter((obj: any, index: any) => {
				return index === flattened.findIndex((o: any) => obj._id === o._id);
			});

			// console.log(flattened);
			// console.log("unique", unique2);

			// let fResult = [...arr1, ...arr2];

			if (res.data.data) {
				setSearchResult(roomSearchText ? unique2 : res.data.data);
				setSearchResultFilter(roomSearchText ? unique2 : res.data.data);
				if (!localStorage.getItem("data")) {
					localStorage.setItem(
						"data",
						JSON.stringify(roomSearchText ? unique2 : res.data.data)
					);
				}
			}
			if (
				amenitiesArray.length ||
				trvlMood.length ||
				starRatingArray.length ||
				cancelTimeArray.length
			) {
				setLoader(true);
				const userJson = localStorage.getItem("data");
				let searchData = userJson !== null ? JSON.parse(userJson) : null;
				let arr: any = [];
				arr = searchData;

				let filterdStar: any = [];
				let filteredAmenities: any = [];
				let filterdTrvl: any = [];
				let filteredCancl: any = [];
				let k: any = "";
				if (arr && arr.length > 0) {
					if (starRatingArray && starRatingArray.length > 0) {
						const test = starRatingArray.map((val: any) => {
							const matchedGenres = arr.filter((key: any) => {
								return (
									key.property[0] &&
									key.property[0].property_basic_info.property_star_rating ==
										val
								);
							});

							return matchedGenres; // this returns the matching objects, cool
						});
						// console.log("matchedGenres", test.flat(1));
						const result = Object.values(
							test
								.flat(1)
								.reduce(
									(acc: any, obj: any) => ({ ...acc, [obj._id]: obj }),
									{}
								)
						);
						filterdStar.push(result);
					}

					if (amenitiesArray && amenitiesArray.length > 0) {
						const test = amenitiesArray.map((val: any) => {
							const matchedGenres = arr.filter((key: any) => {
								let t =
									key.property[0] &&
									key.property[0].property_amenities.basic_facilities &&
									key.property[0].property_amenities.basic_facilities.length >
										0 &&
									key.property[0].property_amenities.basic_facilities.includes(
										val
									);
								// console.log(t);
								if (t) {
									return key;
								}
							});

							// filterdRating.push(matchedGenres);
							return matchedGenres; // this returns the matching objects, cool
						});
						const result = Object.values(
							test
								.flat(1)
								.reduce(
									(acc: any, obj: any) => ({ ...acc, [obj._id]: obj }),
									{}
								)
						);
						filteredAmenities.push(result);
					}

					if (trvlMood && trvlMood.length > 0) {
						const test = trvlMood.map((val: any) => {
							console.log(val);

							const matchedGenres = arr.filter((key: any) => {
								let t =
									key.property[0] &&
									key.property[0].property_amenities.travel_moods &&
									key.property[0].property_amenities.travel_moods.length > 0 &&
									key.property[0].property_amenities.travel_moods.includes(val);
								// console.log(t);
								if (t) {
									return key;
								}
							});
							// filterdRating.push(matchedGenres);
							return matchedGenres; // this returns the matching objects, cool
						});
						const result = Object.values(
							test
								.flat(1)
								.reduce(
									(acc: any, obj: any) => ({ ...acc, [obj._id]: obj }),
									{}
								)
						);
						filterdTrvl.push(result);
					}
					if (cancelTimeArray && cancelTimeArray.length > 0) {
						const test = cancelTimeArray.map((val: any) => {
							const matchedGenres = arr.filter((key: any) => {
								return (
									key.property[0] &&
									key.property[0].property_policies &&
									key.property[0].property_policies.cancellation_policy === val
								);
							});

							return matchedGenres; // this returns the matching objects, cool
						});
						// console.log("matchedGenres", test.flat(1));
						const result = Object.values(
							test
								.flat(1)
								.reduce(
									(acc: any, obj: any) => ({ ...acc, [obj._id]: obj }),
									{}
								)
						);
						filteredCancl.push(result);
					}
				}
				const final: any = [];
				if (filters.maxPrice.get()) {
					final.push(filteredAmenities);
					final.push(filterdTrvl);
					final.push(filteredCancl);
					final.push(filteredCancl);
					// final.flat(1);
					setLoader(false);
					const result: any = Object.values(
						final
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					// console.log("findal", result);
					// console.log("findal", result[0]);

					const finalMatched =
						result &&
						result[0] &&
						result[0].filter((key: any) => {
							return (
								key.catPrice &&
								key.catPrice.base_price <= filters.maxPrice.get()
							);
						});
					// console.log("finalMatched", finalMatched);
					setSearchResultFilter(finalMatched);
				}
			}
			// else {
			// 	setSearchResult(res.data.data);
			// }

			// setSearchResultFilter(res.data.data);
			if (window.innerWidth < 550) {
				handleClick();
			}
		});
	};

	// ========  Find Resort Button to Scroll Down to Room Cards Code Start's here ==========
	const cardsRef = useRef<any>();
	const handleClick = () => {
		cardsRef.current?.scrollIntoView({ behavior: "smooth" });
	};
	// ========  Find Resort Button to Scroll Down to Room Cards Code End's here ==========

	useEffect(() => {
		handleFindResorts();

		if (window.innerWidth < 550) {
			window.scrollTo({ top: 140, behavior: "smooth" });
		} else if (window.innerWidth >= 1200) {
			window.scrollTo({ top: 410, behavior: "smooth" });
		} else if (window.innerWidth > 700 && window.innerWidth < 1200) {
			window.scrollTo({ top: 280, behavior: "smooth" });
		}
	}, []);
	const [showMessage, setShowMessage] = useState(false);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setShowMessage(true);
		}, 2000);

		return () => clearTimeout(timeoutId);
	}, []); // Empty dependency array ensures this effect runs only once

	//  couple group switch button code
	const handleChecked = () => {
		// console.log("coupleGroup", coupleGroup);

		setCoupleGroup(!coupleGroup);
	};
	//  couple group switch button code

	const datas = {
		rooms: guestsFilter.rooms,
		adults: guestsFilter.adults,
		groupAdults: guestsFilter.groupAdults,
		childRange1: guestsFilter.childRange1,
		childRange2: guestsFilter.childRange2,
		childRange3: guestsFilter.childRange3,
		coupleGroupVal: coupleGroup,
		checkInDate: apiInDate === "" ? dateString : apiInDate,
		checkOutDate: apiOutDate === "" ? DateStringEnd : apiOutDate,
	};

	// console.log("checkInDate", datas);

	useEffect(() => {
		if (datas.checkInDate && weekendFlag) {
			var today = new Date(datas.checkInDate);
			if (today.getDay() == 6 || today.getDay() == 0) {
				setWeekend(true);
			} else {
				setWeekend(false);
			}
		}
		setWeekendFlag(false);
	}, [datas, weekendFlag]);

	// setWeekend(
	// 	new Date(datas.checkInDate).getDay() == 6 ||
	// 		new Date(datas.checkInDate).getDay() == 0
	// 		? true
	// 		: false
	// );

	const handleSort = (e: any) => {
		// console.log(e);
		// console.log(weekend);

		setSelectType(e);
		// console.log("searchResult", searchResult);
		let data = searchResult;
		// console.log(data);
		// var newArray = data.filter(function (el) {
		//   return el.catPrice.base_price <= 1000 &&
		//          el.sqft >= 500 &&
		//          el.num_of_beds >=2 &&
		//          el.num_of_baths >= 2.5;
		// });
		if (e == "popular" || e === "new") {
			setSearchResult(searchResultFilter);
		}

		if (e == "priceLow") {
			if (weekend) {
				data.sort(
					(a, b) =>
						parseFloat(
							a.weekend_pricing
								? a.weekend_pricing.base_weekend_price
								: a.catPrice.base_price
						) -
						parseFloat(
							b.weekend_pricing
								? b.weekend_pricing.base_weekend_price
								: b.catPrice.base_price
						)
				);
				setSearchResult(data);
			} else {
				data.sort(
					(a, b) =>
						parseFloat(a.catPrice.base_price) -
						parseFloat(b.catPrice.base_price)
				);
				setSearchResult(data);
			}
		}
		if (e == "priceHigh") {
			if (weekend) {
				data.sort(
					(a, b) =>
						parseFloat(
							b.weekend_pricing
								? b.weekend_pricing.base_weekend_price
								: b.catPrice.base_price
						) -
						parseFloat(
							a.weekend_pricing
								? a.weekend_pricing.base_weekend_price
								: a.catPrice.base_price
						)
				);
				setSearchResult(data);
			} else {
				data.sort(
					(a, b) =>
						parseFloat(b.catPrice.base_price) -
						parseFloat(a.catPrice.base_price)
				);
				setSearchResult(data);
			}
		}
		// data.sort(
		// 	(a, b) =>
		// 		parseFloat(a.catPrice.base_price) - parseFloat(b.catPrice.base_price)
		// );
	};

	useEffect(() => {
		setLoader(true);
		// const finalMatched =
		// 	searchResult &&
		// 	searchResult.filter((key: any) => {
		// 		return (
		// 			key.catPrice && key.catPrice.base_price <= filters.maxPrice.get()
		// 		);
		// 	});
		// // console.log("finalMatched", finalMatched);
		// setSearchResultFilter(finalMatched);
		// setSearchResult(finalMatched);
		handleFindResorts();
		setTimeout(() => setLoader(false), 3000);
	}, [filters.maxPrice.get()]);

	useEffect(() => {
		setLoader(true);
		if (
			amenitiesArray.length ||
			trvlMood.length ||
			starRatingArray.length ||
			cancelTimeArray.length
		) {
			setLoader(true);
			const userJson = localStorage.getItem("data");
			// console.log("---->", userJson);

			let searchData = userJson !== null ? JSON.parse(userJson) : null;
			let arr: any = [];
			arr = searchData;

			let filterdStar: any = [];
			let filteredAmenities: any = [];
			let filterdTrvl: any = [];
			let filteredCancl: any = [];
			let k: any = "";
			if (arr && arr.length > 0) {
				if (starRatingArray && starRatingArray.length > 0) {
					const test = starRatingArray.map((val: any) => {
						const matchedGenres = arr.filter((key: any) => {
							return (
								key.property[0] &&
								key.property[0].property_basic_info.property_star_rating == val
							);
						});

						return matchedGenres; // this returns the matching objects, cool
					});
					// console.log("matchedGenres", test.flat(1));
					const result = Object.values(
						test
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					// console.log(":ressss 1", result);

					filterdStar.push(result);
				}

				if (amenitiesArray && amenitiesArray.length > 0) {
					const test = amenitiesArray.map((val: any) => {
						const matchedGenres = arr.filter((key: any) => {
							let t =
								key.property[0] &&
								key.property[0].property_amenities.basic_facilities &&
								key.property[0].property_amenities.basic_facilities.length >
									0 &&
								key.property[0].property_amenities.basic_facilities.includes(
									val
								);
							// console.log(t);
							if (t) {
								return key;
							}
						});

						// filterdRating.push(matchedGenres);
						return matchedGenres; // this returns the matching objects, cool
					});
					const result = Object.values(
						test
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					filteredAmenities.push(result);
				}

				if (trvlMood && trvlMood.length > 0) {
					const test = trvlMood.map((val: any) => {
						// console.log(val);

						const matchedGenres = arr.filter((key: any) => {
							let t =
								key.property[0] &&
								key.property[0].property_amenities.travel_moods &&
								key.property[0].property_amenities.travel_moods.length > 0 &&
								key.property[0].property_amenities.travel_moods.includes(val);
							// console.log(t);
							if (t) {
								return key;
							}
						});
						// filterdRating.push(matchedGenres);
						return matchedGenres; // this returns the matching objects, cool
					});
					const result = Object.values(
						test
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					filterdTrvl.push(result);
				}
				if (cancelTimeArray && cancelTimeArray.length > 0) {
					const test = cancelTimeArray.map((val: any) => {
						const matchedGenres = arr.filter((key: any) => {
							return (
								key.property[0] &&
								key.property[0].property_policies &&
								key.property[0].property_policies.cancellation_policy === val
							);
						});

						return matchedGenres; // this returns the matching objects, cool
					});
					// console.log("matchedGenres", test.flat(1));
					const result = Object.values(
						test
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					filteredCancl.push(result);
				}
			}
			const final: any = [];
			if (filters.maxPrice.get()) {
				final.push(filteredAmenities);
				final.push(filterdTrvl);
				final.push(filterdStar);
				final.push(filteredCancl);
				// final.flat(1);
				setLoader(false);

				// console.log("Final --- ", final);

				const result: any = Object.values(
					final
						.flat(1)
						.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
				);
				// console.log("findal", result);
				// console.log("findal", result[0]);

				const finalMatched =
					result &&
					result[0] &&
					result[0].filter((key: any) => {
						return (
							key.catPrice && key.catPrice.base_price <= filters.maxPrice.get()
						);
					});
				// console.log("finalMatched", finalMatched);
				setSearchResultFilter(finalMatched);
				setSearchResult(finalMatched);
			}
			// setFilterFlag(true);
		}

		setLoader(false);
	}, [starRatingArray && starRatingArray.length]);

	useEffect(() => {
		setLoader(true);
		if (
			amenitiesArray.length ||
			trvlMood.length ||
			starRatingArray.length ||
			cancelTimeArray.length
		) {
			setLoader(true);
			const userJson = localStorage.getItem("data");
			let searchData = userJson !== null ? JSON.parse(userJson) : null;
			let arr: any = [];
			arr = searchData;

			let filterdStar: any = [];
			let filteredAmenities: any = [];
			let filterdTrvl: any = [];
			let filteredCancl: any = [];
			let k: any = "";
			if (arr && arr.length > 0) {
				if (starRatingArray && starRatingArray.length > 0) {
					const test = starRatingArray.map((val: any) => {
						const matchedGenres = arr.filter((key: any) => {
							return (
								key.property[0] &&
								key.property[0].property_basic_info.property_star_rating == val
							);
						});

						return matchedGenres; // this returns the matching objects, cool
					});
					// console.log("matchedGenres", test.flat(1));
					const result = Object.values(
						test
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					filterdStar.push(result);
				}

				if (amenitiesArray && amenitiesArray.length > 0) {
					const test = amenitiesArray.map((val: any) => {
						const matchedGenres = arr.filter((key: any) => {
							let t =
								key.property[0] &&
								key.property[0].property_amenities.basic_facilities &&
								key.property[0].property_amenities.basic_facilities.length >
									0 &&
								key.property[0].property_amenities.basic_facilities.includes(
									val
								);
							// console.log(t);
							if (t) {
								return key;
							}
						});

						// filterdRating.push(matchedGenres);
						return matchedGenres; // this returns the matching objects, cool
					});
					const result = Object.values(
						test
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					filteredAmenities.push(result);
				}

				if (trvlMood && trvlMood.length > 0) {
					const test = trvlMood.map((val: any) => {
						// console.log(val);

						const matchedGenres = arr.filter((key: any) => {
							let t =
								key.property[0] &&
								key.property[0].property_amenities.travel_moods &&
								key.property[0].property_amenities.travel_moods.length > 0 &&
								key.property[0].property_amenities.travel_moods.includes(val);
							// console.log(t);
							if (t) {
								return key;
							}
						});
						// filterdRating.push(matchedGenres);
						return matchedGenres; // this returns the matching objects, cool
					});
					const result = Object.values(
						test
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					filterdTrvl.push(result);
				}
				if (cancelTimeArray && cancelTimeArray.length > 0) {
					const test = cancelTimeArray.map((val: any) => {
						const matchedGenres = arr.filter((key: any) => {
							return (
								key.property[0] &&
								key.property[0].property_policies &&
								key.property[0].property_policies.cancellation_policy === val
							);
						});

						return matchedGenres; // this returns the matching objects, cool
					});
					// console.log("matchedGenres", test.flat(1));
					const result = Object.values(
						test
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					filteredCancl.push(result);
				}
			}
			const final: any = [];
			if (filters.maxPrice.get()) {
				final.push(filteredAmenities);
				final.push(filterdTrvl);
				final.push(filteredCancl);
				final.push(filteredCancl);
				// final.flat(1);
				setLoader(false);
				const result: any = Object.values(
					final
						.flat(1)
						.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
				);
				// console.log("findal", result);
				// console.log("findal", result[0]);

				const finalMatched =
					result &&
					result[0] &&
					result[0].filter((key: any) => {
						return (
							key.catPrice && key.catPrice.base_price <= filters.maxPrice.get()
						);
					});
				// console.log("finalMatched", finalMatched);
				setSearchResultFilter(finalMatched);
				setSearchResult(finalMatched);
			}
			// setFilterFlag(true);
		}

		setLoader(false);
	}, [amenitiesArray && amenitiesArray.length]);

	useEffect(() => {
		setLoader(true);
		if (
			amenitiesArray.length ||
			trvlMood.length ||
			starRatingArray.length ||
			cancelTimeArray.length
		) {
			setLoader(true);
			const userJson = localStorage.getItem("data");
			let searchData = userJson !== null ? JSON.parse(userJson) : null;
			let arr: any = [];
			arr = searchData;

			let filterdStar: any = [];
			let filteredAmenities: any = [];
			let filterdTrvl: any = [];
			let filteredCancl: any = [];
			let k: any = "";
			if (arr && arr.length > 0) {
				if (starRatingArray && starRatingArray.length > 0) {
					const test = starRatingArray.map((val: any) => {
						const matchedGenres = arr.filter((key: any) => {
							return (
								key.property[0] &&
								key.property[0].property_basic_info.property_star_rating == val
							);
						});

						return matchedGenres; // this returns the matching objects, cool
					});
					// console.log("matchedGenres", test.flat(1));
					const result = Object.values(
						test
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					filterdStar.push(result);
				}

				if (amenitiesArray && amenitiesArray.length > 0) {
					const test = amenitiesArray.map((val: any) => {
						const matchedGenres = arr.filter((key: any) => {
							let t =
								key.property[0] &&
								key.property[0].property_amenities.basic_facilities &&
								key.property[0].property_amenities.basic_facilities.length >
									0 &&
								key.property[0].property_amenities.basic_facilities.includes(
									val
								);
							// console.log(t);
							if (t) {
								return key;
							}
						});

						// filterdRating.push(matchedGenres);
						return matchedGenres; // this returns the matching objects, cool
					});
					const result = Object.values(
						test
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					filteredAmenities.push(result);
				}

				if (trvlMood && trvlMood.length > 0) {
					const test = trvlMood.map((val: any) => {
						// console.log(val);

						const matchedGenres = arr.filter((key: any) => {
							let t =
								key.property[0] &&
								key.property[0].property_amenities.travel_moods &&
								key.property[0].property_amenities.travel_moods.length > 0 &&
								key.property[0].property_amenities.travel_moods.includes(val);
							// console.log(t);
							if (t) {
								return key;
							}
						});
						// filterdRating.push(matchedGenres);
						return matchedGenres; // this returns the matching objects, cool
					});
					const result = Object.values(
						test
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					filterdTrvl.push(result);
				}
				if (cancelTimeArray && cancelTimeArray.length > 0) {
					const test = cancelTimeArray.map((val: any) => {
						const matchedGenres = arr.filter((key: any) => {
							return (
								key.property[0] &&
								key.property[0].property_policies &&
								key.property[0].property_policies.cancellation_policy === val
							);
						});

						return matchedGenres; // this returns the matching objects, cool
					});
					// console.log("matchedGenres", test.flat(1));
					const result = Object.values(
						test
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					filteredCancl.push(result);
				}
			}
			const final: any = [];
			if (filters.maxPrice.get()) {
				final.push(filteredAmenities);
				final.push(filterdTrvl);
				final.push(filteredCancl);
				final.push(filteredCancl);
				// final.flat(1);
				setLoader(false);
				const result: any = Object.values(
					final
						.flat(1)
						.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
				);
				// console.log("findal", result);
				// console.log("findal", result[0]);

				const finalMatched =
					result &&
					result[0] &&
					result[0].filter((key: any) => {
						return (
							key.catPrice && key.catPrice.base_price <= filters.maxPrice.get()
						);
					});
				// console.log("finalMatched", finalMatched);
				setSearchResultFilter(finalMatched);
				setSearchResult(finalMatched);
			}
			// setFilterFlag(true);
		}

		setLoader(false);
	}, [trvlMood && trvlMood.length]);

	useEffect(() => {
		setLoader(true);
		if (
			amenitiesArray.length ||
			trvlMood.length ||
			starRatingArray.length ||
			cancelTimeArray.length
		) {
			setLoader(true);
			const userJson = localStorage.getItem("data");
			let searchData = userJson !== null ? JSON.parse(userJson) : null;
			let arr: any = [];
			arr = searchData;

			let filterdStar: any = [];
			let filteredAmenities: any = [];
			let filterdTrvl: any = [];
			let filteredCancl: any = [];
			let k: any = "";
			if (arr && arr.length > 0) {
				if (starRatingArray && starRatingArray.length > 0) {
					const test = starRatingArray.map((val: any) => {
						const matchedGenres = arr.filter((key: any) => {
							return (
								key.property[0] &&
								key.property[0].property_basic_info.property_star_rating == val
							);
						});

						return matchedGenres; // this returns the matching objects, cool
					});
					// console.log("matchedGenres", test.flat(1));
					const result = Object.values(
						test
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					filterdStar.push(result);
				}

				if (amenitiesArray && amenitiesArray.length > 0) {
					const test = amenitiesArray.map((val: any) => {
						const matchedGenres = arr.filter((key: any) => {
							let t =
								key.property[0] &&
								key.property[0].property_amenities.basic_facilities &&
								key.property[0].property_amenities.basic_facilities.length >
									0 &&
								key.property[0].property_amenities.basic_facilities.includes(
									val
								);
							// console.log(t);
							if (t) {
								return key;
							}
						});

						// filterdRating.push(matchedGenres);
						return matchedGenres; // this returns the matching objects, cool
					});
					const result = Object.values(
						test
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					filteredAmenities.push(result);
				}

				if (trvlMood && trvlMood.length > 0) {
					const test = trvlMood.map((val: any) => {
						// console.log(val);

						const matchedGenres = arr.filter((key: any) => {
							let t =
								key.property[0] &&
								key.property[0].property_amenities.travel_moods &&
								key.property[0].property_amenities.travel_moods.length > 0 &&
								key.property[0].property_amenities.travel_moods.includes(val);
							// console.log(t);
							if (t) {
								return key;
							}
						});
						// filterdRating.push(matchedGenres);
						return matchedGenres; // this returns the matching objects, cool
					});
					const result = Object.values(
						test
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					filterdTrvl.push(result);
				}
				if (cancelTimeArray && cancelTimeArray.length > 0) {
					const test = cancelTimeArray.map((val: any) => {
						const matchedGenres = arr.filter((key: any) => {
							return (
								key.property[0] &&
								key.property[0].property_policies &&
								key.property[0].property_policies.cancellation_policy === val
							);
						});

						return matchedGenres; // this returns the matching objects, cool
					});
					// console.log("matchedGenres", test.flat(1));
					const result = Object.values(
						test
							.flat(1)
							.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
					);
					filteredCancl.push(result);
				}
			}
			const final: any = [];
			if (filters.maxPrice.get()) {
				final.push(filteredAmenities);
				final.push(filterdTrvl);
				final.push(filteredCancl);
				final.push(filteredCancl);
				// final.flat(1);
				setLoader(false);
				const result: any = Object.values(
					final
						.flat(1)
						.reduce((acc: any, obj: any) => ({ ...acc, [obj._id]: obj }), {})
				);
				// console.log("findal", result);
				// console.log("findal", result[0]);

				const finalMatched =
					result &&
					result[0] &&
					result[0].filter((key: any) => {
						return (
							key.catPrice && key.catPrice.base_price <= filters.maxPrice.get()
						);
					});
				// console.log("finalMatched", finalMatched);
				setSearchResultFilter(finalMatched);
				setSearchResult(finalMatched);
			}
			// setFilterFlag(true);
		}
		setLoader(false);
	}, [cancelTimeArray && cancelTimeArray.length]);

	useEffect(() => {
		console.log("trace flag", filterFlag);

		let flag: any = true;
		if (filterFlag === false) {
			// console.log("trace flag if1", filterFlag);
			if (cancelTimeArray?.length > 0) {
				flag = false;
			}
			if (trvlMood?.length > 0) {
				flag = false;
			}
			if (amenitiesArray?.length > 0) {
				flag = false;
			}
			if (starRatingArray?.length > 0) {
				flag = false;
			}
			if (flag) {
				// console.log("trace flag if2", filterFlag);
				handleFindResorts();
				setFilterFlag(false);
			} else {
				setFilterFlag(true);
			}
		}

		// setFilterFlag(true);
	}, [filterFlag]);

	// const handlePopularResorts = () => {
	// 	axios.get(`${API_URL}/v1/user/mostPopularProperty`).then((res) => {
	// 		console.log("res.data.data", res.data.data);
	// 		setSearchResult(res.data.data);
	// 		setSearchResultFilter(res.data.data);
	// 	});
	// };
	const enterKye = (e: any) => {
		if (e.key === "Enter") {
			handleFindResorts();
		}
	};

	return (
		<>
			{/* <UserHeader /> */}
			<div className="room-detail-container fix-top-nav-padd">
				<div className="room-details-banner">
					<img
						src={toAbsoluteUrl("/media/img/banner-2.jpg")}
						alt="MyResorts.in"
					/>
					<div className="room-details-banner-info">
						<h1>Find Popular Resorts</h1>
						<h5>
							Luxurious rooms with stunning views and premium amenities. Cozy
							suites to spacious villas. Browse our gallery and book for an
							unforgettable getaway!
						</h5>
					</div>
					<div className="overlay-hy">
						<img
							src={toAbsoluteUrl("/media/img/tree-shape.png")}
							alt="MyResorts.in"
						/>
					</div>
				</div>

				<div className="container">
					<Row>
						<Col lg="12">
							<div className="breadcum-wrapper">
								<div className="breadcum">
									<ul>
										<li className="room-breadcum-li">
											<a href="home" className="home-bredcum">
												Home
											</a>
										</li>
										<li className="room-breadcum-li">
											<a href="rooms" className="rooms-bredcum">
												Resorts / Rooms
											</a>
										</li>
									</ul>
								</div>
								<div className="sort">
									<p>Sort By</p>
									<select
										name="cars"
										id="cars"
										style={{ outline: "none" }}
										className="sort-tag"
										onChange={(e) => {
											handleSort(e.target.value);
											// console.log("e.target.value", e.target.value);
											// setSelectType(e.target.value);
										}}
									>
										{/* <option className="select-option" value="popular">
											Popularity
										</option>
										<option className="select-option" value="new">
											Newest Arrival{" "}
										</option>
										<option className="select-option" value="review">
											Avg. Cust. Reviews
										</option> */}
										<option className="select-option" value="priceLow">
											Price Low To High
										</option>
										<option className="select-option" value="priceHigh">
											Price High To Low
										</option>
									</select>
								</div>
							</div>
						</Col>
					</Row>
				</div>
				<div className="container">
					<Row>
						<Col lg="4" xs="12">
							<div className="room-search-left-panel">
								<div className="green-checkin-box">
									<div className="rooms-couple-group-tog">
										<div
											className="switch-button rooms-switch-btn"
											onClick={handleChecked}
										>
											<input
												className="switch-button-checkbox"
												type="checkbox"
												checked={coupleGroup}
												readOnly
											></input>
											<label className="switch-button-label">
												<span className="switch-button-label-span">COUPLE</span>
											</label>
										</div>
									</div>

									<div className="search-text-container">
										<input
											className="search-text-input"
											placeholder="Search Resorts"
											type="text"
											onChange={(e) => setRoomSearchText(e.target.value)}
											value={`${roomSearchText}`}
											onKeyPress={(e) => {
												enterKye(e);
											}}
										/>
									</div>

									<div className="chk-in-check-out-wrap" ref={calenderRef}>
										<div
											className="chk-in-check-out-box"
											onClick={() => {
												return (
													setOpenCalendar(!openCalendar),
													setGuestsFilterShow(false)
												);
											}}
										>
											<p>check - in</p>
											<span>
												{checkedInDate === "" ? (
													<>
														<h2>{checkInDay}</h2>
														<h3>
															{checkInMonth}'{checkInYear}
														</h3>
													</>
												) : (
													<>
														<h2>{checkedInDate.day}</h2>
														<h3>
															{checkedInDate.month}'{checkedInDate.year}
														</h3>
													</>
												)}
											</span>
										</div>
										<div
											className="chk-in-check-out-box"
											onClick={() => {
												return (
													setOpenCalendar(!openCalendar),
													setGuestsFilterShow(false)
												);
											}}
										>
											<p>check - out</p>
											<span>
												{checkedOutDate === "" ? (
													<>
														<h2>{checkOutDay}</h2>
														<h3>
															{checkOutMonth}'{checkOutYear}
														</h3>
													</>
												) : (
													<>
														<h2>{checkedOutDate.day}</h2>
														<h3>
															{checkedOutDate.month}'{checkedOutDate.year}
														</h3>
													</>
												)}
											</span>
										</div>
										{openCalendar && (
											<div className="dateRange-box">
												<DateRange
													editableDateInputs={true}
													onChange={(item) => handleDateChange(item)} //setDateRange([item.selection])}
													moveRangeOnFirstSelection={false}
													ranges={dateRange}
													minDate={new Date()}
													months={2}
													direction="horizontal"
													className="calendarElement"
													rangeColors={["#009933"]}
												/>
											</div>
										)}

										<div className="chk-in-check-out-box">
											<p>Rooms</p>
											<span
												onClick={() => {
													return (
														setGuestsFilterShow(!guestsFilterShow),
														setOpenCalendar(false)
													);
												}}
											>
												<h2>
													{guestsFilter.rooms}{" "}
													<AiFillCaretDown style={{ fontSize: "20px" }} />
												</h2>
											</span>
										</div>

										<div className="chk-in-check-out-box">
											<p>Guests</p>
											<span
												onClick={() => {
													return (
														setGuestsFilterShow(!guestsFilterShow),
														setOpenCalendar(false)
													);
												}}
											>
												<h2>
													{totalGuestsNum}{" "}
													<AiFillCaretDown style={{ fontSize: "20px" }} />{" "}
												</h2>
											</span>
										</div>

										{guestsFilterShow && (
											<>
												{coupleGroup ? (
													<>
														<div
															className="guest-filter-show group-filter-show resort-guest-filter-show"
															onBlur={() => setGuestsFilterShow(false)}
														>
															<div
																className="container-fluid user-select-none"
																style={{ cursor: "pointer", margin: "auto" }}
															>
																<div className="row p-4 guest-filter-inputs">
																	<div className="col">
																		<label>Adults</label>
																		<div
																			className="row"
																			style={{
																				boxShadow: "0px 0px 5px green",
																				borderRadius: "5px",
																				marginTop: "5px",
																				width: "110px",
																			}}
																		>
																			<div
																				className="col-3 border text-center"
																				onClick={() =>
																					handleGuestsNumberChange(
																						"groupAdults",
																						"sub"
																					)
																				}
																			>
																				<span className="fw-bold fs-3 user-select-none">
																					-
																				</span>
																			</div>
																			<div className="col-6 border text-center">
																				<span className="fw-bold fs-3 user-select-none">
																					{guestsFilter["groupAdults"]}
																				</span>
																			</div>
																			<div
																				className="col-3 border text-center"
																				onClick={() =>
																					handleGuestsNumberChange(
																						"groupAdults",
																						"add"
																					)
																				}
																			>
																				<span className="fw-bold fs-3 user-select-none">
																					+
																				</span>
																			</div>
																		</div>
																	</div>
																	<div className="col">
																		Child (0-12 yrs)
																		<div
																			className="row"
																			style={{
																				boxShadow: "0px 0px 5px green",
																				borderRadius: "5px",
																				marginTop: "5px",
																				width: "110px",
																			}}
																		>
																			<div
																				className="col-3 border text-center"
																				onClick={() =>
																					handleGuestsNumberChange(
																						"childRange3",
																						"sub"
																					)
																				}
																			>
																				<span className="fw-bold fs-3 user-select-none">
																					-
																				</span>
																			</div>
																			<div className="col-6 border text-center">
																				<span className="fw-bold fs-3 user-select-none">
																					{guestsFilter["childRange3"]}
																				</span>
																			</div>
																			<div
																				className="col-3 border text-center"
																				onClick={() =>
																					handleGuestsNumberChange(
																						"childRange3",
																						"add"
																					)
																				}
																			>
																				<span className="fw-bold fs-3 user-select-none">
																					+
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="d-flex justify-content-around mt-5">
																	<button
																		className="guests_done_apply_button"
																		type="button"
																		onClick={() => guestsFilterApply()}
																	>
																		Apply
																	</button>
																</div>
															</div>
														</div>
													</>
												) : (
													<>
														<div
															className="guest-filter-show resort-guest-filter-show"
															onBlur={() => setGuestsFilterShow(false)}
														>
															<div
																className="container-fluid user-select-none"
																style={{ cursor: "pointer", margin: "auto" }}
															>
																<div className="row p-4 guest-filter-inputs">
																	<div className="col">
																		<label>Rooms</label>
																		<div
																			className="row"
																			style={{
																				boxShadow: "0px 0px 5px green",
																				borderRadius: "5px",
																				marginTop: "5px",
																				width: "110px",
																			}}
																		>
																			<div
																				className="col-3 border text-center fw-bold fs-3 user-select-none"
																				onClick={() =>
																					handleGuestsNumberChange(
																						"rooms",
																						"sub"
																					)
																				}
																			>
																				-
																			</div>
																			<div className="col-6 border text-center fw-bold fs-3 user-select-none">
																				{guestsFilter["rooms"]}
																			</div>
																			<div
																				className="col-3 border text-center fw-bold fs-3 user-select-none"
																				onClick={() =>
																					handleGuestsNumberChange(
																						"rooms",
																						"add"
																					)
																				}
																			>
																				+
																			</div>
																		</div>
																	</div>
																	<div className="col">
																		<label>Adults</label>
																		<div
																			className="row"
																			style={{
																				boxShadow: "0px 0px 5px green",
																				borderRadius: "5px",
																				marginTop: "5px",
																				width: "110px",
																			}}
																		>
																			<div
																				className="col-3 border text-center"
																				onClick={() =>
																					handleGuestsNumberChange(
																						"adults",
																						"sub"
																					)
																				}
																			>
																				<span className="fw-bold fs-3 user-select-none">
																					-
																				</span>
																			</div>
																			<div className="col-6 border text-center">
																				<span className="fw-bold fs-3 user-select-none">
																					{guestsFilter["adults"]}
																				</span>
																			</div>
																			<div
																				className="col-3 border text-center"
																				onClick={() =>
																					handleGuestsNumberChange(
																						"adults",
																						"add"
																					)
																				}
																			>
																				<span className="fw-bold fs-3 user-select-none">
																					+
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="row mt-2 p-4 guest-filter-inputs">
																	<div className="col">
																		Child (0-6 yrs)
																		<div
																			className="row"
																			style={{
																				boxShadow: "0px 0px 5px green",
																				borderRadius: "5px",
																				marginTop: "5px",
																				width: "110px",
																			}}
																		>
																			<div
																				className="col-3 border text-center"
																				onClick={() =>
																					handleGuestsNumberChange(
																						"childRange1",
																						"sub"
																					)
																				}
																			>
																				<span className="fw-bold fs-3 user-select-none">
																					-
																				</span>
																			</div>
																			<div className="col-6 border text-center">
																				<span className="fw-bold fs-3 user-select-none">
																					{guestsFilter["childRange1"]}
																				</span>
																			</div>
																			<div
																				className="col-3 border text-center"
																				onClick={() =>
																					handleGuestsNumberChange(
																						"childRange1",
																						"add"
																					)
																				}
																			>
																				<span className="fw-bold fs-3 user-select-none">
																					+
																				</span>
																			</div>
																		</div>
																	</div>
																	<div className="col">
																		Child (6-12 yrs)
																		<div
																			className="row"
																			style={{
																				boxShadow: "0px 0px 5px green",
																				borderRadius: "5px",
																				marginTop: "5px",
																				width: "110px",
																			}}
																		>
																			<div
																				className="col-3 border text-center"
																				onClick={() =>
																					handleGuestsNumberChange(
																						"childRange2",
																						"sub"
																					)
																				}
																			>
																				<span className="fw-bold fs-3 user-select-none">
																					-
																				</span>
																			</div>
																			<div className="col-6 border text-center">
																				<span className="fw-bold fs-3 user-select-none">
																					{guestsFilter["childRange2"]}
																				</span>
																			</div>
																			<div
																				className="col-3 border text-center"
																				onClick={() =>
																					handleGuestsNumberChange(
																						"childRange2",
																						"add"
																					)
																				}
																			>
																				<span className="fw-bold fs-3 user-select-none">
																					+
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="d-flex justify-content-around mt-5">
																	<button
																		className="guests_done_apply_button"
																		type="button"
																		onClick={() => guestsFilterApply()}
																	>
																		Apply
																	</button>
																</div>
															</div>
														</div>
													</>
												)}
											</>
										)}
									</div>

									<div className="search-btn-container">
										<button
											type="button"
											className="search-btn"
											onClick={handleFindResorts}
										>
											{" "}
											Find Resorts
										</button>
									</div>
								</div>

								<div className="max-night-price-wrap">
									<span>
										<h2>Max Night Price :</h2>
										<p>&#8377; {filters.maxPrice.get()}</p>
									</span>
									<div className="slidecontainer">
										{/* <input type="range" value="8000" min="1000" max="30000" step="1000" className="slider" id="myRange" onChange={(e) => filters.maxPrice.set(parseInt(e.target.value))} /> */}
										<input
											type="range"
											min="1000"
											max="30000"
											step="1000"
											className="slider"
											id="myRange"
											value={filters.maxPrice.get()}
											onChange={(e) =>
												filters.maxPrice.set(parseInt(e.target.value))
											}
										/>
									</div>
								</div>

								{/* <div className="services-wrap">
									<Collapsible trigger="Amenities">
										<div className="services-list">
											<ul>
												<li>
													<input
														type="checkbox"
														id="kingBed"
														value="kingBed"
														onChange={handleAmenitiesName}
													/>
													<label htmlFor="kingBed">
														<p>King Beds</p>
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														id="drink"
														value="welcomeDrink"
														onChange={handleAmenitiesName}
													/>
													<label htmlFor="drink">
														<p>Welcome Drink</p>
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														id="bike"
														value="Bike Rental"
														onChange={handleAmenitiesName}
													/>
													<label htmlFor="bike">
														<p>Bike Rental</p>
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														id="privateBathroom"
														value="Bathroom"
														onChange={handleAmenitiesName}
													/>
													<label htmlFor="privateBathroom">
														<p>Private Bathroom</p>
													</label>
												</li>

												<li>
													<input
														type="checkbox"
														id="swimmingPool"
														value="Swimming Pool"
														onChange={handleAmenitiesName}
													/>
													<label htmlFor="swimmingPool">
														{" "}
														<p>Swimming Pool</p>{" "}
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														id="noSmoking"
														value="No Smoking"
														onChange={handleAmenitiesName}
													/>
													<label htmlFor="noSmoking">
														{" "}
														<p>No Smoking</p>{" "}
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														id="television"
														value="television"
														onChange={handleAmenitiesName}
													/>
													<label htmlFor="television">
														{" "}
														<p>Television</p>{" "}
													</label>
												</li>
											</ul>
										</div>
									</Collapsible>
								</div> */}
								<div className="services-wrap">
									<Collapsible trigger="Amenities">
										<div className="services-list">
											<ul>
												{amenities.map((amenity) => (
													<li key={amenity.id}>
														<input
															type="checkbox"
															id={amenity.id}
															value={amenity.value}
															onChange={handleAmenitiesName}
														/>
														<label htmlFor={amenity.id}>
															<p>{amenity.value}</p>
														</label>
													</li>
												))}
											</ul>
										</div>
									</Collapsible>
								</div>

								<div className="services-wrap">
									<Collapsible trigger="Travel Moods">
										<div className="services-list">
											<ul>
												<li>
													<input
														type="checkbox"
														id="chillout"
														value="Chillout"
														onChange={handleTrvlMood}
													/>
													<label htmlFor="chillout">
														<p>Chillout</p>
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														id="adventure"
														value="Adventure"
														onChange={handleTrvlMood}
													/>
													<label htmlFor="adventure">
														<p>Adventure</p>
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														id="luxury"
														value="Luxury"
														onChange={handleTrvlMood}
													/>
													<label htmlFor="luxury">
														<p>Luxury</p>
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														id="leisure"
														value="Leisure"
														onChange={handleTrvlMood}
													/>
													<label htmlFor="leisure">
														<p>Leisure</p>
													</label>
												</li>

												<li>
													<input
														type="checkbox"
														id="weekend"
														value="Weekend"
														onChange={handleTrvlMood}
													/>
													<label htmlFor="weekend">
														{" "}
														<p>Weekend </p>{" "}
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														id="budget"
														value="budget"
														onChange={handleTrvlMood}
													/>
													<label htmlFor="budget">
														{" "}
														<p>Budget</p>{" "}
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														id="safari"
														value="Safari"
														onChange={handleTrvlMood}
													/>
													<label htmlFor="safari">
														{" "}
														<p>Safari</p>{" "}
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														id="nature"
														value="Nature"
														onChange={handleTrvlMood}
													/>
													<label htmlFor="nature">
														{" "}
														<p>Nature</p>{" "}
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														id="solo"
														value="Solo"
														onChange={handleTrvlMood}
													/>
													<label htmlFor="solo">
														{" "}
														<p>Solo</p>{" "}
													</label>
												</li>
											</ul>
										</div>
									</Collapsible>
								</div>

								<div className="services-wrap">
									<Collapsible trigger="Cancellation">
										<div className="services-list cancellation-list">
											<ul>
												<li>
													<input
														type="checkbox"
														id="24hrs"
														value="Free cancellation upto 24 hrs"
														onChange={handleCancelTime}
													/>
													<label htmlFor="24hrs">
														<p>Free cancellation upto 24 hours</p>{" "}
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														id="48hrs"
														value="Free cancellation upto 48 hrs"
														onChange={handleCancelTime}
													/>
													<label htmlFor="48hrs">
														{" "}
														<p>Free cancellation upto 48 hours</p>{" "}
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														id="72hrs"
														value="Free cancellation upto 72 hrs"
														onChange={handleCancelTime}
													/>
													<label htmlFor="72hrs">
														<p>Free cancellation upto 72 hours</p>{" "}
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														id="4Days"
														value="4Days"
														onChange={handleCancelTime}
													/>
													<label htmlFor="4Days">
														<p>Free cancellation upto 4 days</p>{" "}
													</label>
												</li>
											</ul>
										</div>
									</Collapsible>
								</div>
								<div className="services-wrap">
									<Collapsible trigger="Star Rating">
										<div className="services-list star-list" ref={cardsRef}>
											<ul>
												<li>
													<input
														type="checkbox"
														value="1"
														id="oneStar"
														onChange={handleStarRating}
													/>
													<label htmlFor="oneStar">
														<p>
															<img
																src={toAbsoluteUrl("/media/img/fav.png")}
																alt="MyResorts.in"
															/>
														</p>
													</label>
												</li>

												<li>
													<input
														type="checkbox"
														value="2"
														id="twoStars"
														onChange={handleStarRating}
													/>
													<label htmlFor="twoStars">
														<p>
															<img
																src={toAbsoluteUrl("/media/img/fav.png")}
																alt="MyResorts.in"
															/>
															<img
																src={toAbsoluteUrl("/media/img/fav.png")}
																alt="MyResorts.in"
															/>
														</p>
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														value="3"
														id="threeStars"
														onChange={handleStarRating}
													/>
													<label htmlFor="threeStars">
														<p>
															<img
																src={toAbsoluteUrl("/media/img/fav.png")}
																alt="MyResorts.in"
															/>
															<img
																src={toAbsoluteUrl("/media/img/fav.png")}
																alt="MyResorts.in"
															/>
															<img
																src={toAbsoluteUrl("/media/img/fav.png")}
																alt="MyResorts.in"
															/>
														</p>
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														value="4"
														id="fourStars"
														onChange={handleStarRating}
													/>
													<label htmlFor="fourStars">
														<p>
															<img
																src={toAbsoluteUrl("/media/img/fav.png")}
																alt="MyResorts.in"
															/>
															<img
																src={toAbsoluteUrl("/media/img/fav.png")}
																alt="MyResorts.in"
															/>
															<img
																src={toAbsoluteUrl("/media/img/fav.png")}
																alt="MyResorts.in"
															/>
															<img
																src={toAbsoluteUrl("/media/img/fav.png")}
																alt="MyResorts.in"
															/>
														</p>
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														value="5"
														id="fiveStars"
														onChange={handleStarRating}
													/>
													<label htmlFor="fiveStars">
														<p>
															<img
																src={toAbsoluteUrl("/media/img/fav.png")}
																alt="MyResorts.in"
															/>
															<img
																src={toAbsoluteUrl("/media/img/fav.png")}
																alt="MyResorts.in"
															/>
															<img
																src={toAbsoluteUrl("/media/img/fav.png")}
																alt="MyResorts.in"
															/>
															<img
																src={toAbsoluteUrl("/media/img/fav.png")}
																alt="MyResorts.in"
															/>
															<img
																src={toAbsoluteUrl("/media/img/fav.png")}
																alt="MyResorts.in"
															/>
														</p>
													</label>
												</li>
											</ul>
										</div>
									</Collapsible>
								</div>

								{/* <a href="#" className="all-news-bt">
                 Filter
                </a> */}

								{/* <div className="latest-news-main" >
                  <h5>Latest News</h5>
                </div>
                <div className="latest-news-room" >
                  <div className="lat-news-box-list">
                    <img
                      src={toAbsoluteUrl("/media/img/hotel-img-1.png")}
                      alt="MyResorts.in"
                    />
                    <div className="lat-news-content">
                      <h2>29 October</h2>
                      <h3>Resort in Tadoba</h3>
                      <p>Robin Miles</p>
                    </div>
                  </div>

                  <div className="lat-news-box-list">
                    <img
                      src={toAbsoluteUrl("/media/img/hotel-img-1.png")}
                      alt="MyResorts.in"
                    />
                    <div className="lat-news-content">
                      <h2>29 October</h2>
                      <h3>Resort in Tadoba</h3>
                      <p>Robin Miles</p>
                    </div>
                  </div>

                  <div className="lat-news-box-list">
                    <img
                      src={toAbsoluteUrl("/media/img/hotel-img-1.png")}
                      alt="MyResorts.in"
                    />
                    <div className="lat-news-content">
                      <h2>29 October</h2>
                      <h3>Resort in Tadoba</h3>
                      <p>Robin Miles</p>
                    </div>
                  </div>

                  <a href="/" className="all-news-bt"  >
                    ALL NEWS
                  </a>
                </div> */}
							</div>
						</Col>

						{/* Working Area for Now Start's Here */}

						<Col lg="8" xs="12">
							{loader ? (
								<div
									className="spinner"
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "center",
										alignContent: "center",
										alignItems: "center",
										height: "150",
									}}
								>
									<Spinner animation="border" variant="success" />
								</div>
							) : (
								<div className="room-search-right-panel">
									{/* {searchResult !== undefined ? */}
									{searchResult.length < 1 ? (
										<h1
											style={{
												color: "#009933",
												display: showMessage ? "block" : "none",
											}}
										>
											No Resorts found at this moment, meanwhile take a look at
											this popular resorts..
										</h1>
									) : (
										searchResult.map((value, index) => {
											return (
												<>
													{coupleGroup
														? value.group_booking &&
														  value.group_booking.group_booking_allowed
															? value.property &&
															  value.property[0] &&
															  value.property[0].property_status ===
																	"Published" && (
																	<div
																		className="room-search-list-box"
																		key={index}
																	>
																		<div className="popular-tour-box ">
																			<div className="popular-tours-img">
																				<div className="pop-tour-img-big">
																					{value.catdetails !== undefined ? (
																						<>
																							<Link
																								to={{
																									pathname: "/roomdetails",
																									search: `catId=${value._id}&rooms=${datas.rooms}&adults=${datas.adults}&groupAdults=${datas.groupAdults}&childRange1=${datas.childRange1}&childRange2=${datas.childRange2}&childRange3=${datas.childRange3}&coupleGroupVal=${coupleGroup}&checkIn=${datas.checkInDate}&checkOut=${datas.checkOutDate}`,
																								}}
																							>
																								<img
																									// src={value.images[0] === undefined ? '/media/img/icons/imgPlaceholder.png' : `${Img_Path}${value.images[0]}`}
																									src={
																										value?.images[0] ===
																										undefined
																											? "/media/img/icons/defaultImg.png"
																											: `${Img_Path}${value.images[0]}`
																									}
																									alt={value?.catdetails?.name}
																								/>
																								<div className="star-box-rooms">
																									{value.property !== undefined
																										? value.property.map(
																												(
																													info: any,
																													index: any
																												) => {
																													return (
																														<>
																															<span key={index}>
																																<AiFillStar />{" "}
																																<span className="star-rating-rooms">
																																	{
																																		info
																																			?.property_basic_info
																																			?.property_star_rating
																																	}
																																</span>
																															</span>
																														</>
																													);
																												}
																										  )
																										: ""}
																								</div>
																							</Link>
																						</>
																					) : (
																						""
																					)}
																				</div>

																				{value.images ? (
																					<>
																						<div className="pop-tour-img-list">
																							<img
																								// src={value.images[1] === undefined ? '/media/img/icons/imgPlaceholder.png' : `${Img_Path}${value.images[1]}`}
																								src={
																									value.images[1] === undefined
																										? "/media/img/icons/defaultImg.png"
																										: `${Img_Path}${value.images[1]}`
																								}
																								alt={value?.catdetails?.name}
																							/>
																							<img
																								// src={value.images[2] === undefined ? '/media/img/icons/imgPlaceholder.png' : `${Img_Path}${value.images[2]}`}
																								src={
																									value.images[2] === undefined
																										? "/media/img/icons/defaultImg.png"
																										: `${Img_Path}${value.images[2]}`
																								}
																								alt={value?.catdetails?.name}
																							/>
																							<img
																								// src={value.images[3] === undefined ? '/media/img/icons/imgPlaceholder.png' : `${Img_Path}${value.images[3]}`}
																								src={
																									value.images[3] === undefined
																										? "/media/img/icons/defaultImg.png"
																										: `${Img_Path}${value.images[3]}`
																								}
																								alt={value?.catdetails?.name}
																							/>

																							{value.catdetails !==
																							undefined ? (
																								<>
																									<Link
																										to={{
																											pathname: "/roomdetails",
																											search: `catId=${value._id}&rooms=${datas.rooms}&adults=${datas.adults}&groupAdults=${datas.groupAdults}&childRange1=${datas.childRange1}&childRange2=${datas.childRange2}&childRange3=${datas.childRange3}&coupleGroupVal=${coupleGroup}&checkIn=${datas.checkInDate}&checkOut=${datas.checkOutDate}`,
																										}}
																									>
																										<p>View All</p>
																										<img
																											// src={value.images[4] === undefined ? '/media/img/icons/imgPlaceholder.png' : `${Img_Path}${value.images[4]}`}
																											src={
																												value.images[4] ===
																												undefined
																													? "/media/img/icons/defaultImg.png"
																													: `${Img_Path}${value.images[4]}`
																											}
																											alt={
																												value?.catdetails?.name
																											}
																										/>
																									</Link>
																								</>
																							) : (
																								""
																							)}
																						</div>
																					</>
																				) : (
																					""
																				)}
																			</div>
																			{/* LINE 78 ROOMS.SCSS */}
																			<div className="room-details-info-bottom-part">
																				<div className="popular-tour-info-new">
																					<div className="room-view-wrap">
																						<div className="room-view">
																							{value.catdetails !==
																							undefined ? (
																								<>
																									<Link
																										to={{
																											pathname: "/roomdetails",
																											search: `catId=${value._id}&rooms=${datas.rooms}&adults=${datas.adults}&groupAdults=${datas.groupAdults}&childRange1=${datas.childRange1}&childRange2=${datas.childRange2}&childRange3=${datas.childRange3}&coupleGroupVal=${coupleGroup}&checkIn=${datas.checkInDate}&checkOut=${datas.checkOutDate}`,
																										}}
																									>
																										<div className="resort-view-name-h2">
																											{value.catdetails.name}
																										</div>
																									</Link>
																								</>
																							) : (
																								""
																							)}

																							{value.property !== undefined
																								? value.property.map(
																										(info: any, index: any) => {
																											return (
																												<>
																													<span key={index}>
																														<Link
																															to={{
																																pathname:
																																	"/resortdetails",
																																search: `propertyId=${info._id}&rooms=${datas.rooms}&adults=${datas.adults}&groupAdults=${datas.groupAdults}&childRange1=${datas.childRange1}&childRange2=${datas.childRange2}&childRange3=${datas.childRange3}&coupleGroupVal=${coupleGroup}&checkIn=${datas.checkInDate}&checkOut=${datas.checkOutDate}`,
																															}}
																														>
																															<div className="resort-view-name-h3">
																																{
																																	info
																																		?.property_basic_info
																																		?.property_name
																																}
																															</div>
																														</Link>
																													</span>
																												</>
																											);
																										}
																								  )
																								: ""}

																							<div className="resort-view-name-h4">
																								{value.property !== undefined
																									? value.property.map(
																											(
																												info: any,
																												index: any
																											) => {
																												return (
																													<>
																														<span key={index}>
																															{
																																info
																																	.property_location
																																	?.property_nearest_gate
																															}
																															,&nbsp;
																															{
																																info
																																	.property_location
																																	?.property_address
																															}
																															,&nbsp;
																															{
																																info
																																	.property_location
																																	?.property_zip_code
																															}
																														</span>
																													</>
																												);
																											}
																									  )
																									: ""}
																							</div>
																						</div>
																						<div className="ratings-box rating-container">
																							{/* <div className="star-rating-container">
							   <a href="/" className="stars-box">
								 <StarRating rating={value.property_star_rating} color="#009933" />
							   </a>
							 </div> */}
																							{/* <div className="star-rating-container">
							   <AiFillStar style={{ transform: "translateY(3px)" }} />
							   {value.property !== undefined ? (
								 value.property.map((info: any) => {
								   return (
									 <>
									   <span style={{ margin: "0px 4px 0px 3px" }}>{info?.property_basic_info?.property_star_rating}</span>
									 </>
								   )
								 })
							   ) : ""}
							 </div> */}

																							<div className="free-cancel">
																								{value.property !== undefined
																									? value.property.map(
																											(
																												info: any,
																												index: any
																											) => {
																												return (
																													<span key={index}>
																														{" "}
																														{
																															info
																																?.property_policies
																																?.cancellation_policy
																														}{" "}
																													</span>
																												);
																											}
																									  )
																									: ""}
																							</div>
																						</div>
																					</div>

																					<div className="bottom-info-wrap">
																						<div className="bottom-info-wrap-left">
																							<div className="ratings rating-container">
																								{value.property !== undefined
																									? value.property.map(
																											(
																												info: any,
																												index: any
																											) => {
																												return (
																													<>
																														<span key={index}>
																															{
																																info
																																	?.property_basic_info
																																	?.property_star_rating
																															}{" "}
																															/ 5
																														</span>
																													</>
																												);
																											}
																									  )
																									: ""}

																								{/* <p>332 Ratings</p> */}
																							</div>
																							<div className="amenities">
																								<span
																									style={{
																										height: "33px",
																										overflow: "hidden",
																									}}
																								>
																									{value.property !== undefined
																										? value.property.map(
																												(info: any) => {
																													return info.property_amenities.basic_facilities
																														.slice(0, 9) // Limit to 10 amenities
																														.map(
																															(
																																data: any,
																																index: any
																															) => {
																																return (
																																	<small
																																		className="room-view-amenities-name"
																																		key={index}
																																	>
																																		{" "}
																																		{data}{" "}
																																	</small>
																																);
																															}
																														);
																												}
																										  )
																										: ""}
																								</span>

																								{/* <span> <FaCoffee /> Coffee</span> */}
																								{/* <span> <IoMdRestaurant /> Restaurant</span>
							  																		 <span> <GiCoffeePot /> Cafe</span> */}

																								{/* <a href="#">+ More</a> */}
																							</div>
																							{/* <h5>Pool villa seaview</h5> */}

																							<div className="couple-friendly">
																								{/* <span>
																												<img
																												src={toAbsoluteUrl(
																													"/media/img/icon-user-grey.png"
																												)}
																												alt="MyResorts.in"
																												/>
																												<p>4 GUESTS</p>
																											</span> */}

																								{/* <span>
																										<img
																										src={toAbsoluteUrl(
																											"/media/img/icon-user-grey.png"
																										)}
																										alt="MyResorts.in"
																										/>
																										<p>Couple Friendly</p>
																									</span> */}
																							</div>
																						</div>
																						<div className="bottom-info-wrap-right bottom-wrap-right-container">
																							<a href="#" className="off-bt">
																								Upto 30% Off
																							</a>
																							<div className="price-n-off">
																								{weekend ? (
																									value.weekendGroupBooking !==
																									undefined ? (
																										<>
																											<h2>
																												&#8377;{" "}
																												{
																													value
																														?.weekendGroupBooking
																														?.weekend_base_price
																												}
																											</h2>
																										</>
																									) : (
																										<h2>
																											&#8377;{" "}
																											{
																												value?.group_booking
																													?.base_price
																											}
																										</h2>
																									)
																								) : value.catdetails !==
																								  undefined ? (
																									<>
																										<h2>
																											&#8377;{" "}
																											{
																												value?.group_booking
																													?.base_price
																											}
																										</h2>
																									</>
																								) : (
																									""
																								)}

																								<span>
																									{/* <h3 style={{ textAlign: "right" }}>+₹123 TAXES & FEES</h3> */}
																									{/* <p> 3 per room / night</p> */}
																								</span>
																							</div>

																							{value.catdetails !==
																							undefined ? (
																								<>
																									<Link
																										to={{
																											pathname: "/roomdetails",
																											search: `catId=${value._id}&rooms=${datas.rooms}&adults=${datas.adults}&groupAdults=${datas.groupAdults}&childRange1=${datas.childRange1}&childRange2=${datas.childRange2}&childRange3=${datas.childRange3}&coupleGroupVal=${coupleGroup}&checkIn=${datas.checkInDate}&checkOut=${datas.checkOutDate}`,
																										}}
																									>
																										<div className="book-for-now">
																											Book Now
																										</div>
																									</Link>
																								</>
																							) : (
																								""
																							)}
																						</div>
																					</div>

																					{/* <div className="rate">
					 <h4>8.0 Superb</h4>
					 <h2>National Park 2 Days Tour</h2>
					 <h3>
					   <span>$70 /</span>Per Person{" "}
					 </h3>
				   </div>

				   <div className="total-days">
					 <p>3 Days</p>
					 <p>12+</p>
					 <p>Los Angeles</p>
				   </div> */}
																				</div>
																			</div>
																		</div>
																	</div>
															  )
															: null
														: value.property &&
														  value.property[0] &&
														  value.property[0].property_status ===
																"Published" && (
																<div
																	className="room-search-list-box"
																	key={index}
																>
																	<div className="popular-tour-box ">
																		<div className="popular-tours-img">
																			<div className="pop-tour-img-big">
																				{value.catdetails !== undefined ? (
																					<>
																						<Link
																							to={{
																								pathname: "/roomdetails",
																								search: `catId=${value._id}&rooms=${datas.rooms}&adults=${datas.adults}&groupAdults=${datas.groupAdults}&childRange1=${datas.childRange1}&childRange2=${datas.childRange2}&childRange3=${datas.childRange3}&coupleGroupVal=${coupleGroup}&checkIn=${datas.checkInDate}&checkOut=${datas.checkOutDate}`,
																							}}
																						>
																							<img
																								// src={value.images[0] === undefined ? '/media/img/icons/imgPlaceholder.png' : `${Img_Path}${value.images[0]}`}
																								src={
																									value?.images[0] === undefined
																										? "/media/img/icons/defaultImg.png"
																										: `${Img_Path}${value.images[0]}`
																								}
																								alt={value?.catdetails?.name}
																							/>
																							<div className="star-box-rooms">
																								{value.property !== undefined
																									? value.property.map(
																											(
																												info: any,
																												index: any
																											) => {
																												return (
																													<>
																														<span key={index}>
																															<AiFillStar />{" "}
																															<span className="star-rating-rooms">
																																{
																																	info
																																		?.property_basic_info
																																		?.property_star_rating
																																}
																															</span>
																														</span>
																													</>
																												);
																											}
																									  )
																									: ""}
																							</div>
																						</Link>
																					</>
																				) : (
																					""
																				)}
																			</div>

																			{value.images ? (
																				<>
																					<div className="pop-tour-img-list">
																						<img
																							// src={value.images[1] === undefined ? '/media/img/icons/imgPlaceholder.png' : `${Img_Path}${value.images[1]}`}
																							src={
																								value.images[1] === undefined
																									? "/media/img/icons/defaultImg.png"
																									: `${Img_Path}${value.images[1]}`
																							}
																							alt={value?.catdetails?.name}
																						/>
																						<img
																							// src={value.images[2] === undefined ? '/media/img/icons/imgPlaceholder.png' : `${Img_Path}${value.images[2]}`}
																							src={
																								value.images[2] === undefined
																									? "/media/img/icons/defaultImg.png"
																									: `${Img_Path}${value.images[2]}`
																							}
																							alt={value?.catdetails?.name}
																						/>
																						<img
																							// src={value.images[3] === undefined ? '/media/img/icons/imgPlaceholder.png' : `${Img_Path}${value.images[3]}`}
																							src={
																								value.images[3] === undefined
																									? "/media/img/icons/defaultImg.png"
																									: `${Img_Path}${value.images[3]}`
																							}
																							alt={value?.catdetails?.name}
																						/>

																						{value.catdetails !== undefined ? (
																							<>
																								<Link
																									to={{
																										pathname: "/roomdetails",
																										search: `catId=${value._id}&rooms=${datas.rooms}&adults=${datas.adults}&groupAdults=${datas.groupAdults}&childRange1=${datas.childRange1}&childRange2=${datas.childRange2}&childRange3=${datas.childRange3}&coupleGroupVal=${coupleGroup}&checkIn=${datas.checkInDate}&checkOut=${datas.checkOutDate}`,
																									}}
																								>
																									<p>View All</p>
																									<img
																										// src={value.images[4] === undefined ? '/media/img/icons/imgPlaceholder.png' : `${Img_Path}${value.images[4]}`}
																										src={
																											value.images[4] ===
																											undefined
																												? "/media/img/icons/defaultImg.png"
																												: `${Img_Path}${value.images[4]}`
																										}
																										alt={
																											value?.catdetails?.name
																										}
																									/>
																								</Link>
																							</>
																						) : (
																							""
																						)}
																					</div>
																				</>
																			) : (
																				""
																			)}
																		</div>
																		{/* LINE 78 ROOMS.SCSS */}
																		<div className="room-details-info-bottom-part">
																			<div className="popular-tour-info-new">
																				<div className="room-view-wrap">
																					<div className="room-view">
																						{value.catdetails !== undefined ? (
																							<>
																								<Link
																									to={{
																										pathname: "/roomdetails",
																										search: `catId=${value._id}&rooms=${datas.rooms}&adults=${datas.adults}&groupAdults=${datas.groupAdults}&childRange1=${datas.childRange1}&childRange2=${datas.childRange2}&childRange3=${datas.childRange3}&coupleGroupVal=${coupleGroup}&checkIn=${datas.checkInDate}&checkOut=${datas.checkOutDate}`,
																									}}
																								>
																									<div className="resort-view-name-h2">
																										{value.catdetails.name}
																									</div>
																								</Link>
																							</>
																						) : (
																							""
																						)}

																						{value.property !== undefined
																							? value.property.map(
																									(info: any, index: any) => {
																										return (
																											<>
																												<span key={index}>
																													<Link
																														to={{
																															pathname:
																																"/resortdetails",
																															search: `propertyId=${info._id}&rooms=${datas.rooms}&adults=${datas.adults}&groupAdults=${datas.groupAdults}&childRange1=${datas.childRange1}&childRange2=${datas.childRange2}&childRange3=${datas.childRange3}&coupleGroupVal=${coupleGroup}&checkIn=${datas.checkInDate}&checkOut=${datas.checkOutDate}`,
																														}}
																													>
																														<div className="resort-view-name-h3">
																															{
																																info
																																	?.property_basic_info
																																	?.property_name
																															}
																														</div>
																													</Link>
																												</span>
																											</>
																										);
																									}
																							  )
																							: ""}

																						<div className="resort-view-name-h4">
																							{value.property !== undefined
																								? value.property.map(
																										(info: any, index: any) => {
																											return (
																												<>
																													<span key={index}>
																														{
																															info
																																.property_location
																																?.property_nearest_gate
																														}
																														,&nbsp;
																														{
																															info
																																.property_location
																																?.property_address
																														}
																														,&nbsp;
																														{
																															info
																																.property_location
																																?.property_zip_code
																														}
																													</span>
																												</>
																											);
																										}
																								  )
																								: ""}
																						</div>
																					</div>
																					<div className="ratings-box rating-container">
																						{/* <div className="star-rating-container">
                                   <a href="/" className="stars-box">
                                     <StarRating rating={value.property_star_rating} color="#009933" />
                                   </a>
                                 </div> */}
																						{/* <div className="star-rating-container">
                                   <AiFillStar style={{ transform: "translateY(3px)" }} />
                                   {value.property !== undefined ? (
                                     value.property.map((info: any) => {
                                       return (
                                         <>
                                           <span style={{ margin: "0px 4px 0px 3px" }}>{info?.property_basic_info?.property_star_rating}</span>
                                         </>
                                       )
                                     })
                                   ) : ""}
                                 </div> */}

																						<div className="free-cancel">
																							{value.property !== undefined
																								? value.property.map(
																										(info: any, index: any) => {
																											return (
																												<span key={index}>
																													{" "}
																													{
																														info
																															?.property_policies
																															?.cancellation_policy
																													}{" "}
																												</span>
																											);
																										}
																								  )
																								: ""}
																						</div>
																					</div>
																				</div>

																				<div className="bottom-info-wrap">
																					<div className="bottom-info-wrap-left">
																						<div className="ratings rating-container">
																							{value.property !== undefined
																								? value.property.map(
																										(info: any, index: any) => {
																											return (
																												<>
																													<span key={index}>
																														{
																															info
																																?.property_basic_info
																																?.property_star_rating
																														}{" "}
																														/ 5
																													</span>
																												</>
																											);
																										}
																								  )
																								: ""}

																							{/* <p>332 Ratings</p> */}
																						</div>
																						<div className="amenities">
																							<span
																								style={{
																									height: "33px",
																									overflow: "hidden",
																								}}
																							>
																								{/* {value.property_address !== undefined ? (
                                       value.services.map((data: any, index: any) => {
                                         return <small className="room-view-amenities-name" key={index}> {data} </small>
                                       })
                                     ) : " "} */}

																								{/* {value.property !== undefined ? (
                                       value.property.map((info: any) => {
                                         return info.property_amenities.basic_facilities.map((data: any, index: any) => {
                                           return <small className="room-view-amenities-name" key={index}> {data} </small>
                                         })
                                       })
                                     ) : ""} */}

																								{value.property !== undefined
																									? value.property.map(
																											(info: any) => {
																												return info.property_amenities.basic_facilities
																													.slice(0, 9)
																													.map(
																														(
																															data: any,
																															index: any
																														) => {
																															return (
																																<small
																																	className="room-view-amenities-name"
																																	key={index}
																																>
																																	{" "}
																																	{data}{" "}
																																</small>
																															);
																														}
																													);
																											}
																									  )
																									: ""}
																							</span>

																							{/* <span> <FaCoffee /> Coffee</span> */}
																							{/* <span> <IoMdRestaurant /> Restaurant</span>
                                   <span> <GiCoffeePot /> Cafe</span> */}

																							{/* <a href="#">+ More</a> */}
																						</div>
																						{/* <h5>Pool villa seaview</h5> */}

																						<div className="couple-friendly">
																							<span>
																								<img
																									style={{ height: "19px" }}
																									src={toAbsoluteUrl(
																										//"/media/img/icon-user-grey.png"
																										"/media/svg/misc/utensils-solid.svg"
																									)}
																									alt="MyResorts"
																								/>
																								{/* <p>4 GUESTS</p> */}

																								<p>
																									{value.catdetails
																										?.meal_option == 1 && (
																										<span
																											style={{
																												marginLeft: "3px",
																											}}
																										>
																											Breakfast and Dinner
																										</span>
																									)}
																									{value.catdetails
																										?.meal_option == 2 && (
																										<span
																											style={{
																												marginLeft: "3px",
																											}}
																										>
																											Breakfast Only
																										</span>
																									)}
																									{value.catdetails
																										?.meal_option == 3 && (
																										<span
																											style={{
																												marginLeft: "3px",
																											}}
																										>
																											All meals Included&nbsp;
																											<span
																												style={{
																													fontSize: "7px",
																													color: "gray",
																													fontStyle: "italic",
																												}}
																											>
																												(American Plan)
																											</span>
																										</span>
																									)}
																									{value.catdetails
																										?.meal_option == "" && (
																										<span
																											style={{
																												marginLeft: "3px",
																											}}
																										>
																											None
																										</span>
																									)}
																								</p>
																							</span>

																							{/* <span>
																									<img
																									src={toAbsoluteUrl(
																										"/media/img/icon-user-grey.png"
																									)}
																									alt="MyResorts.in"
																									/>
																									<p>Couple Friendly</p>
																								</span> */}
																						</div>
																					</div>
																					<div className="bottom-info-wrap-right bottom-wrap-right-container">
																						<a href="#" className="off-bt">
																							Upto 30% Off
																						</a>
																						<div className="price-n-off">
																							{weekend ? (
																								value.weekend_pricing !==
																								undefined ? (
																									<>
																										<h2>
																											&#8377;{" "}
																											{
																												value?.weekend_pricing
																													?.base_weekend_price
																											}
																										</h2>
																										{/* <span
																											style={{
																												color: "#D3D3D3",
																												fontSize: "10",
																											}}
																										>
																											per room / night
																										</span> */}
																									</>
																								) : (
																									<h2>
																										&#8377;{" "}
																										{
																											value?.catPrice
																												?.base_price
																										}
																										{/* <span
																											style={{
																												color: "#D3D3D3",
																												fontSize: "10",
																											}}
																										>
																											per room / night
																										</span> */}
																									</h2>
																								)
																							) : value.catdetails !==
																							  undefined ? (
																								<>
																									<h2>
																										&#8377;{" "}
																										{
																											value?.catPrice
																												?.base_price
																										}
																									</h2>
																									{/* <span
																										style={{
																											color: "#D3D3D3",
																											fontSize: "10",
																										}}
																									>
																										per room / night
																									</span> */}
																								</>
																							) : (
																								""
																							)}

																							<h4>
																								{" "}
																								₹
																								{1540 +
																									Number(
																										value?.catPrice?.base_price
																									)}
																							</h4>

																							<span>
																								<h3
																									style={{ textAlign: "right" }}
																								>
																									+taxes & fees
																								</h3>
																								<p> per room / night</p>
																							</span>
																						</div>

																						{value.catdetails !== undefined ? (
																							<>
																								<Link
																									to={{
																										pathname: "/roomdetails",
																										search: `catId=${value._id}&rooms=${datas.rooms}&adults=${datas.adults}&groupAdults=${datas.groupAdults}&childRange1=${datas.childRange1}&childRange2=${datas.childRange2}&childRange3=${datas.childRange3}&coupleGroupVal=${coupleGroup}&checkIn=${datas.checkInDate}&checkOut=${datas.checkOutDate}`,
																									}}
																								>
																									<div className="book-for-now">
																										Book Now
																									</div>
																								</Link>
																							</>
																						) : (
																							""
																						)}
																					</div>
																				</div>

																				{/* <div className="rate">
                         <h4>8.0 Superb</h4>
                         <h2>National Park 2 Days Tour</h2>
                         <h3>
                           <span>$70 /</span>Per Person{" "}
                         </h3>
                       </div>

                       <div className="total-days">
                         <p>3 Days</p>
                         <p>12+</p>
                         <p>Los Angeles</p>
                       </div> */}
																			</div>
																		</div>
																	</div>
																</div>
														  )}
												</>
											);
										})
									)}
								</div>
							)}
						</Col>
					</Row>
				</div>

				<UserFooter />
			</div>
		</>
	);
}
