import "./ResortDetails.scss";
import {
	Carousel as Multicarousel,
	Col,
	Container,
	Form,
	Nav,
	Navbar,
	NavDropdown,
	Row,
	Tab,
	Tabs,
} from "react-bootstrap-v5";
import Collapsible from "react-collapsible";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import ImageGallery from "react-image-gallery";
import UserHeader from "../components/UserHeader";
import UserFooter from "../components/UserFooter";
import { AiFillCaretDown } from "react-icons/ai";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StarRating from "../components/StarRating";
import { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import filtersState from "../userglobalstate/filterstate";
import { none, useState as useGlobalState } from "@hookstate/core";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import SelectStars from "../components/SelectStars/SelectStars";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
	SampleNextArrow,
	SamplePrevArrow,
} from "../components/CustomArrows/CustomArrows";

const API_URL = process.env.REACT_APP_API_URL || "";

// const Img_Path = `https://myresort.s3.ap-south-1.amazonaws.com/`;
// const Img_Path = "https://myresortsbucket.s3.amazonaws.com/";
const Img_Path = "https://myresortsbucket-prod1.s3.ap-south-1.amazonaws.com/";

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
		slidesToSlide: 4, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		slidesToSlide: 2, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
};

// ==== Date Range and Guest room filter Starts here ========

// Date Range Picker Click Outside Close Start's here
function useClickOutside(callback: () => void) {
	const ref = useRef<HTMLDivElement>(null);
	const handleClick = (event: MouseEvent) => {
		if (ref.current && !ref.current.contains(event.target as Node)) {
			callback();
		}
	};
	useEffect(() => {
		document.addEventListener("click", handleClick);
		return () => {
			document.removeEventListener("click", handleClick);
		};
	});
	return ref;
}
// Date Range Picker Click Outside Close End's here

// ==== Date Range and Guest room filter ends here ===========

const signUpSchema = Yup.object({
	comment: Yup.string()
		.max(400, "Your Review is Too Long")
		.required("Your Message is Required !"),
});

export default function Resortdetails() {
	const [resortData, setResortData] = useState<any>("");
	const [resortInfo, setResortInfo] = useState<any>("");

	const [imgData, setImgData] = useState<any>("");
	const [imgData1, setImgData1] = useState<any>("");
	const [imgData2, setImgData2] = useState<any>("");
	const [imgData3, setImgData3] = useState<any>("");
	const [imgData4, setImgData4] = useState<any>("");
	const [imgData5, setImgData5] = useState<any>("");
	const [activities, setActivities] = useState([]);
	const [formatCheckIn, setFormatCheckIn] = useState<any>("");
	const [formatCheckOut, setFormatCheckOut] = useState<any>("");

	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const propertyId = params.get("propertyId") || "63333fe310e91088cb400e75";
	const roomsNum = params.get("rooms") ? params.get("rooms") : 1;
	const adultNum = params.get("adults") ? params.get("adults") : 2;
	const GroupNum = params.get("groupAdults") ? params.get("groupAdults") : 4;
	const childRange1Num = params.get("childRange1")
		? params.get("childRange1")
		: 0;
	const childRange2Num = params.get("childRange2")
		? params.get("childRange2")
		: 0;
	const childRange3Num = params.get("childRange3")
		? params.get("childRange3")
		: 0;
	const coupleGroupString = params.get("coupleGroupVal")
		? params.get("coupleGroupVal")
		: false; //cgsbc
	const coupleGroupVal =
		coupleGroupString === "true" ? coupleGroupString === "true" : false; //cgsbc
	// const userId = localStorage.getItem("UserId") || '';

	const checkInDate: string | number | null = params.get("checkIn");
	const checkOutDate: string | number | null = params.get("checkOut");
	let FirstDate: Date;
	if (typeof checkInDate === "string") {
		FirstDate = new Date(checkInDate);
	} else {
		FirstDate = new Date();
	}
	let SecondDate: Date;
	if (typeof checkOutDate === "string") {
		SecondDate = new Date(checkOutDate);
	} else {
		const today = new Date();
		const nextDay = new Date(today);
		nextDay.setDate(nextDay.getDate() + 1);
		SecondDate = nextDay;
	}

	// =========== date range picker and rooms and guests filter start's here ==========
	const [coupleGroup, setCoupleGroup] = useState(coupleGroupVal); //cgsbc
	const filters = useGlobalState(filtersState.filters);
	const [defaultPlaceOptions, setdefaultPlaceOptions] = useState(
		filters.defaultPlaceOptions.get()
	);
	const [resultPlaceOptions, setResultPlaceOptions] = useState<any[]>([]);
	const [guestsFilterShow, setGuestsFilterShow] = useState(false);

	// ========== date range picker code start's here ==========

	const [checkedInDate, setCheckedInDate] = useState<any>("");
	const [checkedOutDate, setCheckedOutDate] = useState<any>("");

	const [dateRange, setDateRange] = useState<any[]>([
		{
			startDate: new Date(FirstDate),
			endDate: new Date(SecondDate),
			key: "selection",
		},
	]);

	const [openCalendar, setOpenCalendar] = useState(false);
	const calenderRef = useClickOutside(() => {
		return setOpenCalendar(false), setGuestsFilterShow(false);
	});

	const InDate = dateRange.map((data) => {
		return data.startDate;
	});
	const OutDate = dateRange.map((data) => {
		return data.endDate;
	});

	const dateString = InDate.toString();
	const checkInDay = dateString.slice(8, 10);
	const checkInMonth = dateString.slice(4, 7);
	const checkInYear = dateString.slice(11, 15);

	const DateStringEnd = OutDate.toString();
	const checkOutDay = DateStringEnd.slice(8, 10);
	const checkOutMonth = DateStringEnd.slice(4, 7);
	const checkOutYear = DateStringEnd.slice(11, 15);

	const CheckInDate = checkInDay + " " + checkInMonth + " " + checkInYear;
	const CheckOutDate = checkOutDay + " " + checkOutMonth + " " + checkOutYear;

	// ========== date range picker code end's here ==========

	const [similarRoom, setSimilarRoom] = useState<any>("");

	const [rating, setRating] = useState<number>(3);
	const [reviewMsg, setReviewMsg] = useState<any>("");
	const [reviewsData, setReviewsData] = useState<any>("");

	const [userName, setUserName] = useState<any>("");
	const [userEmail, setUserEmail] = useState<any>("");
	const [userNum, setUserNum] = useState<any>("");
	const [userId, setUserId] = useState<any>("");

	const [guestsFilter, setGuestFilter] = useState<any>({
		rooms: roomsNum,
		adults: adultNum,
		childRange1: childRange1Num,
		childRange2: childRange2Num,
		childRange3: childRange3Num,
		groupAdults: GroupNum,
	});

	const totalGuestsNum = coupleGroup
		? Number(guestsFilter.groupAdults) + Number(guestsFilter.childRange3)
		: Number(guestsFilter.adults) +
		  Number(guestsFilter.childRange1) +
		  Number(guestsFilter.childRange2);

	// ================ date range picker ==========================
	const handleDateChange = (item: any) => {
		setDateRange([item.selection]);
		const startDate: Date = item.selection.startDate as Date;
		const endDate: Date = item.selection.endDate as Date;

		let tempDate = startDate
			.toLocaleString("en-US", {
				timeZone: process.env.LOCAL_TZ,
				hour12: false,
			})
			.split(",")[0]
			.split("/");

		let startDateStr =
			tempDate[2] +
			"-" +
			(parseInt(tempDate[0]) < 10 ? "0" : "") +
			tempDate[0] +
			"-" +
			(parseInt(tempDate[1]) < 10 ? "0" : "") +
			tempDate[1];

		tempDate = endDate
			.toLocaleString("en-US", {
				timeZone: process.env.LOCAL_TZ,
				hour12: false,
			})
			.split(",")[0]
			.split("/");

		let endDateStr: any =
			tempDate[2] +
			"-" +
			(parseInt(tempDate[0]) < 10 ? "0" : "") +
			tempDate[0] +
			"-" +
			(parseInt(tempDate[1]) < 10 ? "0" : "") +
			tempDate[1];

		if (startDateStr === endDateStr) {
			let FirstDate = new Date(startDateStr);

			let nextDay = new Date(startDateStr);
			nextDay.setDate(nextDay.getDate() + 1);
			let SecondDate = nextDay;

			const dateString = FirstDate.toString();
			const checkInDay = dateString.slice(8, 10);
			const checkInMonth = dateString.slice(4, 7);
			const checkInYear = dateString.slice(11, 15);

			const DateStringEnd = SecondDate.toString();
			const checkOutDay = DateStringEnd.slice(8, 10);
			const checkOutMonth = DateStringEnd.slice(4, 7);
			const checkOutYear = DateStringEnd.slice(11, 15);

			let CheckInDate = checkInDay + " " + checkInMonth + " " + checkInYear;
			let CheckOutDate = checkOutDay + " " + checkOutMonth + " " + checkOutYear;

			endDateStr = CheckOutDate;

			filters.checkIn.set(startDateStr);
			filters.checkOut.set(endDateStr);

			setCheckedInDate(CheckInDate);
			setCheckedOutDate(CheckOutDate);
		} else {
			let FirstDate = new Date(startDateStr);
			let SecondDate = new Date(endDateStr);

			const dateString = FirstDate.toString();
			const checkInDay = dateString.slice(8, 10);
			const checkInMonth = dateString.slice(4, 7);
			const checkInYear = dateString.slice(11, 15);

			const DateStringEnd = SecondDate.toString();
			const checkOutDay = DateStringEnd.slice(8, 10);
			const checkOutMonth = DateStringEnd.slice(4, 7);
			const checkOutYear = DateStringEnd.slice(11, 15);

			let CheckInDate = checkInDay + " " + checkInMonth + " " + checkInYear;
			let CheckOutDate = checkOutDay + " " + checkOutMonth + " " + checkOutYear;

			filters.checkIn.set(startDateStr);
			filters.checkOut.set(endDateStr);

			setCheckedInDate(CheckInDate);
			setCheckedOutDate(CheckOutDate);
			startDateStr !== endDateStr && setOpenCalendar(false);
		}
	};
	// ========== date range picker ================================

	const handleGuestsNumberChange = (type: string, arith: string) => {
		let obj = { ...guestsFilter };
		if (arith === "add") obj[type]++;
		if (
			arith === "sub" &&
			(type === "adults" || type === "rooms") &&
			obj[type] !== 1
		)
			obj[type]--;
		if (
			arith === "sub" &&
			(type === "childRange1" ||
				type === "childRange2" ||
				type === "childRange3") &&
			obj[type] !== 0
		)
			obj[type]--;
		if (arith === "sub" && type === "groupAdults" && obj[type] !== 4)
			obj[type]--;

		setGuestFilter(obj);
	};

	const guestsFilterApply = () => {
		/* let obj = { ...filters }
    obj['guests'] = guestsFilter */
		filters.guests.set(guestsFilter);
		setGuestsFilterShow(false);
	};
	//============== date range picker and rooms and guests filter end's here=============

	// console.log(propertyId, "PROPERTY ID")

	const handleResortData = () => {
		axios
			.get(`${API_URL}/v1/user/property_detail?property_id=${propertyId}`)
			.then((res) => {
				const ResortApiData = res.data.data;

				if (ResortApiData) {
					ResortApiData.map((values: any) => {
						setResortInfo(values);
						return "";
					});
				}
				// console.log(
				// 	"resort",
				// 	res.data.data[0]?.property_basic_info?.property_name
				// );

				setResortData(res.data.data);
				handleSimilarRooms(
					res.data.data.map(
						(values: any) => values?.property_location?.property_city
					),
					res.data.data[0]?.property_basic_info?.property_name
				);

				setImgData(res.data.data.map((values: any) => values.images));

				setImgData1(res.data.data.map((values: any) => values.images[0]));
				setImgData2(res.data.data.map((values: any) => values.images[1]));
				setImgData3(res.data.data.map((values: any) => values.images[2]));
				setImgData4(res.data.data.map((values: any) => values.images[3]));
				setImgData5(res.data.data.map((values: any) => values.images[4]));

				// console.log(JSON.stringify(res.data.data.map((values: any) => values.images[0])), "fifth image")

				const CheckinTime = res.data.data.map(
					(value: any) => value.property_policies.checkin_time
				);
				const dateIn = new Date(`2023-03-06T${CheckinTime}:00`);

				const formattedTimeIn = dateIn.toLocaleString("en-US", {
					hour: "numeric",
					minute: "numeric",
					hour12: true,
				});
				setFormatCheckIn(formattedTimeIn);

				const CheckoutTime = res.data.data.map(
					(value: any) => value.property_policies.checkout_time
				);
				const dateOut = new Date(`2023-03-06T${CheckoutTime}:00`);

				const formattedTimeOut = dateOut.toLocaleString("en-US", {
					hour: "numeric",
					minute: "numeric",
					hour12: true,
				});
				setFormatCheckOut(formattedTimeOut);
			});
	};

	// ===========   Similar Rooms Code Start's Here =====================
	function handleBookNowClick(id: any) {
		const url = `/roomdetails?catId=${id}`;
		window.location.href = url;
	}

	const handleSimilarRooms = (place: any, cat: any) => {
		// console.log("cat");

		// axios.get(`${API_URL}/v1/user/similar_room?place=${similarRoomCity}`)
		axios.get(`${API_URL}/v1/user/similar_room?place=${place}`).then((res) => {
			// console.log("similar Rooms", res.data.data);
			// console.log(cat);
			let room: any = [];
			if (res?.data?.data?.length > 0) {
				res?.data?.data.map((value: any) => {
					if (
						value?.property[0]?.property_basic_info?.property_name === cat &&
						value?.property[0]?.property_status === "Published"
					) {
						room.push(value);
					}
				});
			}
			// console.log(room.length);
			if (room.length === 1) {
				setSimilarRoom(room);
				setSimilarRoom(room);
			} else {
				setSimilarRoom(room);
			}
		});
	};
	const handleToDoList = () => {
		axios
			.get(`${API_URL}/v1/user/things-to-do?activity_property_id=${propertyId}`)
			.then((res) => {
				// console.log("setActivities Rooms ------1", res.data.data);

				setActivities(res.data.data);
			});

		// axios
		// 	.get(`${API_URL}/v1/vendor/activity`, {
		// 		params: { activity_property_id: propertyId },
		// 	})
		// 	.then((res) => {
		// 		setActivities(res.data.data);
		// 	});
	};

	// ===========   Similar Rooms Code End's Here =====================

	// ================== guest review form start's here =========================

	useEffect(() => {
		let UserFirstName = localStorage.getItem("UserFirstname") || "";
		let UserLastName = localStorage.getItem("UserLastname") || "";
		let UserName = `${UserFirstName} ${UserLastName}`;
		let UserEmail = localStorage.getItem("UserEmail");
		let UserNum = localStorage.getItem("UserNum");
		const userId = localStorage.getItem("UserId") || "";

		setUserName(UserName);
		setUserEmail(UserEmail);
		setUserNum(UserNum);
		setUserId(userId);
	});

	const handleReviewMsg = (message: any) => {
		setReviewMsg(message);
		setTimeout(() => {
			setReviewMsg("");
		}, 3000);
	};

	const handleGetReviews = () => {
		axios
			.get(`${API_URL}/v1/user/getreview?resort_id=${propertyId}`)
			.then((res) => {
				setReviewsData(res.data.data);
				// console.log(res.data.data, "review Data")
			});
	};

	const initialValues = {
		comment: "",
		rating: 3,
		username: "",
		mobile: "",
		email: "",
		resort_id: propertyId,
	};

	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			initialValues: initialValues,
			validationSchema: signUpSchema,
			onSubmit: (values, action) => {
				let userData = {
					review: values.comment,
					rating: rating,
					username: userName,
					mobile: userNum,
					email: userEmail,
					resort_id: propertyId,
					user_id: userId,
				};

				if (userEmail && userName) {
					axios.post(`${API_URL}/v1/user/postreview`, userData).then((res) => {
						handleReviewMsg(res.data.message);
						handleGetReviews();
					});
				} else {
					handleReviewMsg("Please Login in to Write Your Review");
				}
				action.resetForm(); //to clear the user info after submitting the form
			},
		});

	// ================== guest review form end's here =========================

	useEffect(() => {
		handleResortData();
		handleGetReviews();
		// handleSimilarRooms("all");
		handleToDoList();

		if (window.innerWidth < 550) {
			window.scrollTo({ top: 140, behavior: "smooth" });
		} else if (window.innerWidth > 700 && window.innerWidth < 1200) {
			window.scrollTo({ top: 280, behavior: "smooth" });
		} else if (window.innerWidth >= 1200) {
			window.scrollTo({ top: 410, behavior: "smooth" });
		}
	}, []);

	//  couple group switch button code
	const handleChecked = () => {
		setCoupleGroup(!coupleGroup);
	};
	//  couple group switch button code

	const settings = {
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		// autoplay: true,
		// autoplaySpeed: 3000,
	};

	const defualtImg = "/media/img/icons/defaultImg.png";

	const images = [
		{
			original: imgData1 === "" ? defualtImg : `${Img_Path}${imgData1}`,
			thumbnail: imgData1 === "" ? defualtImg : `${Img_Path}${imgData1}`,
		},
		{
			original: imgData2 === "" ? defualtImg : `${Img_Path}${imgData2}`,
			thumbnail: imgData2 === "" ? defualtImg : `${Img_Path}${imgData2}`,
		},
		{
			original: imgData3 === "" ? defualtImg : `${Img_Path}${imgData3}`,
			thumbnail: imgData3 === "" ? defualtImg : `${Img_Path}${imgData3}`,
		},
		{
			original: imgData4 === "" ? defualtImg : `${Img_Path}${imgData4}`,
			thumbnail: imgData4 === "" ? defualtImg : `${Img_Path}${imgData4}`,
		},
		{
			original: imgData5 === "" ? defualtImg : `${Img_Path}${imgData5}`,
			thumbnail: imgData5 === "" ? defualtImg : `${Img_Path}${imgData5}`,
		},
	];

	// const hasImages = imgData;
	// console.log(hasImages, "y2", imgData.length)

	const Imgsettings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: similarRoom.length > 3 ? 4 : similarRoom.length,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: true,
		variableWidth: true,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
				breakpoint: 500, // Adjust the breakpoint as needed
				settings: {
					slidesToShow: 1, // Number of slides to show on mobile
					arrows: false,
					autoplay: false,
					dots: true,
				},
			},
		],
	};

	const handleRatingChange = (value: number) => {
		setRating(value);
	};
	useEffect(() => {
		console.log("");
	}, [filters.maxPrice.get()]);

	return (
		<>
			{/* <UserHeader /> */}

			<div className="room-detail-container resort-details-page fix-top-nav-padd">
				<div className="room-details-banner">
					<img src={toAbsoluteUrl("/media/img/banner-2.jpg")} alt="MyResorts.in" />
					<div className="room-details-banner-info">
						<h1>Resorts</h1>
						<h5 onClick={handleResortData}>
							Discover luxurious resorts worldwide on our user-friendly website.
							Find details on location, dining options, and nearby attractions,
							alongside stunning photos and virtual tours.
						</h5>
					</div>
					<div className="overlay-hy">
						<img
							src={toAbsoluteUrl("/media/img/tree-shape.png")}
							alt="MyResorts.in"
						/>
					</div>
				</div>

				<div className="container">
					<Row>
						<Col lg="12">
							<div className="breadcum-wrapper">
								<div className="breadcum">
									<ul>
										<li>
											<a href="home">Home</a>
										</li>
										<li>
											<a href="rooms">Resorts / Rooms</a>
										</li>
										<li>
											<a href="resortdetails">Resorts</a>
										</li>
									</ul>
								</div>
							</div>
						</Col>
					</Row>
				</div>

				<div className="container">
					<div className="rd-room-with-view">
						{/* {resortData !== "" ? ( */}
						<Row>
							<Col lg="12">
								<Row>
									<Col lg="8">
										{resortData !== ""
											? resortData.map((value: any) => {
													return (
														<>
															<div className="room-with-view-container">
																<div className="rd-room-with-view-title">
																	<h2>
																		{value.property_basic_info.property_name}
																	</h2>
																	<div className="resort-star-rating">
																		<StarRating
																			rating={
																				value.property_basic_info
																					.property_star_rating
																			}
																			color="#009933"
																		/>
																	</div>
																</div>
																<div className="rd-room-address">
																	<div className="rd-room-address-1">
																		{/* return console.log(value.property_location.property_address, "property address") */}
																		{resortData !== ""
																			? resortData.map((value: any) => {
																					return (
																						<>
																							<span>
																								<img
																									src={toAbsoluteUrl(
																										"/media/img/location-pin.svg"
																									)}
																									alt="MyResorts.in"
																								/>
																								{
																									value.property_location
																										.property_address
																								}
																								&nbsp;
																								{
																									value.property_location
																										.property_state
																								}
																								,&nbsp;
																								{
																									value.property_location
																										.property_nearest_gate
																								}
																							</span>
																							<p className="rd-room-address-para">
																								{
																									value.property_location
																										.property_city
																								}{" "}
																								{
																									value.property_location
																										.property_zip_code
																								}
																							</p>
																						</>
																					);
																			  })
																			: ""}
																	</div>

																	<div className="rd-room-address-2">
																		<div className="ame-wrap">
																			<div className="ame">
																				{resortInfo !== "" ? (
																					resortInfo?.property_amenities
																						?.travel_moods.length !== 0 ? (
																						resortInfo?.property_amenities?.travel_moods.map(
																							(value: any, index: any) => {
																								let convertedTextName = value
																									.split(" ")
																									.map(
																										(word: any) =>
																											word
																												.charAt(0)
																												.toUpperCase() +
																											word.slice(1)
																									)
																									.join(" ");
																								return (
																									<span
																										className="ame-text"
																										key={index}
																									>
																										{convertedTextName}
																									</span>
																								);
																							}
																						)
																					) : (
																						<>
																							<span className="ame-text">
																								Newly renovated
																							</span>
																							<span>Free Wi-Fi .</span>
																						</>
																					)
																				) : (
																					""
																				)}
																			</div>
																		</div>

																		<div className="by-rat">
																			<span>
																				{isNaN(reviewsData?.averageRating) ? "No Rating" : `${reviewsData?.averageRating} / 5`}
																			</span>
																			<p>
																				{isNaN(reviewsData?.totalCount) ? "No Rating" : `${reviewsData?.totalCount} Ratings`}
																			</p>
																		</div>
																	</div>

																	<div className="rd-image-gallery-wrap">
																		<ImageGallery
																			showFullscreenButton={false}
																			items={images}
																		/>
																	</div>
																</div>
															</div>
														</>
													);
											  })
											: ""}
									</Col>
									<Col lg="4">
										<div className="rd-right-pannel">
											<div className="switch-button" onClick={handleChecked}>
												<input
													className="switch-button-checkbox"
													type="checkbox"
													checked={coupleGroup}
												></input>
												<label className="switch-button-label">
													<span className="switch-button-label-span">
														COUPLE
													</span>
												</label>
											</div>

											<div className="rd-cjeck-in-out-part">
												{/* <a href="#" className="off-bt">
													60% Off
												</a>
												<div className="price-n-off">
													<h2>₹472</h2>
													<span>
													<h3>+₹123 TAXES &amp; FEES</h3>
													<p>per room / night</p>
													</span>
												</div> */}

												{/*=============== guest room container code starts here =========== */}
												<div
													className="date-room-filters-container"
													ref={calenderRef}
												>
													<div
														className="resort-bookingDate-container"
														onClick={() => setOpenCalendar(!openCalendar)}
													>
														<h3 className="resort-booking-date">
															{checkedInDate === ""
																? `${CheckInDate} - ${CheckOutDate}`
																: `${checkedInDate} - ${checkedOutDate}`}
														</h3>
														<AiFillCaretDown style={{ color: "#fff" }} />
													</div>

													{openCalendar && (
														<div className="resort-dateRange-box">
															<DateRange
																editableDateInputs={true}
																onChange={(item) => handleDateChange(item)} //setDateRange([item.selection])}
																moveRangeOnFirstSelection={false}
																ranges={dateRange}
																minDate={new Date()}
																months={2}
																direction="horizontal"
																className="calendarElement"
																rangeColors={["#009933"]}
															/>
														</div>
													)}

													<div
														className="resort-guests-filter"
														onClick={() =>
															setGuestsFilterShow(!guestsFilterShow)
														}
													>
														<h3 className="resort-guests-filter-name">
															{guestsFilter.rooms} Room, {totalGuestsNum} Guests{" "}
														</h3>
														<AiFillCaretDown style={{ color: "#fff" }} />
													</div>

													{guestsFilterShow && (
														<>
															{coupleGroup ? (
																<>
																	<div
																		className="guest-filter-show group-filter-show resort-guest-filter-show"
																		onBlur={() => setGuestsFilterShow(false)}
																	>
																		<div
																			className="container-fluid user-select-none"
																			style={{
																				cursor: "pointer",
																				margin: "auto",
																			}}
																		>
																			<div className="row p-4 guest-filter-inputs">
																				<div className="col">
																					<label>Adults</label>
																					<div
																						className="row"
																						style={{
																							boxShadow: "0px 0px 5px green",
																							borderRadius: "5px",
																							marginTop: "5px",
																							width: "110px",
																						}}
																					>
																						<div
																							className="col-3 border text-center"
																							onClick={() =>
																								handleGuestsNumberChange(
																									"groupAdults",
																									"sub"
																								)
																							}
																						>
																							<span className="fw-bold fs-3 user-select-none">
																								-
																							</span>
																						</div>
																						<div className="col-6 border text-center">
																							<span className="fw-bold fs-3 user-select-none">
																								{guestsFilter["groupAdults"]}
																							</span>
																						</div>
																						<div
																							className="col-3 border text-center"
																							onClick={() =>
																								handleGuestsNumberChange(
																									"groupAdults",
																									"add"
																								)
																							}
																						>
																							<span className="fw-bold fs-3 user-select-none">
																								+
																							</span>
																						</div>
																					</div>
																				</div>
																				<div className="col">
																					Child (0-12 yrs)
																					<div
																						className="row"
																						style={{
																							boxShadow: "0px 0px 5px green",
																							borderRadius: "5px",
																							marginTop: "5px",
																							width: "110px",
																						}}
																					>
																						<div
																							className="col-3 border text-center"
																							onClick={() =>
																								handleGuestsNumberChange(
																									"childRange3",
																									"sub"
																								)
																							}
																						>
																							<span className="fw-bold fs-3 user-select-none">
																								-
																							</span>
																						</div>
																						<div className="col-6 border text-center">
																							<span className="fw-bold fs-3 user-select-none">
																								{guestsFilter["childRange3"]}
																							</span>
																						</div>
																						<div
																							className="col-3 border text-center"
																							onClick={() =>
																								handleGuestsNumberChange(
																									"childRange3",
																									"add"
																								)
																							}
																						>
																							<span className="fw-bold fs-3 user-select-none">
																								+
																							</span>
																						</div>
																					</div>
																				</div>
																			</div>
																			<div className="d-flex justify-content-around mt-5">
																				<button
																					className="guests_done_apply_button"
																					type="button"
																					onClick={() => guestsFilterApply()}
																				>
																					Apply
																				</button>
																			</div>
																		</div>
																	</div>
																</>
															) : (
																<>
																	<div
																		className="guest-filter-show resort-guest-filter-show"
																		onBlur={() => setGuestsFilterShow(false)}
																	>
																		<div
																			className="container-fluid user-select-none"
																			style={{
																				cursor: "pointer",
																				margin: "auto",
																			}}
																		>
																			<div className="row p-4 guest-filter-inputs">
																				<div className="col">
																					<label>Rooms</label>
																					<div
																						className="row"
																						style={{
																							boxShadow: "0px 0px 5px green",
																							borderRadius: "5px",
																							marginTop: "5px",
																							width: "110px",
																						}}
																					>
																						<div
																							className="col-3 border text-center fw-bold fs-3 user-select-none"
																							onClick={() =>
																								handleGuestsNumberChange(
																									"rooms",
																									"sub"
																								)
																							}
																						>
																							-
																						</div>
																						<div className="col-6 border text-center fw-bold fs-3 user-select-none">
																							{guestsFilter["rooms"]}
																						</div>
																						<div
																							className="col-3 border text-center fw-bold fs-3 user-select-none"
																							onClick={() =>
																								handleGuestsNumberChange(
																									"rooms",
																									"add"
																								)
																							}
																						>
																							+
																						</div>
																					</div>
																				</div>
																				<div className="col">
																					<label>Adults</label>
																					<div
																						className="row"
																						style={{
																							boxShadow: "0px 0px 5px green",
																							borderRadius: "5px",
																							marginTop: "5px",
																							width: "110px",
																						}}
																					>
																						<div
																							className="col-3 border text-center"
																							onClick={() =>
																								handleGuestsNumberChange(
																									"adults",
																									"sub"
																								)
																							}
																						>
																							<span className="fw-bold fs-3 user-select-none">
																								-
																							</span>
																						</div>
																						<div className="col-6 border text-center">
																							<span className="fw-bold fs-3 user-select-none">
																								{guestsFilter["adults"]}
																							</span>
																						</div>
																						<div
																							className="col-3 border text-center"
																							onClick={() =>
																								handleGuestsNumberChange(
																									"adults",
																									"add"
																								)
																							}
																						>
																							<span className="fw-bold fs-3 user-select-none">
																								+
																							</span>
																						</div>
																					</div>
																				</div>
																			</div>
																			<div className="row mt-2 p-4 guest-filter-inputs">
																				<div className="col">
																					Child (0-6 yrs)
																					<div
																						className="row"
																						style={{
																							boxShadow: "0px 0px 5px green",
																							borderRadius: "5px",
																							marginTop: "5px",
																							width: "110px",
																						}}
																					>
																						<div
																							className="col-3 border text-center"
																							onClick={() =>
																								handleGuestsNumberChange(
																									"childRange1",
																									"sub"
																								)
																							}
																						>
																							<span className="fw-bold fs-3 user-select-none">
																								-
																							</span>
																						</div>
																						<div className="col-6 border text-center">
																							<span className="fw-bold fs-3 user-select-none">
																								{guestsFilter["childRange1"]}
																							</span>
																						</div>
																						<div
																							className="col-3 border text-center"
																							onClick={() =>
																								handleGuestsNumberChange(
																									"childRange1",
																									"add"
																								)
																							}
																						>
																							<span className="fw-bold fs-3 user-select-none">
																								+
																							</span>
																						</div>
																					</div>
																				</div>
																				<div className="col">
																					Child (6-12 yrs)
																					<div
																						className="row"
																						style={{
																							boxShadow: "0px 0px 5px green",
																							borderRadius: "5px",
																							marginTop: "5px",
																							width: "110px",
																						}}
																					>
																						<div
																							className="col-3 border text-center"
																							onClick={() =>
																								handleGuestsNumberChange(
																									"childRange2",
																									"sub"
																								)
																							}
																						>
																							<span className="fw-bold fs-3 user-select-none">
																								-
																							</span>
																						</div>
																						<div className="col-6 border text-center">
																							<span className="fw-bold fs-3 user-select-none">
																								{guestsFilter["childRange2"]}
																							</span>
																						</div>
																						<div
																							className="col-3 border text-center"
																							onClick={() =>
																								handleGuestsNumberChange(
																									"childRange2",
																									"add"
																								)
																							}
																						>
																							<span className="fw-bold fs-3 user-select-none">
																								+
																							</span>
																						</div>
																					</div>
																				</div>
																			</div>
																			<div className="d-flex justify-content-around mt-5">
																				<button
																					className="guests_done_apply_button"
																					type="button"
																					onClick={() => guestsFilterApply()}
																				>
																					Apply
																				</button>
																			</div>
																		</div>
																	</div>
																</>
															)}
														</>
													)}
												</div>
												{/* ========= guest room container code end here ====== */}
											</div>
											{/* <button className="search-bt">
                        <img
                          src={toAbsoluteUrl("/media/img/search-white.svg")}
                          alt="MyResorts.in"
                        />
                        Search
                      </button> */}

											<div className="map-container">
												<iframe
													src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241317.11610063197!2d72.74109777692279!3d19.082197837935304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c6306644edc1%3A0x5da4ed8f8d648c69!2sMumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1646998301966!5m2!1sen!2sin"
													width="100%"
													height="150"
													style={{ border: "0" }}
													loading="lazy"
												></iframe>
											</div>

											<div className="map-info">
												<h4>Show on Map</h4>
												<span>
													{" "}
													<img
														src={toAbsoluteUrl("/media/img/location-pin.svg")}
														alt="MyResorts.in"
													/>
													Better Place for stay
												</span>
												<p>
													Explore The World with Our Interactive Maps, Uncover
													Hidden Gems, Navigate With Ease, And Embark On Virtual
													Adventures.
												</p>
											</div>
										</div>
									</Col>
								</Row>
							</Col>

							<Col lg="12">
								<div className="room-details-amenities">
									<Row>
										<Col lg="12">
											<h2>Amenities</h2>
										</Col>
										<Col lg="12">
											<div className="rd-amenities-list">
												{resortInfo !== "" ? (
													resortInfo?.property_amenities?.basic_facilities
														.length !== 0 ? (
														resortInfo?.property_amenities?.basic_facilities.map(
															(value: any, index: any) => {
																let convertedTextName = value
																	.split(" ")
																	.map(
																		(word: any) =>
																			word.charAt(0).toUpperCase() +
																			word.slice(1)
																	)
																	.join(" ");
																return (
																	<div
																		className="room-details-amenities-box"
																		key={index}
																	>
																		<h3>{convertedTextName}</h3>
																	</div>
																);
															}
														)
													) : (
														<>
															<div className="room-details-amenities-box">
																<h3>Machaan</h3>
															</div>
															<div className="room-details-amenities-box">
																<h3>Bonfire</h3>
															</div>
															<div className="room-details-amenities-box">
																<h3>Outdoor Dining</h3>
															</div>
															<div className="room-details-amenities-box">
																<h3>Stargazing</h3>
															</div>
															<div className="room-details-amenities-box">
																<h3>Night View</h3>
															</div>
															<div className="room-details-amenities-box">
																<h3>Balcony </h3>
															</div>
														</>
													)
												) : (
													""
												)}

												{/* <div className="room-details-amenities-box">
                          <img
                            src={toAbsoluteUrl("/media/img/am-1.svg")}
                            alt="MyResorts.in"
                          />
                          <h3>Freash Air</h3>
                        </div>

                        <div className="room-details-amenities-box">
                          <img
                            src={toAbsoluteUrl("/media/img/am-2.svg")}
                            alt="MyResorts.in"
                          />
                          <h3>Bathrobes</h3>
                        </div>
                        <div className="room-details-amenities-box">
                          <img
                            src={toAbsoluteUrl("/media/img/am-3.svg")}
                            alt="MyResorts.in"
                          />
                          <h3>Wifi</h3>
                        </div>
                        <div className="room-details-amenities-box">
                          <img
                            src={toAbsoluteUrl("/media/img/am-4.svg")}
                            alt="MyResorts.in"
                          />
                          <h3>Wake-Up-Call</h3>
                        </div>

                        <div className="room-details-amenities-box">
                          <img
                            src={toAbsoluteUrl("/media/img/am-5.svg")}
                            alt="MyResorts.in"
                          />
                          <h3>Air Conditioning</h3>
                        </div>

                        <div className="room-details-amenities-box">
                          <img
                            src={toAbsoluteUrl("/media/img/am-6.svg")}
                            alt="MyResorts.in"
                          />
                          <h3>24/7 service</h3>
                        </div>

                        <div className="room-details-amenities-box">
                          <img
                            src={toAbsoluteUrl("/media/img/am-7.svg")}
                            alt="MyResorts.in"
                          />
                          <h3>Dry Cleaning</h3>
                        </div>

                        <div className="room-details-amenities-box">
                          <img
                            src={toAbsoluteUrl("/media/img/am-8.svg")}
                            alt="MyResorts.in"
                          />
                          <h3>Healthy Food</h3>
                        </div> */}
											</div>
										</Col>
									</Row>
								</div>
							</Col>

							<Col lg="12">
								<div className="description">
									<h2>Description</h2>

									{resortData !== ""
										? resortData.map((value: any) => {
												return (
													<>
														<p>
															{value.property_basic_info.property_description}
														</p>
													</>
												);
										  })
										: ""}

									{/* <a href="#">Read More</a> */}
								</div>
							</Col>
						</Row>

						{/* ) : ""} */}
					</div>
				</div>

				<div className="container">
					<Row>
						<Col lg="12">
							<div className="select-room-titile">
								<h3>Select your room</h3>
							</div>
						</Col>

						{similarRoom !== "" ? (
							<Slider {...Imgsettings}>
								{similarRoom !== ""
									? similarRoom.map((value: any, index: number) => {
											let DefaultImg =
												window.innerWidth < 550
													? "/media/img/icons/resortdImg.png"
													: "/media/img/icons/SimilardefaultImg.png";
											return (
												<div key={index}>
													<div className="select-room-box">
														<div className="select-room-box-img">
															<img
																className="similar-room-img"
																src={
																	value?.images[0] === undefined
																		? DefaultImg
																		: `${Img_Path}${value?.images[0]}`
																}
																alt="MyResorts.in"
															/>
														</div>
														<div className="select-room-info">
															<a
																href="#!"
																onClick={() => handleBookNowClick(value._id)}
															>
																<h2>{value.category_basic_info.name}</h2>
															</a>
															<div className="amenities select-room-amenities">
																{value.category_amenities.basic_facilities.map(
																	(data: any, index: any) => {
																		return <span key={index}>{data}</span>;
																	}
																)}
															</div>

															{/* <a className="off-bt">
																Upto 30% Off
															</a> */}
															<div className="price-n-off">
																<h2>
																	₹{value.category_base_pricing.base_price}
																</h2>
																<div className="dummy-price">
																	{" "}
																	₹
																	{1540 +
																		Number(
																			
																			value.category_base_pricing
																			.base_price
																		)
																	}
																</div>
																<span>
																	<h3 style={{ textAlign: "right" }}>+taxes & fees</h3>
																	<p> per room / night</p>
																</span>
															</div>

															<button
																className="book-for-now"
																onClick={() => handleBookNowClick(value._id)}
															>
																Book Now
															</button>
														</div>
													</div>
												</div>
											);
									  })
									: ""}
							</Slider>
						) : (
							""
						)}

						{/* <Col lg="3" md="6">
              <div className="select-room-box">
                <div className="select-room-box-img">
                  <img
                    src={toAbsoluteUrl("/media/img/room-2.png")}
                    alt="MyResorts.in"
                  />
                </div>
                <div className="select-room-info">
                  <h2>Deluxe Room</h2>
                  <div className="amenities">
                    <span>Sanitizers</span>
                    <span>Air Conditioning</span>
                    <a href="#">+ More</a>
                  </div>
                  <div className="room-facilities">
                    <img
                      src={toAbsoluteUrl("/media/img/select-rrom-icon-1.svg")}
                      alt="MyResorts.in" />
                    <img
                      src={toAbsoluteUrl("/media/img/select-rrom-icon-2.svg")}
                      alt="MyResorts.in"
                    />
                    <img
                      src={toAbsoluteUrl("/media/img/select-rrom-icon-3.svg")}
                      alt="MyResorts.in"
                    />
                    <img
                      src={toAbsoluteUrl("/media/img/select-rrom-icon-4.svg")}
                      alt="MyResorts.in"
                    />

                    <img
                      src={toAbsoluteUrl("/media/img/select-rrom-icon-5.svg")}
                      alt="MyResorts.in"
                    />
                    <img
                      src={toAbsoluteUrl("/media/img/select-rrom-icon-6.svg")}
                      alt="MyResorts.in"
                    />
                    <img
                      src={toAbsoluteUrl("/media/img/select-rrom-icon-7.svg")}
                      alt="MyResorts.in"
                    />
                    <img
                      src={toAbsoluteUrl("/media/img/select-rrom-icon-8.svg")}
                      alt="MyResorts.in"
                    />
                  </div>
                  <a href="#" className="off-bt">
                    60% Off
                  </a>
                  <div className="price-n-off">
                    <h2>₹472</h2>
                    <span>
                      <h3>+₹123 TAXES &amp; FEES</h3>
                      <p>per room / night</p>
                    </span>
                  </div>
                  <a href="roomdetails" className="book-for-now">
                    Book Now
                  </a>
                </div>
              </div>
            </Col> */}
					</Row>
				</div>

				<div className="container">
					<Row>
						<Col lg="12">
							<div className="reviews-wrap">
								<Tabs
									defaultActiveKey="reviews"
									id="uncontrolled-tab-example"
									className="mb-3"
								>
									<Tab eventKey="todo" title="Things to do">
										<div className="things-to-do-container">
											<div className="things-carousel-container">
												<Slider {...settings}>
													{activities && activities.length > 0
														? activities.map((key: any, index: number) => {
																return (
																	key?.activity_status === "Active" && (
																		<div
																			className="carousel-item-container"
																			key={index}
																		>
																			<div className="carousel-item-box">
																				<h4 className="carousel-item-name">
																					{key?.activity_title}
																				</h4>
																				<div className="things-carousel-img-box">
																					<img
																						className="things-carousel-img"
																						src={
																							Img_Path +
																							key?.activity_image_file[0]
																						}
																						alt="archery-img"
																					/>
																				</div>
																				<p>{key?.activity_description}</p>
																				{/* <img
																					className="things-carousel-rectangle"
																					src="media/img/resortdetails/rectangle.png"
																					alt="rectangle-img"
																				/> */}
																			</div>
																		</div>
																	)
																);
														  })
														: null}
													{/* <div className="carousel-item-container">
														<div className="carousel-item-box">
															<h4 className="carousel-item-name">
																Archery At Our Resort{" "}
															</h4>
															<div className="things-carousel-img-box">
																<img
																	className="things-carousel-img"
																	src="media/img/resortdetails/archery.png"
																	alt="archery-img"
																/>
																<p>
																	Our luxurious resort offers a serene escape
																	from the hustle and bustle of daily life.
																	Enjoy stunning ocean views from your private
																	balcony, relax in our infinity pool, and
																	indulge in world-class dining.
																</p>
															</div>
															<img
																className="things-carousel-rectangle"
																src="media/img/resortdetails/rectangle.png"
																alt="rectangle-img"
															/>
														</div>
														<div className="carousel-item-box">
															<h4 className="carousel-item-name">
																Come and Enjoy our Furnished Clubhouse
															</h4>
															<div className="things-carousel-img-box">
																<img
																	className="things-carousel-img"
																	src="media/img/resortdetails/hotelsresort.png"
																	alt="archery-img"
																/>
																<p>
																	Our luxurious resort offers a serene escape
																	from the hustle and bustle of daily life.
																	Enjoy stunning ocean views from your private
																	balcony, relax in our infinity pool, and
																	indulge in world-class dining.
																</p>
															</div>
															<img
																className="things-carousel-rectangle"
																src="media/img/resortdetails/rectangle.png"
																alt="rectangle-img"
															/>
														</div>
														<div className="carousel-item-box">
															<h4 className="carousel-item-name">
																Birdwatching at Mahuaa Vann Resort
															</h4>
															<div className="things-carousel-img-box">
																<img
																	className="things-carousel-img"
																	src="media/img/resortdetails/things3.png"
																	alt="archery-img"
																/>
																<p>
																	Our luxurious resort offers a serene escape
																	from the hustle and bustle of daily life.
																	Enjoy stunning ocean views from your private
																	balcony, relax in our infinity pool, and
																	indulge in world-class dining.
																</p>
															</div>
															<img
																className="things-carousel-rectangle"
																src="media/img/resortdetails/rectangle.png"
																alt="rectangle-img"
															/>
														</div>
														<div className="carousel-item-box">
															<h4 className="carousel-item-name">
																Best Adventure Camp in Entire Pench
															</h4>
															<div className="things-carousel-img-box">
																<img
																	className="things-carousel-img"
																	src="media/img/resortdetails/things4.png"
																	alt="archery-img"
																/>
																<p>
																	Our luxurious resort offers a serene escape
																	from the hustle and bustle of daily life.
																	Enjoy stunning ocean views from your private
																	balcony, relax in our infinity pool, and
																	indulge in world-class dining.
																</p>
															</div>
															<img
																className="things-carousel-rectangle"
																src="media/img/resortdetails/rectangle.png"
																alt="rectangle-img"
															/>
														</div>
													</div>

													<div className="carousel-item-container">
														<div className="carousel-item-box">
															<h4 className="carousel-item-name">
																Archery At Our Resort{" "}
															</h4>
															<div className="things-carousel-img-box">
																<img
																	className="things-carousel-img"
																	src="media/img/resortdetails/archery.png"
																	alt="archery-img"
																/>
																<p>
																	Our luxurious resort offers a serene escape
																	from the hustle and bustle of daily life.
																	Enjoy stunning ocean views from your private
																	balcony, relax in our infinity pool, and
																	indulge in world-class dining.
																</p>
															</div>
															<img
																className="things-carousel-rectangle"
																src="media/img/resortdetails/rectangle.png"
																alt="rectangle-img"
															/>
														</div>
														<div className="carousel-item-box">
															<h4 className="carousel-item-name">
																Come and Enjoy our Furnished Clubhouse
															</h4>
															<div className="things-carousel-img-box">
																<img
																	className="things-carousel-img"
																	src="media/img/resortdetails/hotelsresort.png"
																	alt="archery-img"
																/>
																<p>
																	Our luxurious resort offers a serene escape
																	from the hustle and bustle of daily life.
																	Enjoy stunning ocean views from your private
																	balcony, relax in our infinity pool, and
																	indulge in world-class dining.
																</p>
															</div>
															<img
																className="things-carousel-rectangle"
																src="media/img/resortdetails/rectangle.png"
																alt="rectangle-img"
															/>
														</div>
														<div className="carousel-item-box">
															<h4 className="carousel-item-name">
																Birdwatching at Mahuaa Vann Resort
															</h4>
															<div className="things-carousel-img-box">
																<img
																	className="things-carousel-img"
																	src="media/img/resortdetails/things3.png"
																	alt="archery-img"
																/>
																<p>
																	Our luxurious resort offers a serene escape
																	from the hustle and bustle of daily life.
																	Enjoy stunning ocean views from your private
																	balcony, relax in our infinity pool, and
																	indulge in world-class dining.
																</p>
															</div>
															<img
																className="things-carousel-rectangle"
																src="media/img/resortdetails/rectangle.png"
																alt="rectangle-img"
															/>
														</div>
														<div className="carousel-item-box">
															<h4 className="carousel-item-name">
																Best Adventure Camp in Entire Pench
															</h4>
															<div className="things-carousel-img-box">
																<img
																	className="things-carousel-img"
																	src="media/img/resortdetails/things4.png"
																	alt="archery-img"
																/>
																<p>
																	Our luxurious resort offers a serene escape
																	from the hustle and bustle of daily life.
																	Enjoy stunning ocean views from your private
																	balcony, relax in our infinity pool, and
																	indulge in world-class dining.
																</p>
															</div>
															<img
																className="things-carousel-rectangle"
																src="media/img/resortdetails/rectangle.png"
																alt="rectangle-img"
															/>
														</div>
													</div>

													<div className="carousel-item-container">
														<div className="carousel-item-box">
															<h4 className="carousel-item-name">
																Archery At Our Resort{" "}
															</h4>
															<div className="things-carousel-img-box">
																<img
																	className="things-carousel-img"
																	src="media/img/resortdetails/archery.png"
																	alt="archery-img"
																/>
																<p>
																	Our luxurious resort offers a serene escape
																	from the hustle and bustle of daily life.
																	Enjoy stunning ocean views from your private
																	balcony, relax in our infinity pool, and
																	indulge in world-class dining.
																</p>
															</div>
															<img
																className="things-carousel-rectangle"
																src="media/img/resortdetails/rectangle.png"
																alt="rectangle-img"
															/>
														</div>
														<div className="carousel-item-box">
															<h4 className="carousel-item-name">
																Come and Enjoy our Furnished Clubhouse
															</h4>
															<div className="things-carousel-img-box">
																<img
																	className="things-carousel-img"
																	src="media/img/resortdetails/hotelsresort.png"
																	alt="archery-img"
																/>
																<p>
																	Our luxurious resort offers a serene escape
																	from the hustle and bustle of daily life.
																	Enjoy stunning ocean views from your private
																	balcony, relax in our infinity pool, and
																	indulge in world-class dining.
																</p>
															</div>
															<img
																className="things-carousel-rectangle"
																src="media/img/resortdetails/rectangle.png"
																alt="rectangle-img"
															/>
														</div>
														<div className="carousel-item-box">
															<h4 className="carousel-item-name">
																Birdwatching at Mahuaa Vann Resort
															</h4>
															<div className="things-carousel-img-box">
																<img
																	className="things-carousel-img"
																	src="media/img/resortdetails/things3.png"
																	alt="archery-img"
																/>
																<p>
																	Our luxurious resort offers a serene escape
																	from the hustle and bustle of daily life.
																	Enjoy stunning ocean views from your private
																	balcony, relax in our infinity pool, and
																	indulge in world-class dining.
																</p>
															</div>
															<img
																className="things-carousel-rectangle"
																src="media/img/resortdetails/rectangle.png"
																alt="rectangle-img"
															/>
														</div>
														<div className="carousel-item-box">
															<h4 className="carousel-item-name">
																Best Adventure Camp in Entire Pench
															</h4>
															<div className="things-carousel-img-box">
																<img
																	className="things-carousel-img"
																	src="media/img/resortdetails/things4.png"
																	alt="archery-img"
																/>
																<p>
																	Our luxurious resort offers a serene escape
																	from the hustle and bustle of daily life.
																	Enjoy stunning ocean views from your private
																	balcony, relax in our infinity pool, and
																	indulge in world-class dining.
																</p>
															</div>
															<img
																className="things-carousel-rectangle"
																src="media/img/resortdetails/rectangle.png"
																alt="rectangle-img"
															/>
														</div>
													</div> */}
												</Slider>
											</div>
											<div className="things-img">
												<img
													className="things-todo-img"
													src="media/img/resortdetails/thingstodo.png"
													alt="things-todo-img"
												/>
											</div>
										</div>
									</Tab>

									<Tab eventKey="essentials" title="Nearby Essentials">
										<div className="nearby-container">
											<div className="nearby-info-container">
												{resortData !== ""
													? resortData.map((values: any) => {
															return (
																<>
																	<div className="nearby-left-box">
																		<div className="nearby-heading-container">
																			<img
																				className="nearby-arrow"
																				src="/media/img/resortdetails/nearbyarrow.png"
																				alt="neaby-img"
																			/>
																			<h3 className="nearby-heading">
																				{" "}
																				{`About ${values.property_basic_info.property_name}`}
																			</h3>
																			{/* <h3 className="nearby-heading">About Resort Name </h3> */}
																		</div>
																		<div className="nearby-text-box">
																			{/* <h4 className="nearby-sub-heading">Room with View Resort</h4> */}
																			<h4
																				className="nearby-sub-heading"
																				style={{ lineHeight: "25px" }}
																			>
																				<img
																					style={{
																						width: "10px",
																						marginRight: "5px",
																					}}
																					src={toAbsoluteUrl(
																						"/media/img/location-pin.svg"
																					)}
																					alt="MyResorts.in"
																				/>
																				{`
                                  ${values?.property_location?.property_nearest_gate},
                                  ${values?.property_location?.property_address},
                                  ${values?.property_location?.property_locality},
                                  ${values?.property_location?.property_city},
                                  ${values?.property_location?.property_state},
                                  ${values?.property_location?.property_country},
                                  ${values?.property_location?.property_zip_code}.
                                  `}
																			</h4>
																			{/* <h4 className="nearby-sub-heading">{`${values?.property_policies?.cancellation_policy}`}</h4> */}
																		</div>
																		{/* <div className="nearby-checkin-box">
                                  <h5 className="nearby-checkin-heading"> </h5>
                                  <h5 className="nearby-checkin-subheading"> Check-in from 11:00AM</h5>
                                  <h5 className="nearby-checkin-subheading"> Check-out from 11:00PM</h5>
                                </div> */}
																	</div>

																	<div className="nearby-left-box">
																		<div className="nearby-heading-container">
																			<img
																				className="nearby-arrow"
																				src="/media/img/resortdetails/nearbyarrow.png"
																				alt="neaby-img"
																			/>
																			<h3 className="nearby-heading">
																				Resort Policies{" "}
																			</h3>
																		</div>
																		<div className="nearby-text-box">
																			<h4 className="nearby-sub-heading">
																				{`${values?.property_policies?.cancellation_policy}`}
																			</h4>
																		</div>
																	</div>

																	<div className="nearby-left-box">
																		<div className="nearby-heading-container">
																			<img
																				className="nearby-arrow"
																				src="/media/img/resortdetails/nearbyarrow.png"
																				alt="neaby-img"
																			/>
																			<h3 className="nearby-heading">
																				{" "}
																				Check-in/Check-out
																			</h3>
																		</div>
																		<div className="nearby-text-box">
																			<h4 className="nearby-sub-heading">
																				{" "}
																				Check-In from {formatCheckIn}
																			</h4>
																			<h4 className="nearby-sub-heading">
																				{" "}
																				Check-Out from {formatCheckOut}
																			</h4>
																		</div>
																	</div>

																	{/* <div className="nearby-left-box">
																		<div className="nearby-heading-container">
																			<img
																				className="nearby-arrow"
																				src="/media/img/resortdetails/nearbyarrow.png"
																				alt="neaby-img"
																			/>
																			<h3 className="nearby-heading">
																				Contact Info
																			</h3>
																		</div>
																		<div className="nearby-text-box">
																			<h4 className="nearby-sub-heading">{`${values?.property_contact_details?.phone_no}`}</h4>
																			<h4 className="nearby-sub-heading">{`${values?.property_contact_details?.email}`}</h4>
																		</div>
																	</div> */}
																</>
															);
													  })
													: ""}
											</div>
											<div className="nearby-info-img-box">
												<img
													className="nearby-img"
													src="/media/img/resortdetails/nearby.png"
													alt="neaby-img"
												/>
											</div>
										</div>
									</Tab>

									<Tab eventKey="reviews" title="Guest Reviews">
										<div className="room-details-tab-container">
											{reviewsData !== "" ? (
												<>
													<div className="rd-ratings-wrap">
														<div className="rd-rating-box">
															{reviewsData.averageRating === "NaN" ? (
																<h5>No Rating</h5>
															) : (
																<h5>
																	{reviewsData?.averageRating > 4 ? (
																		<h5>Amazing</h5>
																	) : reviewsData?.averageRating > 3 ? (
																		<h5>Great</h5>
																	) : reviewsData?.averageRating > 2 ? (
																		<h5>Good</h5>
																	) : reviewsData?.averageRating > 1 ? (
																		<h5>Decent</h5>
																	) : (
																		<h5>Poor</h5>
																	)}
																</h5>
															)}

															<p>{reviewsData?.totalCount || 0} Ratings</p>
														</div>
														<div className="rd-ratings-bar">
															<div className="rd-rat-bar-box">
																<div className="rd-rat-box-left">
																	<span>
																		<p>5</p>
																		<img
																			src={toAbsoluteUrl(
																				"/media/img/star-g.png"
																			)}
																			alt="MyResorts.in"
																		/>
																	</span>

																	<div
																		className="rd-rating-line-bar"
																		style={{
																			width: `${
																				reviewsData
																					?.percentageDistribution[5] || 0
																			}%`,
																		}}
																	></div>
																</div>
																<h3>
																	{reviewsData?.percentageDistribution[5] || 0}%
																</h3>
															</div>

															<div className="rd-rat-bar-box">
																<div className="rd-rat-box-left">
																	<span>
																		<p>4</p>
																		<img
																			src={toAbsoluteUrl(
																				"/media/img/star-g.png"
																			)}
																			alt="MyResorts.in"
																		/>
																	</span>

																	<div
																		className="rd-rating-line-bar"
																		style={{
																			width: `${
																				reviewsData
																					?.percentageDistribution[4] || 0
																			}%`,
																		}}
																	></div>
																</div>
																<h3>
																	{reviewsData?.percentageDistribution[4] || 0}%
																</h3>
															</div>

															<div className="rd-rat-bar-box">
																<div className="rd-rat-box-left">
																	<span>
																		<p>3</p>
																		<img
																			src={toAbsoluteUrl(
																				"/media/img/star-g.png"
																			)}
																			alt="MyResorts.in"
																		/>
																	</span>

																	<div
																		className="rd-rating-line-bar"
																		style={{
																			width: `${
																				reviewsData
																					?.percentageDistribution[3] || 0
																			}%`,
																		}}
																	></div>
																</div>
																<h3>
																	{reviewsData?.percentageDistribution[3] || 0}%
																</h3>
															</div>

															<div className="rd-rat-bar-box">
																<div className="rd-rat-box-left">
																	<span>
																		<p>2</p>
																		<img
																			src={toAbsoluteUrl(
																				"/media/img/star-g.png"
																			)}
																			alt="MyResorts.in"
																		/>
																	</span>

																	<div
																		className="rd-rating-line-bar"
																		style={{
																			width: `${
																				reviewsData
																					?.percentageDistribution[2] || 0
																			}%`,
																		}}
																	></div>
																</div>
																<h3>
																	{reviewsData?.percentageDistribution[2] || 0}%
																</h3>
															</div>
															<div className="rd-rat-bar-box">
																<div className="rd-rat-box-left">
																	<span>
																		<p>1</p>
																		<img
																			src={toAbsoluteUrl(
																				"/media/img/star-g.png"
																			)}
																			alt="MyResorts.in"
																		/>
																	</span>

																	<div
																		className="rd-rating-line-bar"
																		style={{
																			width: `${
																				reviewsData
																					?.percentageDistribution[1] || 0
																			}%`,
																		}}
																	></div>
																</div>
																<h3>
																	{reviewsData?.percentageDistribution[1] || 0}%
																</h3>
															</div>
														</div>
													</div>
												</>
											) : (
												""
											)}

											<form onSubmit={handleSubmit}>
												<div className="write-review">
													<textarea
														rows={3}
														name="comment"
														id="comment"
														placeholder="Write Your Review"
														value={values.comment}
														onChange={handleChange}
														onBlur={handleBlur}
													/>
													{errors.comment && touched.comment ? (
														<span style={{ color: "red" }}>
															{errors.comment}{" "}
														</span>
													) : null}
													{/* <StarRating rating="5" color="#009933" /> */}
													{/* <SelectStars/> */}
													<div className="rating-submit-container">
														<div className="select-star-content">
															<SelectStars onChange={handleRatingChange} />
														</div>
														<div className="select-post-btn">
															<button type="submit" className="book-for-now">
																Post
															</button>
															{/* <a href="/" className="book-for-now">
                              Post {rating}
                            </a> */}
														</div>
													</div>
													<div
														style={{
															color: "red",
															padding: "10px 0px 10px 0px",
														}}
													>
														{reviewMsg}
													</div>
												</div>
											</form>

											<div className="rd-comment-main-list">
												{reviewsData !== ""
													? reviewsData.user_reviews.map((data: any) => {
															return (
																<>
																	<div
																		className="rd-comment-box"
																		key={data._id}
																	>
																		<div className="rd-comment-img">
																			<img
																				alt="Profile-Pic"
																				src={
																					data?.user_image.length > 0
																						? data?.user_image
																						: toAbsoluteUrl(
																								"/media/img/icons/pic44.jpg"
																						  )
																				}
																			/>
																		</div>
																		<div className="rd-comment-description">
																			<h2>{data.username}</h2>
																			<div className="rd-comment-rating">
																				<StarRating
																					rating={data.rating}
																					color="#009933"
																				/>
																				<span className="span-star-rating">
																					{data.rating} / 5
																				</span>
																				{data.rating > 4 ? (
																					<h5>Amazing Resort</h5>
																				) : data.rating > 3 ? (
																					<h5>Great Resort</h5>
																				) : data.rating > 2 ? (
																					<h5>Good Resort</h5>
																				) : data.rating > 1 ? (
																					<h5>Decent Resort</h5>
																				) : (
																					<h5>Poor Resort</h5>
																				)}
																			</div>
																			<p>{data.review}</p>

																			<p className="comment-post-date">
																				{new Date(
																					data.createdAt
																				).toLocaleDateString("en-GB")}
																			</p>
																		</div>
																	</div>
																</>
															);
													  })
													: ""}

												{/* <div className="rd-comment-box">
                          <div className="rd-comment-img">
                            <img
                              src={toAbsoluteUrl("/media/img/comment-img-2.jpg")}
                              alt="MyResorts.in"
                            />
                          </div>
                          <div className="rd-comment-description">
                            <h2>James Harden</h2>
                            <div className="rd-comment-rating">
                              
                              <div className="star-rat">
                                <img
                                  src={toAbsoluteUrl("/media/img/star-g.png")}
                                  alt="MyResorts.in"
                                />
                                <img
                                  src={toAbsoluteUrl("/media/img/star-g.png")}
                                  alt="MyResorts.in"
                                />
                                <img
                                  src={toAbsoluteUrl("/media/img/star-g.png")}
                                  alt="MyResorts.in"
                                />
                                <img
                                  src={toAbsoluteUrl("/media/img/star-g.png")}
                                  alt="MyResorts.in"
                                />
                                <img
                                  src={toAbsoluteUrl("/media/img/star-g.png")}
                                  alt="MyResorts.in"
                                />
                              </div>
                             
                              <span style={{ marginLeft: "10px" }}>4 / 5</span>
                              <h5>Good Rooms</h5>
                            </div>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing and
                              typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the 1500s,
                              when an unknown printer took a galley of type and
                              scrambled it to make a type specimen book. It has
                              survived not only five centuries, but also the leap
                              into electronic typesetting, remaining essentially
                              unchanged. It was popularised in the 1960s with the
                              release of Letraset sheets containing Lorem Ipsum
                              passages, and more recently with desktop publishing
                              software like Aldus PageMaker including versions of
                              Lorem Ipsum.
                            </p>

                            <p className="comment-post-date">31 Mar 2021</p>
                          </div>
                        </div> */}
											</div>
										</div>
									</Tab>
								</Tabs>
							</div>
						</Col>
					</Row>
				</div>

				<UserFooter />
			</div>
		</>
	);
}
