import "./User.scss";
//import "./global.scss";
import {
	Button,
	Carousel as Multicarousel,
	Col,
	Container,
	Form,
	Modal,
	Nav,
	Navbar,
	NavDropdown,
	Row,
	Tab,
} from "react-bootstrap-v5";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { useState } from "react";
import UserHeader from "./components/UserHeader";
import UserFooter from "./components/UserFooter";

import { createFilter } from "react-select";
import AsyncSelect from "react-select/async";
import { CSSProperties } from "react";
import axios from "axios";
import filtersState from "./userglobalstate/filterstate";
import { useState as useGlobalState } from "@hookstate/core";
import { useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// date range picker
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
	SampleNextArrow,
	SamplePrevArrow,
} from "./components/CustomArrows/CustomArrows";
import StarRating from "./components/StarRating";
// import StarRating from "./components/StarRating";
import FloatingWhatsApp from "react-floating-whatsapp";

const API_URL = process.env.REACT_APP_API_URL || "";

// const Img_Path = `https://myresort.s3.ap-south-1.amazonaws.com/`;
// const Img_Path = "https://myresortsbucket.s3.amazonaws.com/";
const Img_Path = "https://myresortsbucket-prod1.s3.ap-south-1.amazonaws.com/";

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
		slidesToSlide: 3, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		slidesToSlide: 2, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
};

const responsivetestimonial = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
		slidesToSlide: 3, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		slidesToSlide: 2, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
};

const Imgsettings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 1,
	autoplay: false,
	autoplaySpeed: 3000,
	arrows: true,
	nextArrow: <SampleNextArrow />,
	prevArrow: <SamplePrevArrow />,
	responsive: [
		{
			breakpoint: 500, // Adjust the breakpoint as needed
			settings: {
				slidesToShow: 1, // Number of slides to show on mobile
				arrows: false,
				dots: true,
			},
		},
	],
};

const customStyles = {
	control: (provided: any) => ({
		...provided,
		borderRadius: "80px !important",
		alignContent: "center",
		border: 0,
		boxShadow: "none",
	}),
	menu: (provided: any, state: any) => ({
		...provided,
		marginTop: "5px",
		borderRadius: "15px",
	}),
	menuList: (provided: any) => ({ ...provided, borderRadius: "15px" }),
	option: (provided: any, state: any) => ({
		...provided,
		background: state.isSelected
			? "#8dc73f"
			: state.isFocused
			? "rgb(234, 250, 234)"
			: "",
		":active": { ...provided[":active"], backgroundColor: "#c2edc2" },
	}),
	singleValue: (provided: any) => ({
		...provided,
		fontWeight: "600",
		fontSize: "14px",
	}),
};

// date range picker
interface dateRangeType {
	startDate?: Date | undefined;
	endDate?: Date | undefined;
	key?: string | undefined;
}
// date range picker

// Date Range Picker Click Outside Close Start's here
function useClickOutside(callback: () => void) {
	const ref = useRef<HTMLDivElement>(null);
	const handleClick = (event: MouseEvent) => {
		if (ref.current && !ref.current.contains(event.target as Node)) {
			callback();
		}
	};
	useEffect(() => {
		document.addEventListener("click", handleClick);
		return () => {
			document.removeEventListener("click", handleClick);
		};
	});
	return ref;
}
// Date Range Picker Click Outside Close End's here

export default function User() {
	const filters = useGlobalState(filtersState.filters);
	const [resultPlaceOptions, setResultPlaceOptions] = useState<any[]>([]);
	const [guestsFilterShow, setGuestsFilterShow] = useState(false);

	const [searchText, setSearchText] = useState<any>("");

	const [popularResorts, setPopularResorts] = useState<any[]>([{}]);

	const [reviews, setReviews] = useState<any>("");

	const [aboutUs, setAboutUs] = useState<any>("");

	const [twoBlogs, setTwoBlogs] = useState<any>("");
	const [threeBlogs, setThreeBlogs] = useState<any>("");

	const [coupleGroup, setCoupleGroup] = useState<any>(false);

	const [guestsFilter, setGuestFilter] = useState<any>({
		rooms: 1,
		adults: 2,
		groupAdults: 4,
		childRange1: 0,
		childRange2: 0,
		childRange3: 0,
	});

	//filters.attach(Persistence('gbl-fls'))

	// date range picker
	const [dateRange, setDateRange] = useState<dateRangeType[]>([
		{
			startDate: new Date(filters.checkIn.get()),
			endDate: new Date(filters.checkOut.get()),
			key: "selection",
		},
	]);
	const [openCalendar, setOpenCalendar] = useState(false);
	const calenderRef = useClickOutside(() => {
		return setOpenCalendar(false), setGuestsFilterShow(false);
	});

	// date range picker

	const getPlaceByInput = (placeInput: string) => {
		placeInput = placeInput.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
		let searchPattern = new RegExp(placeInput, "i");
		if (placeInput.length == 2)
			return axios
				.get(`${API_URL}/v1/user/location?place=${placeInput}`)
				.then((res) => {
					let result = res.data.data;
					let city: string[] = [];
					let resort: string[] = [];
					for (let item of result) {
						if (searchPattern.test(item.property_city)) {
							if (
								!city.some(
									(e) => e.toLowerCase() === item.property_city.toLowerCase()
								) &&
								!filters.defaultPlaceOptions
									.get()
									.some(
										(e: any) =>
											e.value.toLowerCase() === item.property_city.toLowerCase()
									)
							)
								city.push(item.property_city);
						}
						if (searchPattern.test(item.property_name)) {
							!resort.includes(
								item.property_name + ", " + item.property_city
							) && resort.push(item.property_name + ", " + item.property_city);
						}
					}
					let cityOptions = city.map((e) => ({ label: e, value: e, data: e }));
					let resortOptions = resort.map((e) => ({
						label: e + " (Resort)",
						value: e,
						data: e,
					}));
					setResultPlaceOptions([...cityOptions, ...resortOptions]);
					return [...cityOptions, ...resortOptions];
				});
		else {
			let matchingPlace = resultPlaceOptions.filter((e: any) => {
				if (searchPattern.test(e.value)) return e;
			});

			return Promise.resolve(matchingPlace);
		}
	};

	const handlePlaceChange = (newValue: any) => {
		filters.defaultPlaceOptions.set((d) => {
			if (!d.some((o) => o.value === newValue.value)) {
				d.unshift(newValue);
				return d;
			}
			return d;
		});
		filters.place.set({ ...newValue });
	};

	// date range picker
	const handleDateChange = (item: any) => {
		setDateRange([item.selection]);
		const startDate: Date = item.selection.startDate as Date;
		const endDate: Date = item.selection.endDate as Date;

		let tempDate = startDate
			.toLocaleString("en-US", {
				timeZone: process.env.LOCAL_TZ,
				hour12: false,
			})
			.split(",")[0]
			.split("/");

		let startDateStr =
			tempDate[2] +
			"-" +
			(parseInt(tempDate[0]) < 10 ? "0" : "") +
			tempDate[0] +
			"-" +
			(parseInt(tempDate[1]) < 10 ? "0" : "") +
			tempDate[1];

		tempDate = endDate
			.toLocaleString("en-US", {
				timeZone: process.env.LOCAL_TZ,
				hour12: false,
			})
			.split(",")[0]
			.split("/");

		let endDateStr =
			tempDate[2] +
			"-" +
			(parseInt(tempDate[0]) < 10 ? "0" : "") +
			tempDate[0] +
			"-" +
			(parseInt(tempDate[1]) < 10 ? "0" : "") +
			tempDate[1];

		filters.checkIn.set(startDateStr);
		filters.checkOut.set(endDateStr);
		startDateStr !== endDateStr && setOpenCalendar(false);

		if (startDateStr === endDateStr) {
			let FirstDate = new Date(startDateStr);

			let nextDay = new Date(startDateStr);
			nextDay.setDate(nextDay.getDate() + 1);
			let SecondDate = nextDay;

			const dateString = FirstDate;
			const checkInDate = new Date(dateString);
			const checkInYear = checkInDate.getFullYear();
			const checkInMonth = String(checkInDate.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
			const checkInDay = String(checkInDate.getDate()).padStart(2, "0");
			const CheckInDate = `${checkInYear}-${checkInMonth}-${checkInDay}`;

			const DateStringEnd = SecondDate;
			const checkOutDate = new Date(DateStringEnd);
			const checkOutYear = checkOutDate.getFullYear();
			const checkOutMonth = String(checkOutDate.getMonth() + 1).padStart(
				2,
				"0"
			); // Month is zero-indexed
			const checkOutDay = String(checkOutDate.getDate()).padStart(2, "0");
			const CheckOutDate = `${checkOutYear}-${checkOutMonth}-${checkOutDay}`;

			filters.checkIn.set(CheckInDate);
			filters.checkOut.set(CheckOutDate);
		}
	};
	// date range picker

	const handleGuestsNumberChange = (type: string, arith: string) => {
		let obj = { ...guestsFilter };
		if (arith === "add") obj[type]++;
		if (
			arith === "sub" &&
			(type === "adults" || type === "rooms") &&
			obj[type] !== 1
		)
			obj[type]--;
		if (
			arith === "sub" &&
			(type === "childRange1" ||
				type === "childRange2" ||
				type === "childRange3") &&
			obj[type] !== 0
		)
			obj[type]--;
		if (arith === "sub" && type === "groupAdults" && obj[type] !== 4)
			obj[type]--;

		setGuestFilter(obj);
	};

	const guestsFilterApply = () => {
		/* let obj = { ...filters }
    obj['guests'] = guestsFilter */
		filters.guests.set(guestsFilter);
		setGuestsFilterShow(false);
	};

	const handleOurGuests = () => {
		axios.get(`${API_URL}/v1/user/getreview`).then((res) => {
			setReviews(res.data.data);
		});
	};

	const handleBlogs = () => {
		axios.get(`${API_URL}/v1/user/bloglimit`).then((res) => {
			let fullData = res.data.data;
			const firstTwoBlogs = fullData.slice(0, 2);
			const remainingBlogs = fullData.slice(2);

			setTwoBlogs(firstTwoBlogs);
			setThreeBlogs(remainingBlogs);
		});
	};

	const handleAboutUs = () => {
		axios.get(`${API_URL}/v1/user/aboutus`).then((res) => {
			setAboutUs(res.data.data);
		});
	};

	const handlePopularResorts = () => {
		axios.get(`${API_URL}/v1/user/mostPopularProperty`).then((res) => {
			// console.log("res.data.data", res.data.data);
			// value?.property_policy?.property_status

			let d: any = [];
			res.data.data &&
				res.data.data.length > 0 &&
				res.data.data.map((value: any) => {
					// console.log("There", value.property_policy.property_status);

					if (value?.property_policy?.property_status === "Published") {
						// console.log("there");

						d.push(value);
					}
				});
			// console.log("There value", d);

			setPopularResorts(d);
		});
	};

	useEffect(() => {
		handlePopularResorts();
		handleOurGuests();
		handleAboutUs();
		handleBlogs();
	}, []);

	//  couple group switch button code
	const handleChecked = (e: any) => {
		setCoupleGroup(e.target.checked);
	};
	//  couple group switch button code

	const data = {
		rooms: guestsFilter.rooms,
		adults: guestsFilter.adults,
		groupAdults: guestsFilter.groupAdults,
		childRange1: guestsFilter.childRange1,
		childRange2: guestsFilter.childRange2,
		childRange3: guestsFilter.childRange3,
		checkIn: filters.checkIn.get(),
		checkOut: filters.checkOut.get(),
		dPlace: searchText,
		coupleGroupVal: coupleGroup,
		// dPlace: filters.place.data.get(),
	};
	const history = useHistory();

	const enterKye = (e: any) => {
		if (e.key === "Enter") {
			history.push({
				pathname: "/rooms",
				state: {
					search: `rooms=${data.rooms}&dPlace=${data.dPlace}&adults=${data.adults}&groupAdults=${data.groupAdults}&checkIn=${data.checkIn}&checkOut=${data.checkOut}&coupleGroupVal=${coupleGroup}&childRange1=${data.childRange1}&childRange2=${data.childRange2}&childRange3=${data.childRange3}`,
				},
			});
		}
	};

	const MostPopularsettings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: popularResorts.length > 3 ? 4 : popularResorts.length,
		// slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: true,
		variableWidth: true,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
				breakpoint: 500, // Adjust the breakpoint as needed
				settings: {
					slidesToShow: 1, // Number of slides to show on mobile
					arrows: false,
					autoplay: false,
					dots: true,
				},
			},
		],
	};
	return (
		<>
			{/* <UserHeader /> */}
			{/* <div className="fix-top-nav-pad"> */}
			<div className="fix-top-nav-padd" style={{ overflow: "hidden" }}>
				<div className="banner-section">
					{/* =================== Multi Carousel banner code======================================================= */}
					<Multicarousel interval={4000} fade wrap={true}>
						<Multicarousel.Item>
							<img
								className="d-block w-100"
								src={toAbsoluteUrl("/media/img/compImg/banner1.jpg")}
								alt="First slide"
							/>
							<Multicarousel.Caption>
								<h3>Welcome to MyResorts.in</h3>
								<p>The only Resort Booking Platform You Need!</p>
							</Multicarousel.Caption>
						</Multicarousel.Item>
						<Multicarousel.Item>
							<img
								className="d-block w-100"
								src={toAbsoluteUrl("/media/img/compImg/banner2.jpg")}
								alt="Second slide"
							/>

							<Multicarousel.Caption>
								<h3>Welcome to MyResorts.in</h3>
								<p>The only Resort Booking Platform You Need!</p>
							</Multicarousel.Caption>
						</Multicarousel.Item>
						<Multicarousel.Item>
							<img
								className="d-block w-100"
								src={toAbsoluteUrl("/media/img/compImg/banner3.jpg")}
								alt="Third slide"
							/>

							<Multicarousel.Caption>
								<h3>Welcome to MyResorts.in</h3>
								<p>The only Resort Booking Platform You Need!</p>
							</Multicarousel.Caption>
						</Multicarousel.Item>
					</Multicarousel>
				</div>
				{/* =================== Multi Carousel banner code ends here======================================================= */}
				<div className="book-now-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="book-now-box">
									<div className="switch-button switch-button-box">
										<input
											className="switch-button-checkbox"
											type="checkbox"
											onChange={handleChecked}
										></input>
										<label className="switch-button-label">
											<span className="switch-button-label-span">COUPLE</span>
										</label>
									</div>

									<span ref={calenderRef}>
										<ul className="list-item-book-now-box">
											<li>
												<h2>Places</h2>
												{/* <AsyncSelect
                          classNamePrefix="place-select"
                          loadOptions={getPlaceByInput}
                          defaultOptions={filters.defaultPlaceOptions.get()}
                          styles={customStyles}
                          placeholder="City or Resort"
                          defaultValue={filters.place.get()}
                          onChange={newValue => handlePlaceChange(newValue)}
                        /> */}
												<span className="search-box-container">
													<input
														type="text"
														onChange={(e: any) => setSearchText(e.target.value)}
														placeholder="Search Resorts"
														className="search-text-field"
														id="inputID"
														onKeyPress={(e) => {
															enterKye(e);
														}}
													/>
												</span>
											</li>
											<li>
												<h2>Guest and Rooms</h2>

												{coupleGroup ? (
													<input
														type="text"
														readOnly
														className="guestroomfield"
														placeholder="Rooms and Guests"
														id="inputID"
														//defaultValue="1 Room | 2 Adults | 0 Child"
														value={`${filters.guests.rooms.get()} Room${
															filters.guests.rooms.get() > 1 ? "s" : ""
														} | ${guestsFilter.groupAdults} Adult${
															guestsFilter.groupAdults > 1 ? "s" : ""
														} | ${guestsFilter.childRange3} Child${
															guestsFilter.childRange3 > 1 ? "ren" : ""
														}`}
														onChange={() => setGuestsFilterShow(false)}
														onClick={() => {
															return (
																setGuestsFilterShow(!guestsFilterShow),
																setOpenCalendar(false)
															);
														}}
													/>
												) : (
													<input
														type="text"
														readOnly
														className="guestroomfield"
														placeholder="Rooms and Guests"
														//defaultValue="1 Room | 2 Adults | 0 Child"
														value={`${filters.guests.rooms.get()} Room${
															filters.guests.rooms.get() > 1 ? "s" : ""
														} | ${filters.guests.adults.get()} Adult${
															filters.guests.adults.get() > 1 ? "s" : ""
														} | ${
															filters.guests.childRange1.get() +
															filters.guests.childRange2.get()
														} Child${
															filters.guests.childRange1.get() +
																filters.guests.childRange2.get() >
															1
																? "ren"
																: ""
														}`}
														onChange={() => setGuestsFilterShow(false)}
														onClick={() => {
															return (
																setGuestsFilterShow(!guestsFilterShow),
																setOpenCalendar(false)
															);
														}}
													/>
												)}

												{guestsFilterShow && (
													<>
														{coupleGroup ? (
															<>
																<div
																	className="guest-filter-show group-filter-show resort-guest-filter-show"
																	onBlur={() => setGuestsFilterShow(false)}
																>
																	<div
																		className="container-fluid user-select-none"
																		style={{
																			cursor: "pointer",
																			margin: "auto",
																		}}
																	>
																		<div className="row p-4 guest-filter-inputs">
																			<div className="col">
																				<label>Adults</label>
																				<div
																					className="row"
																					style={{
																						boxShadow: "0px 0px 5px green",
																						borderRadius: "5px",
																						marginTop: "5px",
																						width: "110px",
																					}}
																				>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"groupAdults",
																								"sub"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							-
																						</span>
																					</div>
																					<div className="col-6 border text-center">
																						<span className="fw-bold fs-3 user-select-none">
																							{guestsFilter["groupAdults"]}
																						</span>
																					</div>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"groupAdults",
																								"add"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							+
																						</span>
																					</div>
																				</div>
																			</div>
																			<div className="col">
																				Child (0-12 yrs)
																				<div
																					className="row"
																					style={{
																						boxShadow: "0px 0px 5px green",
																						borderRadius: "5px",
																						marginTop: "5px",
																						width: "110px",
																					}}
																				>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"childRange3",
																								"sub"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							-
																						</span>
																					</div>
																					<div className="col-6 border text-center">
																						<span className="fw-bold fs-3 user-select-none">
																							{guestsFilter["childRange3"]}
																						</span>
																					</div>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"childRange3",
																								"add"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							+
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className="d-flex justify-content-around mt-5">
																			<button
																				className="guests_done_apply_button"
																				type="button"
																				onClick={() => guestsFilterApply()}
																			>
																				Apply
																			</button>
																		</div>
																	</div>
																</div>
															</>
														) : (
															<>
																<div
																	className="guest-filter-show resort-guest-filter-show"
																	onBlur={() => setGuestsFilterShow(false)}
																>
																	<div
																		className="container-fluid user-select-none"
																		style={{
																			cursor: "pointer",
																			margin: "auto",
																		}}
																	>
																		<div className="row p-4 guest-filter-inputs">
																			<div className="col">
																				<label>Rooms</label>
																				<div
																					className="row"
																					style={{
																						boxShadow: "0px 0px 5px green",
																						borderRadius: "5px",
																						marginTop: "5px",
																						width: "110px",
																					}}
																				>
																					<div
																						className="col-3 border text-center fw-bold fs-3 user-select-none"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"rooms",
																								"sub"
																							)
																						}
																					>
																						-
																					</div>
																					<div className="col-6 border text-center fw-bold fs-3 user-select-none">
																						{guestsFilter["rooms"]}
																					</div>
																					<div
																						className="col-3 border text-center fw-bold fs-3 user-select-none"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"rooms",
																								"add"
																							)
																						}
																					>
																						+
																					</div>
																				</div>
																			</div>
																			<div className="col">
																				<label>Adults</label>
																				<div
																					className="row"
																					style={{
																						boxShadow: "0px 0px 5px green",
																						borderRadius: "5px",
																						marginTop: "5px",
																						width: "110px",
																					}}
																				>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"adults",
																								"sub"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							-
																						</span>
																					</div>
																					<div className="col-6 border text-center">
																						<span className="fw-bold fs-3 user-select-none">
																							{guestsFilter["adults"]}
																						</span>
																					</div>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"adults",
																								"add"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							+
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className="row mt-2 p-4 guest-filter-inputs">
																			<div className="col">
																				Child (0-6 yrs)
																				<div
																					className="row"
																					style={{
																						boxShadow: "0px 0px 5px green",
																						borderRadius: "5px",
																						marginTop: "5px",
																						width: "110px",
																					}}
																				>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"childRange1",
																								"sub"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							-
																						</span>
																					</div>
																					<div className="col-6 border text-center">
																						<span className="fw-bold fs-3 user-select-none">
																							{guestsFilter["childRange1"]}
																						</span>
																					</div>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"childRange1",
																								"add"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							+
																						</span>
																					</div>
																				</div>
																			</div>
																			<div className="col">
																				Child (6-12 yrs)
																				<div
																					className="row"
																					style={{
																						boxShadow: "0px 0px 5px green",
																						borderRadius: "5px",
																						marginTop: "5px",
																						width: "110px",
																					}}
																				>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"childRange2",
																								"sub"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							-
																						</span>
																					</div>
																					<div className="col-6 border text-center">
																						<span className="fw-bold fs-3 user-select-none">
																							{guestsFilter["childRange2"]}
																						</span>
																					</div>
																					<div
																						className="col-3 border text-center"
																						onClick={() =>
																							handleGuestsNumberChange(
																								"childRange2",
																								"add"
																							)
																						}
																					>
																						<span className="fw-bold fs-3 user-select-none">
																							+
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className="d-flex justify-content-around mt-5">
																			<button
																				className="guests_done_apply_button"
																				type="button"
																				onClick={() => guestsFilterApply()}
																			>
																				Apply
																			</button>
																		</div>
																	</div>
																</div>
															</>
														)}
													</>
												)}
											</li>
											<li>
												{openCalendar && (
													<div className="calender-box">
														<DateRange
															editableDateInputs={true}
															onChange={(item) => handleDateChange(item)} //setDateRange([item.selection])}
															moveRangeOnFirstSelection={false}
															ranges={dateRange}
															minDate={new Date()}
															months={2}
															direction="horizontal"
															className="calendarElement"
															rangeColors={["#009933"]}
														/>
													</div>
												)}

												<div className="date-range-box">
													<p className="date-range-title">check - in</p>
													<span
														className="date-range-input"
														onClick={() => {
															return (
																setOpenCalendar(!openCalendar),
																setGuestsFilterShow(false)
															);
														}}
													>
														<h3 className="date-range-text">
															{filters.checkIn.get().split("-")[2]}&nbsp;
														</h3>
														<h3 className="date-range-text">
															{" "}
															{`${
																[
																	"Jan",
																	"Feb",
																	"Mar",
																	"Apr",
																	"May",
																	"Jun",
																	"Jul",
																	"Aug",
																	"Sep",
																	"Oct",
																	"Nov",
																	"Dec",
																][
																	parseInt(
																		filters.checkIn.get().split("-")[1]
																	) - 1
																]
															} 20${filters.checkIn
																.get()
																.split("-")[0]
																.slice(-2)}`}
														</h3>
													</span>
												</div>
											</li>

											<li>
												<div className="date-range-box">
													<p className="date-range-title">check - out</p>
													<span
														className="date-range-input"
														onClick={() => {
															return (
																setOpenCalendar(!openCalendar),
																setGuestsFilterShow(false)
															);
														}}
													>
														<h3 className="date-range-text">
															{filters.checkOut.get().split("-")[2]}&nbsp;
														</h3>
														<h3 className="date-range-text">
															{" "}
															{`${
																[
																	"Jan",
																	"Feb",
																	"Mar",
																	"Apr",
																	"May",
																	"Jun",
																	"Jul",
																	"Aug",
																	"Sep",
																	"Oct",
																	"Nov",
																	"Dec",
																][
																	parseInt(
																		filters.checkOut.get().split("-")[1]
																	) - 1
																]
															} 20${filters.checkOut
																.get()
																.split("-")[0]
																.slice(-2)}`}
														</h3>
													</span>
												</div>
											</li>

											<li>
												<Link
													to={{
														pathname: "rooms",
														search: `rooms=${data.rooms}&dPlace=${data.dPlace}&adults=${data.adults}&groupAdults=${data.groupAdults}&checkIn=${data.checkIn}&checkOut=${data.checkOut}&coupleGroupVal=${coupleGroup}&childRange1=${data.childRange1}&childRange2=${data.childRange2}&childRange3=${data.childRange3}`,
													}}
												>
													<button className="find-resort-btn" type="submit">
														Find Resorts
													</button>
												</Link>
											</li>
										</ul>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="travel-mood-wrap pt-12 pb-12">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="travel-mood-title">
									<h2>Select Your Travel Mood</h2>
									{/* <a href="#!">View All</a> */}
								</div>
								<div className="travel-mood-inner">
									{/* <a href="#!" className="travel-mood-box hvr-wobble-horizontal">
                   wobble animation for left to right movement on click  */}

									<Link
										className="travel-mood-box"
										to={{
											pathname: "/rooms",
											search: `travelMoods=Chillout`,
										}}
									>
										<img
											src={toAbsoluteUrl("/media/img/travelmoods/chillout.svg")}
											alt="Chillout"
										/>
										<h3>Chillout</h3>
									</Link>

									<Link
										className="travel-mood-box"
										to={{
											pathname: "/rooms",
											search: `travelMoods=Adventure`,
										}}
									>
										<img
											src={toAbsoluteUrl(
												"/media/img/travelmoods/advanture.svg"
											)}
											alt="Adventure"
										/>
										<h3>Adventure</h3>
									</Link>

									<Link
										className="travel-mood-box"
										to={{
											pathname: "/rooms",
											search: `travelMoods=Luxury`,
										}}
									>
										<img
											src={toAbsoluteUrl("/media/img/travelmoods/luxury.svg")}
											alt="Luxury"
										/>
										<h3>Luxury</h3>
									</Link>

									<Link
										className="travel-mood-box"
										to={{
											pathname: "/rooms",
											search: `travelMoods=Leisure`,
										}}
									>
										<img
											src={toAbsoluteUrl("/media/img/travelmoods/leisure.svg")}
											alt="Leisure"
										/>
										<h3>Leisure</h3>
									</Link>

									<Link
										className="travel-mood-box"
										to={{
											pathname: "/rooms",
											search: `travelMoods=Weekend`,
										}}
									>
										<img
											src={toAbsoluteUrl("/media/img/travelmoods/weekend.svg")}
											alt="Weekend"
										/>
										<h3>Weekend</h3>
									</Link>

									<Link
										className="travel-mood-box"
										to={{
											pathname: "/rooms",
											search: `travelMoods=Budget`,
										}}
									>
										<img
											src={toAbsoluteUrl("/media/img/travelmoods/budget.svg")}
											alt="Budget"
										/>
										<h3>Budget</h3>
									</Link>

									<Link
										className="travel-mood-box"
										to={{
											pathname: "/rooms",
											search: `travelMoods=Safari`,
										}}
									>
										<img
											src={toAbsoluteUrl("/media/img/travelmoods/safari.svg")}
											alt="Safari"
										/>
										<h3>Safari</h3>
									</Link>

									<Link
										className="travel-mood-box"
										to={{
											pathname: "/rooms",
											search: `travelMoods=Nature`,
										}}
									>
										<img
											src={toAbsoluteUrl("/media/img/travelmoods/nature.svg")}
											alt="Nature"
										/>
										<h3>Nature</h3>
									</Link>

									<Link
										className="travel-mood-box"
										to={{
											pathname: "/rooms",
											search: `travelMoods=Solo`,
										}}
									>
										<img
											src={toAbsoluteUrl("/media/img/travelmoods/solo.svg")}
											alt="Solo"
										/>
										<h3>Solo</h3>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="resort-list">
					<div className="container">
						<Row>
							<Col lg="12">
								<div className="section-title">
									<h2>Exciting Places</h2>
								</div>
							</Col>

							{/* /////Saurabh's code starts here================================================================================================================================================================================================================ */}
							{/* 
<Carousel
  swipeable={false}
  draggable={false}
  showDots={true}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  //autoPlay={deviceType !== "mobile" ? true : false}
  autoPlaySpeed={1000}
  keyBoardControl={true}
  customTransition= "all .5"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
 // deviceType={deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
						>
  							<div>
  								<Col lg="4" md="4" xs="12">
									<div className="resort">
										<img
											src={toAbsoluteUrl(
												"/media/img/compImg/resort-img-01.jpg"
											)}
											alt="myresorts"
										/>
										<span>
											<img
												src={toAbsoluteUrl("/media/img/img-bg-user.png")}
												alt="myresorts"
											/>
										</span>
										<Link
											to={{
												pathname: "/rooms",
												search: `excitingPlaces=Pench`,
											}}
											className="resort-bt"
											style={{
												backgroundImage: `url("/media/img/bt-bg.png")`,
												backgroundRepeat: "no-repeat",
												backgroundSize: "cover",
											}}
										>
											Resort in Pench
										</Link>
									</div>
								</Col>
  							</div>
  							<div>
  								<Col lg="4" md="4" xs="12">
									<div className="resort">
										<img
											src={toAbsoluteUrl("/media/img/compImg/resort-img-2.jpg")}
											alt="myresorts"
										/>
										<span>
											<img
												src={toAbsoluteUrl("/media/img/img-bg-user.png")}
												alt="myresorts"
											/>
										</span>
										<Link
											to={{
												pathname: "/rooms",
												search: `excitingPlaces=Tadoba`,
											}}
											className="resort-bt"
											style={{
												backgroundImage: `url("/media/img/bt-bg.png")`,
												backgroundRepeat: "no-repeat",
												backgroundSize: "cover",
											}}
										>
											Resort in Tadoba
										</Link>
									</div>
								</Col>
  							</div>
  							<div>
  								<Col lg="4" md="4" xs="12">
									<div className="resort">
										<img
											src={toAbsoluteUrl("/media/img/compImg/resort-img-3.jpg")}
											alt="myresorts"
										/>
										<span>
											<img
												src={toAbsoluteUrl("/media/img/img-bg-user.png")}
												alt="myresorts"
											/>
										</span>
										<Link
											to={{
												pathname: "/rooms",
												search: `excitingPlaces=Kanha`,
											}}
											className="resort-bt"
											style={{
												backgroundImage: `url("/media/img/bt-bg.png")`,
												backgroundRepeat: "no-repeat",
												backgroundSize: "cover",
											}}
										>
											Resort in Kanha
										</Link>
									</div>
								</Col>
  							</div>
  
						</Carousel>; */}

							{/* ===========Original Carousal code ====================================================================================================================================================================*/}

							<Slider {...Imgsettings}>
								<Col lg="4" md="4" xs="12">
									<div className="resort">
										<img
											src={toAbsoluteUrl(
												"/media/img/compImg/resort-img-01.jpg"
											)}
											alt="myresorts"
										/>
										<span>
											<img
												src={toAbsoluteUrl("/media/img/img-bg-user.png")}
												alt="myresorts"
											/>
										</span>
										<Link
											to={{
												pathname: "/rooms",
												search: `excitingPlaces=Pench`,
											}}
											className="resort-bt"
											style={{
												backgroundImage: `url("/media/img/bt-bg.png")`,
												backgroundRepeat: "no-repeat",
												backgroundSize: "cover",
											}}
										>
											Resort in Pench
										</Link>
									</div>
								</Col>

								<Col lg="4" md="4" xs="12">
									<div className="resort">
										<img
											src={toAbsoluteUrl("/media/img/compImg/resort-img-2.jpg")}
											alt="myresorts"
										/>
										<span>
											<img
												src={toAbsoluteUrl("/media/img/img-bg-user.png")}
												alt="myresorts"
											/>
										</span>
										<Link
											to={{
												pathname: "/rooms",
												search: `excitingPlaces=Tadoba`,
											}}
											className="resort-bt"
											style={{
												backgroundImage: `url("/media/img/bt-bg.png")`,
												backgroundRepeat: "no-repeat",
												backgroundSize: "cover",
											}}
										>
											Resort in Tadoba
										</Link>
									</div>
								</Col>

								<Col lg="4" md="4" xs="12">
									<div className="resort">
										<img
											src={toAbsoluteUrl("/media/img/compImg/resort-img-3.jpg")}
											alt="myresorts"
										/>
										<span>
											<img
												src={toAbsoluteUrl("/media/img/img-bg-user.png")}
												alt="myresorts"
											/>
										</span>
										<Link
											to={{
												pathname: "/rooms",
												search: `excitingPlaces=Kanha`,
											}}
											className="resort-bt"
											style={{
												backgroundImage: `url("/media/img/bt-bg.png")`,
												backgroundRepeat: "no-repeat",
												backgroundSize: "cover",
											}}
										>
											Resort in Kanha
										</Link>
									</div>
								</Col>

								<Col lg="4" md="4" xs="12">
									<div className="resort">
										<img
											src={toAbsoluteUrl(
												"/media/img/compImg/resort-img-01.jpg"
											)}
											alt="myresorts"
										/>
										<span>
											<img
												src={toAbsoluteUrl("/media/img/img-bg-user.png")}
												alt="myresorts"
											/>
										</span>
										<Link
											to={{
												pathname: "/rooms",
												search: `excitingPlaces=Pench`,
											}}
											className="resort-bt"
											style={{
												backgroundImage: `url("/media/img/bt-bg.png")`,
												backgroundRepeat: "no-repeat",
												backgroundSize: "cover",
											}}
										>
											Resort in Pench
										</Link>
									</div>
								</Col>

								<Col lg="4" md="4" xs="12">
									<div className="resort">
										<img
											src={toAbsoluteUrl("/media/img/compImg/resort-img-2.jpg")}
											alt="myresorts"
										/>
										<span>
											<img
												src={toAbsoluteUrl("/media/img/img-bg-user.png")}
												alt="myresorts"
											/>
										</span>
										<Link
											to={{
												pathname: "/rooms",
												search: `excitingPlaces=Tadoba`,
											}}
											className="resort-bt"
											style={{
												backgroundImage: `url("/media/img/bt-bg.png")`,
												backgroundRepeat: "no-repeat",
												backgroundSize: "cover",
											}}
										>
											Resort in Tadoba
										</Link>
									</div>
								</Col>

								<Col lg="4" md="4" xs="12">
									<div className="resort">
										<img
											src={toAbsoluteUrl("/media/img/compImg/resort-img-3.jpg")}
											alt="myresorts"
										/>
										<span>
											<img
												src={toAbsoluteUrl("/media/img/img-bg-user.png")}
												alt="myresorts"
											/>
										</span>
										<Link
											to={{
												pathname: "/rooms",
												search: `excitingPlaces=Kanha`,
											}}
											className="resort-bt"
											style={{
												backgroundImage: `url("/media/img/bt-bg.png")`,
												backgroundRepeat: "no-repeat",
												backgroundSize: "cover",
											}}
										>
											Resort in Kanha
										</Link>
									</div>
								</Col>
							</Slider>

							{/* ===== original carousal code ends here =====================================================================================================================================================*/}
						</Row>

						<Row>
							<Col lg="6" xs="6">
								<div className="ad">
									<img
										src={toAbsoluteUrl("/media/img/Advertisements/Adv2.png")}
										alt="myresorts"
										// style={{ filter: 'contrast(200%)' }}
									/>
								</div>
							</Col>
							<Col lg="6" xs="6">
								<div className="ad">
									<img
										src={toAbsoluteUrl("/media/img/Advertisements/Adv2.png")}
										alt="myresorts"
									/>
								</div>
							</Col>
						</Row>
					</div>
				</div>

				<div className="featured-tours-wrap">
					<div
						className="container"
						style={{
							backgroundImage: `url("/media/img/resort-bg.jpg")`,
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}
					>
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title">
									<h3>Featured</h3>
									<h2>Most Popular Resorts</h2>
								</div>
							</div>

							<div className="col-lg-12">
								<div className="corousel-cont">
									<Slider {...MostPopularsettings}>
										{popularResorts !== undefined
											? popularResorts.map((value) => {
													let DefaultImg =
														window.innerWidth < 550
															? "/media/img/icons/mobileDefaultImg.png"
															: "/media/img/icons/defaultImg.png";
													// let DefaultImg = "/media/img/icons/defaultImg.png";
													return (
														value?.property_policy?.property_status ===
															"Published" && (
															<>
																<div
																	className="popular-tour-box popular-resorts-cards"
																	style={{ width: "24rem" }}
																>
																	<div className="pop-res-top-star-box">
																		<span className="pop-res-top-star-span">
																			<AiFillStar />
																			<span className="pop-res-top-star-rating">
																				{value?.property_policy
																					?.property_basic_info
																					?.property_star_rating
																					? value?.property_policy
																							?.property_basic_info
																							?.property_star_rating
																					: ""}
																				&nbsp;
																			</span>
																		</span>
																	</div>

																	<div className="popular-tours-img">
																		{value.images ? (
																			<>
																				<div className="pop-tour-img-big most-pop-resort-img ">
																					<img
																						src={
																							value?.images[0] === undefined
																								? DefaultImg
																								: `${Img_Path}${value?.images[0]}`
																						}
																						alt={`${value.category_basic_info.name}`}
																					/>
																				</div>

																				<div className="pop-tour-img-list">
																					<img
																						src={
																							value?.images[1] === undefined
																								? "/media/img/icons/defaultImg.png"
																								: `${Img_Path}${value?.images[1]}`
																						}
																						alt={`${value.category_basic_info.name}`}
																					/>

																					<img
																						src={
																							value?.images[2] === undefined
																								? "/media/img/icons/defaultImg.png"
																								: `${Img_Path}${value?.images[2]}`
																						}
																						alt={`${value.category_basic_info.name}`}
																					/>

																					<Link
																						to={{
																							pathname: "/roomdetails",
																							search: `catId=${value._id}`,
																						}}
																					>
																						<a href="">
																							<p>View All</p>
																							<img
																								src={
																									value?.images[3] === undefined
																										? "/media/img/icons/defaultImg.png"
																										: `${Img_Path}${value?.images[3]}`
																								}
																								alt={`${value.category_basic_info.name}`}
																							/>
																						</a>
																					</Link>
																				</div>
																			</>
																		) : (
																			""
																		)}
																	</div>
																	<div className="popular-tour-info-new ">
																		<div className="ratings-wrap">
																			<div className="popular-resorts-star-ratings">
																				<Link
																					to={{
																						pathname: "/roomdetails",
																						search: `catId=${value._id}`,
																					}}
																				>
																					<h5 className="popular-resort-category">
																						{value?.category_basic_info?.name}
																					</h5>
																				</Link>

																				{/* <AiFillStar />
                                  <span style={{ margin: "0px 4px 0px 3px" }}>
                                    {value.property_star_rating ? value.property_star_rating : 0}&nbsp;
                                  </span> */}
																			</div>
																			{/* <div className="star-ratings">
                                  <StarRating rating={value.property_star_rating} color="#009933" />
                                </div> */}
																			{/* <div className="ratings">
                                  <span>{value.property_star_rating ? value.property_star_rating : 0} / 5</span>
                                  <p>332 Ratings</p>
                                </div> */}
																		</div>

																		<Link
																			to={{
																				pathname: "/resortdetails",
																				search: `propertyId=${value.property_id}`,
																			}}
																		>
																			{value.property_name !== undefined ? (
																				<h2 className="popular-resort-property">
																					{" "}
																					{value.property_name}{" "}
																				</h2>
																			) : (
																				""
																			)}
																		</Link>

																		{/* <a href="resortdetails">
                                {value.property_name !== undefined ?
                                  <h2 className="popular-resort-property"> {value.property_name} </h2> : <h2> Happy Resort </h2>}
                              </a> */}

																		{/* {value.property_locality !== undefined ?
                                <h3>{value.property_locality} {value.property_address}, {value.property_country} {value.property_zip_code}</h3> : <h3>72/1 Ranibagan, Berhampore West Bengal, India 742101
                                </h3>} */}

																		{value.property_policy !== undefined ? (
																			<h3>
																				{
																					value?.property_policy
																						?.property_location
																						?.property_nearest_gate
																				}
																				,&nbsp;
																				{/* {
																					value?.property_policy
																						?.property_location
																						?.property_address
																				} */}
																				{
																					value?.property_policy
																						?.property_location?.property_state
																				}
																				{/* ,
																				{
																					value?.property_policy
																						?.property_location
																						?.property_locality
																				} */}
																			</h3>
																		) : (
																			""
																		)}

																		<div className="amenities amenities-names-box ">
																			{value?.property_policy
																				?.property_amenities !== undefined
																				? value?.property_policy?.property_amenities?.basic_facilities?.map(
																						(data: any, index: any) => {
																							return (
																								<span
																									className="amenities-name"
																									key={index}
																								>
																									{data}
																								</span>
																							);
																						}
																				  )
																				: ""}
																		</div>
																		<div className="price-n-off">
																			<h2>
																				₹{" "}
																				{
																					value?.category_base_pricing
																						?.base_price
																				}
																			</h2>
																			<h2 className="dummy-price">
																				₹
																				{1540 +
																					Number(
																						value?.category_base_pricing
																							?.base_price
																					)}
																			</h2>
																			<span>
																				<h3 style={{ textAlign: "right" }}>
																					+taxes & fees
																				</h3>
																				<p> per room / night</p>
																			</span>

																			{/* <a href="#" className="off-bt">
																					60% Off
																				</a> */}
																		</div>

																		<Link
																			to={{
																				pathname: "/roomdetails",
																				search: `catId=${value._id}`,
																			}}
																		>
																			<a href="/" className="book-for-now">
																				Book Now
																			</a>
																		</Link>
																	</div>
																</div>
															</>
														)
													);
											  })
											: ""}
									</Slider>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="our-blog-wrap">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title">
									<h3>Our</h3>
									<h2 className="blog-heading">Blog</h2>
								</div>
							</div>
							<div className="col-lg-7">
								<Row>
									{twoBlogs !== ""
										? twoBlogs.map((data: any) => {
												const blogData =
													data.blog_data && data.blog_data.length > 300
														? `${data.blog_data.substring(0, 300)}...`
														: data.blog_data;

												const ellipsisStyle =
													data.blog_data && data.blog_data.length > 300
														? { textOverflow: "ellipsis" }
														: {};

												return (
													<>
														{" "}
														<Col lg="6" sm="6" key={data._id}>
															<div className="blog-box">
																<div className="blog-img">
																	<Link
																		to={{
																			pathname: "/blogdetails",
																			search: `blogId=${data._id}`,
																		}}
																	>
																		<img
																			src={
																				data?.blog_image_id === undefined
																					? "/media/img/blog-2.jpg"
																					: `${Img_Path}${data?.blog_image_id}`
																			}
																			alt={`${data?.blog_title}`}
																		/>
																	</Link>
																</div>
																<div className="blog-info">
																	<h4>{data.blog_date}</h4>
																	<h3>{data.blog_title}</h3>
																	<p
																		style={ellipsisStyle}
																		dangerouslySetInnerHTML={{
																			__html: blogData,
																		}}
																	></p>
																	<Link
																		to={{
																			pathname: "/blogdetails",
																			search: `blogId=${data._id}`,
																		}}
																	>
																		<a className="readmore">Read more +</a>
																	</Link>
																</div>
															</div>
														</Col>
													</>
												);
										  })
										: ""}
								</Row>
							</div>

							<div className="col-lg-5 blog-Main">
								{threeBlogs !== ""
									? threeBlogs.map((data: any) => {
											const blogData =
												data.blog_data && data.blog_data.length > 250
													? `${data.blog_data.substring(0, 250)}...`
													: data.blog_data;

											const ellipsisStyle =
												data.blog_data && data.blog_data.length > 250
													? { textOverflow: "ellipsis" }
													: {};

											return (
												<>
													<div className="blog-vert-list-box" key={data._id}>
														<div className="blog-vert-left">
															<p
																style={ellipsisStyle}
																dangerouslySetInnerHTML={{ __html: blogData }}
															></p>
															<h5>{data.blog_date}</h5>
														</div>
														<div className="blog-vert-right">
															<Link
																to={{
																	pathname: "/blogdetails",
																	search: `blogId=${data._id}`,
																}}
															>
																<img
																	src={
																		data?.blog_image_id === undefined
																			? "/media/img/blog-2.jpg"
																			: `${Img_Path}${data?.blog_image_id}`
																	}
																	alt={`${data.blog_title}`}
																/>
															</Link>
														</div>
													</div>
												</>
											);
									  })
									: ""}
							</div>
						</div>
					</div>
				</div>

				<div
					className="testimonial-wrap container"
					style={{
						backgroundImage: `url("/media/img/resort-bg.jpg")`,
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
					}}
				>
					<div className="testimonial-title">
						<h2>Our Guests</h2>
						<p>Check Out what they have to say about our service</p>
					</div>

					{reviews !== "" ? (
						<>
							<Carousel
								responsive={responsivetestimonial}
								autoPlay={true}
								showDots={true}
								autoPlaySpeed={3000}
								keyBoardControl={true}
								partialVisible={true}
								arrows={false}
							>
								{reviews !== ""
									? reviews.map((value: any) => {
											return (
												<>
													<div key={value._id}>
														<div className="tesimonial-box">
															<div className="test-img-wrap">
																<div className="test-img">
																	<img
																		// src={toAbsoluteUrl(
																		// 	"/media/img/icons/pic44.jpg"
																		// )}
																		src={
																			value?.user_image
																				? value?.user_image[0]
																				: toAbsoluteUrl(
																						"/media/img/icons/pic44.jpg"
																				  )
																		}
																		alt={value?.username}
																	/>
																</div>
																<img
																	src={toAbsoluteUrl(
																		"/media/img/icon-quote.png"
																	)}
																	alt={value?.username}
																/>
															</div>
															<h3>{value?.username}</h3>
															<p>“{value?.review}”</p>
															<div className="star-rate">
																<h5>
																	{new Date(value.createdAt).toLocaleDateString(
																		"en-GB"
																	)}
																</h5>
																{/* <span>
                            <img
                              src={toAbsoluteUrl("/media/img/star.png")}
                              alt={value?.username}
                            />
                          </span> */}
																<StarRating
																	rating={value.rating}
																	color="#FFD700"
																/>
															</div>
														</div>
													</div>
												</>
											);
									  })
									: ""}
							</Carousel>
						</>
					) : (
						""
					)}
				</div>
				<div
					className="about-us-wrapper"
					style={{
						backgroundImage: `url("/media/img/about-leaf-bg.svg")`,
						backgroundRepeat: "no-repeat",
					}}
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title">
									{/* <h3>Our</h3> */}
									<h2>About us</h2>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="about-banner-section">
									<Multicarousel>
										<Multicarousel.Item>
											<div className="about-img-section">
												<img
													className="about-carousel-img"
													src={toAbsoluteUrl(
														"/media/img/homeAbout/about_one.jpg"
													)}
													alt="myresorts.in"
												/>
												<div className="about-img-info">
													<img
														src={toAbsoluteUrl("/media/img/about-icon.png")}
														alt="myresorts.in"
													/>
													<h3>Unforgettable Resort Experiences Await!</h3>
													<p>
														At our resort booking website, we connect you with
														the most exquisite destinations worldwide, ensuring
														unforgettable getaways tailored to your desires.
													</p>
												</div>
											</div>
										</Multicarousel.Item>

										<Multicarousel.Item>
											<div className="about-img-section">
												<img
													className="about-carousel-img"
													src={toAbsoluteUrl(
														"/media/img/homeAbout/about_two.jpg"
													)}
													alt="myresorts.in"
												/>
												<div className="about-img-info">
													<img
														src={toAbsoluteUrl("/media/img/about-icon.png")}
														alt="myresorts.in"
													/>
													<h3>Discover Your Perfect Getaway</h3>
													<p>
														At Myresorts.in we make finding and booking your
														dream vacation effortless. Explore rejuvenating
														wildlife destinations and create unforgettable
														memories.
													</p>
												</div>
											</div>
										</Multicarousel.Item>

										<Multicarousel.Item>
											<div className="about-img-section">
												<img
													className="about-carousel-img"
													src={toAbsoluteUrl(
														"/media/img/homeAbout/about_three.jpg"
													)}
													alt="myresorts.in"
												/>
												<div className="about-img-info">
													<img
														src={toAbsoluteUrl("/media/img/about-icon.png")}
														alt="myresorts.in"
													/>
													<h3>Discover Wildlife with Myresorts.in</h3>
													<p>
														Immerse yourself in luxury and tranquility at
														Myresorts.in, offering unparalleled amenities,
														serene views, and exceptional service. Your jungle
														adventure awaits.
													</p>
												</div>
											</div>
										</Multicarousel.Item>
									</Multicarousel>
								</div>
							</div>

							<div className="col-lg-6">
								{aboutUs !== ""
									? aboutUs.map((data: any) => {
											return (
												<>
													<div className="about-us-col-right">
														<h2>{data.title}</h2>
														<p>{data.info}</p>
													</div>
													<div className="about-features-list">
														<div className="div">
															<img
																src={toAbsoluteUrl(
																	"/media/img/about-icon-1.svg"
																)}
																alt={data?.title}
															/>
															<h3>{data.luxury}</h3>
															<p>Luxury Resorts</p>
														</div>

														<div className="div">
															<img
																src={toAbsoluteUrl(
																	"/media/img/about-icon-2.svg"
																)}
																alt={data?.title}
															/>
															<h3>{data.attractive}</h3>
															<p> Attractive Ambience </p>
														</div>

														<div className="div">
															<img
																src={toAbsoluteUrl(
																	"/media/img/about-icon-3.svg"
																)}
																alt={data?.title}
															/>
															<h3>{data.happy}</h3>
															<p>Happy Guests</p>
														</div>
													</div>
												</>
											);
									  })
									: ""}
							</div>
						</div>
					</div>
				</div>
				<UserFooter />
			</div>
		</>
	);
}
