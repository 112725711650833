import "./Profile.scss";
import {
	Carousel as Multicarousel,
	Col,
	Container,
	Form,
	Nav,
	Navbar,
	NavDropdown,
	Row,
	Tab,
	Tabs,
	Modal,
	Button,
} from "react-bootstrap-v5";
import Collapsible from "react-collapsible";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import ImageGallery from "react-image-gallery";
import UserHeader from "../components/UserHeader";
import UserFooter from "../components/UserFooter";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";

const API_URL = process.env.REACT_APP_API_URL || "";

let UserFirstName = localStorage.getItem("UserFirstname") || "";
let UserLastName = localStorage.getItem("UserLastname") || "";
let UserEmail = localStorage.getItem("UserEmail") || "";
// let UserName = `${UserFirstName} ${UserLastName}`;

export default function Profile() {
	const [profileData, setProfileData] = useState<any>("");
	const [upcomingBooking, setUpcomingBooking] = useState<any>("");
	const [completedBooking, setCompletedBooking] = useState<any>("");
	const [cancelledBooking, setCancelledBooking] = useState<any>("");

	const [show, setShow] = useState(false);

	const [profilePic, setProfilePic] = useState<any>("");
	const [loading, setLoading] = useState(false);

	const [showAlert, setShowAlert] = useState(false);
	const [cancelBookingId, setCancelBookingId] = useState<any>("");

	const userNum = localStorage.getItem("UserNum") || "";
	const userId = localStorage.getItem("UserId") || "";

	const getProfileData = () => {
		axios
			.get(`${API_URL}/v1/book/booking_by_cat?userId=${userId}`)
			.then((res) => {
				// console.log(res.data.user_image)
				setProfilePic(res.data.user_image);
				setProfileData(res.data);
				setUpcomingBooking(res.data.data.upcoming_booking);
				setCompletedBooking(res.data.data.completed_booking);
				setCancelledBooking(res.data.data.cancelled_booking);
				setLoading(false);
			});
	};

	useEffect(() => {
		getProfileData();
	}, []);

	useEffect(() => {
		if (window.innerWidth < 550) {
			window.scrollTo({ top: 140, behavior: "smooth" });
		} else if (window.innerWidth > 700 && window.innerWidth < 1200) {
			window.scrollTo({ top: 280, behavior: "smooth" });
		} else if (window.innerWidth >= 1200) {
			window.scrollTo({ top: 410, behavior: "smooth" });
		}
	}, []);

	// ============= update user info code start's here =======================

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleCloseAlert = () => setShowAlert(false);
	const handleShowAlert = () => setShowAlert(true);

	const cancelBookings = (id: any) => {
		axios
			.post(`${API_URL}/v1/book/cancel_booking`, {
				_id: id,
				booking_status: "Cancelled",
				payment_status: "Cancelled",
			})
			.then(() => {
				history.push({
					pathname: "/bookingcancelled",
					search: `?confirmation_id=${id}`,
				});

				// axios
				// 	.post(`${API_URL}/v1/customer/booking_cancelled`, {
				// 		mobile: mobile,
				// 		userEmailId: userEmailId,
				// 		firstName: firstName,
				// 		lastName: lastName,
				// 		propertyName: propertyName,
				// 		roomName: roomName,
				// 		checkIn: checkIn,
				// 		checkOut: checkOut,
				// 		totalGuest: totalGuest,
				// 		bookingId: id,
				// 	})
				// 	.then((res) => {
				// 		console.log(res);
				// 	});

				getProfileData();
				handleCloseAlert();
			});
	};

	// =========== Modal Code Start's here ============

	const initialValues = {
		firstname: UserFirstName,
		lastname: UserLastName,
		number: userNum,
		email: UserEmail,
	};

	const signUpSchema = Yup.object({
		number: Yup.string()
			.required("Mobile Number is Required")
			.max(10, "Enter Valid Mobile Number")
			.phone("IN", true, "Phone Number is Invalid"),
	});
	// =========== Modal Code End's here ============

	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			initialValues: initialValues,
			validationSchema: signUpSchema,

			onSubmit: (values, action) => {
				// console.log(values, "form modal values");
				axios
					.post(`${API_URL}/v1/customer/register_booking_customer`, {
						mobile: values.number,
						firstname: values.firstname,
						lastname: values.lastname,
						email: values.email,
					})
					.then((res) => {
						// console.log(res.data, "res data");

						getProfileData();

						localStorage.setItem("UserFirstname", values.firstname);
						localStorage.setItem("UserLastname", values.lastname);
						localStorage.setItem("UserEmail", values.email);

						handleClose();

						// console.log(res.data.data, "userid")
					});
				// action.resetForm()   //to clear the user info after submitting the form
			},
		});

	// ================ Only Number can Type IN Input Field ==============
	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		// Allow only numbers and backspace
		if (!/^\d$/.test(e.key) && e.key !== "Backspace") {
			e.preventDefault();
		}
	};
	// ================ Only Number can Type IN Input Field ==============

	// ============= update user info code end's here =========================

	// -------------------- Profile pic Upload Code ----------------------------//
	const selectImage = useRef<HTMLInputElement | null>(null);
	const uploadPhoto = () => {
		if (!selectImage.current) return;
		selectImage.current.click();
	};
	const handleImageChange = (e: any) => {
		setLoading(true);
		let image = e.target.files[0];
		let formData = new FormData();
		formData.append("user_id", userId);
		formData.append("user-image", image);
		axios.post(`${API_URL}/v1/user/user_img`, formData).then(() => {
			getProfileData();
		});
	};
	// -------------------- Profile pic Upload Code ----------------------------//

	// ================== User Login Check Code Start's here  ====================
	const history = useHistory();
	useEffect(() => {
		const localUserId = localStorage.getItem("UserId");
		if (!localUserId) {
			history.push("/home");
		}
	}, [history]);
	// ================== User Login Check Code End's here  ====================

	return (
		<>
			{/* <UserHeader /> */}
			<div className="payment-confirmation-wrapper profile-page-wrapper fix-top-nav-padd">
				<div className="room-details-banner">
					<img src={toAbsoluteUrl("/media/img/banner-2.jpg")} alt="MyResorts.in" />
					<div className="room-details-banner-info">
						<h1>Profile</h1>
						{/* <h5>
              Ipsum is that it has a more-or-less normal distribution of letters,
              as opposed to using 'Content here, content here',{" "}
            </h5> */}
					</div>
					<div className="overlay-hy">
						<img
							src={toAbsoluteUrl("/media/img/tree-shape.png")}
							alt="MyResorts.in"
						/>
					</div>
				</div>

				<div className="container">
					<Row>
						<Col lg="12">
							<div className="payment-confirm-wrapper profile-main-wrapper">
								<div className="pro-top-right-vector">
									<img
										src={toAbsoluteUrl("/media/img/profile-vector-2.svg")}
										alt="MyResorts.in"
									/>

									<h3>Welcome</h3>
								</div>

								<div className="profile-pic-change-wrap">
									<div className="profile-image">
										<span onClick={uploadPhoto}>
											{" "}
											{loading ? "Uploading Profile Pic..." : " "}{" "}
										</span>
										<input
											type="file"
											ref={selectImage}
											style={{ display: "none" }}
											onChange={(e) => handleImageChange(e)}
										/>

										<div className="img-pr" onClick={uploadPhoto}>
											<img
												alt="Profile-Pic"
												src={
													profilePic !== ""
														? profilePic
														: toAbsoluteUrl("/media/img/profile-vector-1.svg")
												}
											/>
											<input
												type="file"
												accept="image/*"
												ref={selectImage}
												style={{ display: "none" }}
												onChange={handleImageChange}
											/>
										</div>
									</div>
									<div className="profile-info">
										<h2 onClick={handleShow}>
											{profileData.firstname !== undefined
												? `${profileData.firstname} ${profileData.lastname}`
												: "Guest"}
											<span className="pencil-icon">
												<img
													src={toAbsoluteUrl("/media/img/edit.svg")}
													alt="Edit"
												/>
											</span>
										</h2>

										<div className="pro-info-form">
											<form action="">
												<ul>
													<li>
														<label htmlFor="">Phone Number</label>
														<span>
															<input type="number" value={userNum} readOnly />
															<a href="#!" onClick={handleShow}>
																<img
																	src={toAbsoluteUrl("/media/img/edit.svg")}
																	alt="MyResorts.in"
																/>
															</a>
														</span>
													</li>

													<li>
														<label htmlFor="">Email Address</label>
														<span>
															<input
																type="text"
																value={profileData?.email}
																readOnly
															/>
															<a href="#!" onClick={handleShow}>
																<img
																	src={toAbsoluteUrl("/media/img/edit.svg")}
																	alt="MyResorts.in"
																/>
															</a>
														</span>
													</li>
												</ul>
											</form>
										</div>
									</div>
								</div>

								<div className="big-input" style={{ display: "none" }}>
									<div className="b-input-label-wrap">
										<label htmlFor="">Change password</label>
										<a href="#">
											<img
												src={toAbsoluteUrl("/media/img/edit.svg")}
												alt="MyResorts.in"
											/>
										</a>
									</div>
									<span>
										<h5>Current Password</h5>
										<input type="password" />
									</span>
								</div>

								<div className="big-input" style={{ display: "none" }}>
									<div className="b-input-label-wrap">
										<label htmlFor="">Payment Options</label>
										<a href="#">
											<img
												src={toAbsoluteUrl("/media/img/edit.svg")}
												alt="MyResorts.in"
											/>
										</a>
									</div>
									<span>
										<h5>Payment</h5>
									</span>
								</div>

								<div className="booking-history-wrap">
									<h2>Booking History</h2>
									<div className="booking-history-box booking-history-content">
										<Collapsible trigger="Upcoming booking">
											{profileData ? (
												upcomingBooking.length === 0 ? (
													<>
														<div className="empty-booking-box">
															<h3>Empty Booking</h3>
														</div>
													</>
												) : (
													<>
														{upcomingBooking !== ""
															? upcomingBooking.map((data: any) => {
																	const date = new Date(data.bookingtime);
																	const options = { timeZone: "Asia/Kolkata" };
																	const istString = date.toLocaleString(
																		"en-US",
																		options
																	);
																	const [dateString, timeString] =
																		istString.split(", ");

																	const [monthStr, dayStr, yearStr] =
																		dateString.split("/");
																	const BookingDateString =
																		dayStr + "/" + monthStr + "/" + yearStr;

																	return (
																		<>
																			<div
																				className="complete-booking-box"
																				key={data._id}
																			>
																				<span>
																					<Link
																						to={{
																							pathname: "/bookingdetails",
																							search: `confirmation_id=${data._id}`,
																						}}
																					>
																						<h3>
																							{data?.category_naME} -{" "}
																							{data?.property_name}{" "}
																						</h3>
																					</Link>
																					<p>
																						{" "}
																						{
																							data?.property_locality
																								?.property_nearest_gate
																						}
																						,{" "}
																						{
																							data?.property_locality
																								?.property_address
																						}
																						,{" "}
																						{
																							data?.property_locality
																								?.property_city
																						}{" "}
																					</p>
																					<p>
																						{" "}
																						{`${BookingDateString} ${timeString}`}
																					</p>
																				</span>

																				<span>
																					<h4>Net Amount Paid: </h4>
																					<h4>Rs. {data?.booking_amount}</h4>

																					{/* <button className="cancel-btn" onClick={handleShowAlert}>Cancel Booking</button> */}
																					{/* <button className="cancel-btn" onClick={()=>cancelBookings(data._id)}>Cancel Booking</button> */}
																					<button
																						className="cancel-btn"
																						onClick={() => {
																							setCancelBookingId(data._id);
																							handleShowAlert();
																						}}
																					>
																						Cancel Booking
																					</button>
																				</span>
																			</div>
																		</>
																	);
															  })
															: ""}
													</>
												)
											) : (
												""
											)}
										</Collapsible>

										<Collapsible trigger="Completed booking">
											{profileData ? (
												completedBooking.length === 0 ? (
													<>
														<div className="empty-booking-box">
															<h3>Empty Booking</h3>
														</div>
													</>
												) : (
													<>
														{completedBooking !== ""
															? completedBooking.map((data: any) => {
																	const date = new Date(data.bookingtime);
																	const options = { timeZone: "Asia/Kolkata" };
																	const istString = date.toLocaleString(
																		"en-US",
																		options
																	);
																	const [dateString, timeString] =
																		istString.split(", ");

																	const [monthStr, dayStr, yearStr] =
																		dateString.split("/");
																	const BookingDateString =
																		dayStr + "/" + monthStr + "/" + yearStr;

																	return (
																		<>
																			<div
																				className="complete-booking-box"
																				key={data._id}
																			>
																				<span>
																					<Link
																						to={{
																							pathname: "/bookingdetails",
																							search: `confirmation_id=${data._id}`,
																						}}
																					>
																						<h3>
																							{data?.category_naME} -{" "}
																							{data?.property_name}{" "}
																						</h3>
																					</Link>
																					<p>
																						{" "}
																						{
																							data?.property_locality
																								?.property_nearest_gate
																						}
																						,{" "}
																						{
																							data?.property_locality
																								?.property_address
																						}
																						,{" "}
																						{
																							data?.property_locality
																								?.property_city
																						}{" "}
																					</p>
																					<p>
																						{" "}
																						{`${BookingDateString} ${timeString}`}
																					</p>
																				</span>

																				<span>
																					<h4>Net Amount Paid: </h4>
																					<h4>Rs. {data?.booking_amount}</h4>
																				</span>
																			</div>
																		</>
																	);
															  })
															: ""}
													</>
												)
											) : (
												""
											)}
										</Collapsible>

										<Collapsible trigger="Cancelled booking">
											{profileData ? (
												cancelledBooking.length === 0 ? (
													<>
														<div className="empty-booking-box">
															<h3>Empty Booking</h3>
														</div>
													</>
												) : (
													<>
														{cancelledBooking !== ""
															? cancelledBooking.map((data: any) => {
																	const date = new Date(data.bookingtime);
																	const options = { timeZone: "Asia/Kolkata" };
																	const istString = date.toLocaleString(
																		"en-US",
																		options
																	);
																	const [dateString, timeString] =
																		istString.split(", ");

																	const [monthStr, dayStr, yearStr] =
																		dateString.split("/");
																	const BookingDateString =
																		dayStr + "/" + monthStr + "/" + yearStr;

																	return (
																		<>
																			<div
																				className="complete-booking-box"
																				key={data._id}
																			>
																				<span>
																					<Link
																						to={{
																							pathname: "/bookingcancelled",
																							search: `confirmation_id=${data._id}`,
																						}}
																					>
																						<h3>
																							{data?.category_naME} -{" "}
																							{data?.property_name}{" "}
																						</h3>
																					</Link>
																					<p>
																						{" "}
																						{
																							data?.property_locality
																								?.property_nearest_gate
																						}
																						,{" "}
																						{
																							data?.property_locality
																								?.property_address
																						}
																						,{" "}
																						{
																							data?.property_locality
																								?.property_city
																						}{" "}
																					</p>
																					<p>
																						{" "}
																						{`${BookingDateString} ${timeString}`}
																					</p>
																				</span>

																				<span>
																					<h4>Net Amount Paid: </h4>
																					<h4>Rs. {data?.booking_amount}</h4>
																				</span>
																			</div>
																		</>
																	);
															  })
															: ""}
													</>
												)
											) : (
												""
											)}
										</Collapsible>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</div>

				{/* Login Modal code Start's here */}
				<Modal show={show} onHide={handleClose} centered size="lg">
					<Modal.Body className="loginModal">
						<div className="login-body-content">
							<form onSubmit={handleSubmit}>
								<div className="login-form-user">
									<div className="input-box">
										<img src={toAbsoluteUrl("/media/img/user.svg")} alt="" />
										<input
											type="text"
											autoComplete="off"
											name="firstname"
											id="firstname"
											placeholder="Enter Your First Name"
											value={values.firstname}
											onBlur={handleBlur}
											onChange={handleChange}
											required
										/>
									</div>

									<div className="input-box">
										<img src={toAbsoluteUrl("/media/img/user.svg")} alt="" />
										<input
											type="text"
											autoComplete="off"
											name="lastname"
											id="lastname"
											placeholder="Enter Your Last Name"
											value={values.lastname}
											onBlur={handleBlur}
											onChange={handleChange}
											required
										/>
									</div>

									{/* <div className="input-box">
                    <img
                      src={toAbsoluteUrl("/media/img/call.png")}
                      alt=""
                    />
                    <input type="text" autoComplete='off' name='number' id='number' onKeyPress={handleKeyPress}
                      placeholder='Enter Your Mobile Number' value={values.number} onChange={handleChange}
                      onBlur={handleBlur} maxLength={10} />
                  </div> */}

									<div className="input-box">
										<img src={toAbsoluteUrl("/media/img/email.png")} alt="" />
										<input
											type="email"
											placeholder="Enter Your Email Here"
											onBlur={handleBlur}
											name="email"
											id="email"
											value={values.email}
											onChange={handleChange}
											required
										/>
									</div>

									<div className="login-bt-box">
										<button className="login-bt-user" type="submit">
											Update
										</button>
									</div>
								</div>
							</form>
						</div>
					</Modal.Body>
				</Modal>
				{/* Modal code End's here */}

				<Modal show={showAlert} onHide={handleCloseAlert} size="sm">
					<Modal.Body className="loginModal">
						<div className="alertModal">
							{/* <div className="login-body-content"> */}
							<h4 className="alert-text">
								Are You Sure You Want to Cancel Your Booking ?
							</h4>

							<hr></hr>

							<div className="alert-buttons">
								<Button
									className="bg-primary no-btn"
									onClick={() => setShowAlert(false)}
								>
									No
								</Button>
								<Button
									className="bg-danger yes-btn"
									onClick={() => cancelBookings(cancelBookingId)}
								>
									Yes
								</Button>
							</div>

							{/* </div> */}
						</div>
					</Modal.Body>
				</Modal>

				<UserFooter />
			</div>
		</>
	);
}
