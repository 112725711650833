import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../User.scss";
import {
	Button,
	Carousel as Multicarousel,
	Col,
	Container,
	Form,
	Modal,
	Nav,
	Navbar,
	NavDropdown,
	Row,
	Tab,
} from "react-bootstrap-v5";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import axios from "axios";
import TermsOfUse from "./term/TermModel";
import Privacy from "./term/Privacy";
import Refund from "./term/Refund";
import FloatingWhatsApp from "react-floating-whatsapp";

const API_URL = process.env.REACT_APP_API_URL || "";

export default function UserFooter() {
	const [mailId, setMailId] = useState<any>("");
	const [modalShow, setModalShow] = React.useState<boolean>(false);

	const [mailResponse, setMailResponse] = useState<any>("");

	const handleMail = (e: any) => {
		e.preventDefault();

		axios
			.post(`${API_URL}/v1/user/subscribe`, { email: mailId })
			.then((res: any) => {
				// return setMailResponse(res.data.message)
				return alert(res.data.message);
			})
			.then(() => {
				setMailId("");
			});
	};

	return (
		<>
			<div className="newsletter-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="newsletter-box">
								<div className="news-title">
									<img
										src={toAbsoluteUrl("/media/img/newsletter.png")}
										alt="MyResorts.in"
									/>
									<h3>Join Our Community</h3>
								</div>
								{/* {mailResponse} */}
								<div className="email-field">
									<form onSubmit={handleMail} className="news-letter-form">
										<input
											type="email"
											placeholder="Email Address"
											value={mailId}
											onChange={(e) => setMailId(e.target.value)}
										/>
										<button>sign me up</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="foot-topbg">
				<img
					src={toAbsoluteUrl("/media/img/foot-top-bg1.png")}
					alt="MyResorts.in"
				/>
			</div>
			<div className="footer-container">
				<div className="container">
					<Row>
						<Col lg="4" xs="12">
							<div className="foot-logo">
								<a href="/home">
									<img
										src={toAbsoluteUrl("/media/img/foot-logo.png")}
										alt="MyResorts.in"
									/>
								</a>
								<p>India's only WildLife Resort Booking Platform.</p>
								<div className="social">
									<a href="https://www.facebook.com/myresorts.in/">
										{" "}
										<img
											src={toAbsoluteUrl("/media/img/Facebook.png")}
											alt="MyResorts.in"
										/>
									</a>
									<a href="https://www.instagram.com/myresorts.in/?hl=en">
										{" "}
										<img
											src={toAbsoluteUrl("/media/img/Instagram.png")}
											alt="MyResorts.in"
										/>
									</a>
									<a href="https://twitter.com/MyresortsIn">
										{" "}
										<img
											src={toAbsoluteUrl("/media/img/Twitter.png")}
											alt="MyResorts.in"
										/>
									</a>
									<a href="https://www.youtube.com/@myresorts">
										{" "}
										<img
											src={toAbsoluteUrl("/media/img/Youtube.png")}
											alt="MyResorts.in"
										/>
									</a>
								</div>
							</div>
						</Col>
						<Col lg="2" xs="6">
							<div className="foot-links foot-mobile">
								<h2>Links</h2>
								<ul>
									<li>
										<Link to="/home">Home</Link>
										{/* <a href="/home">Home</a> */}
									</li>
									<li>
										<Link to="/rooms">Find Resorts</Link>
										{/* <a href="/rooms">Find Resorts</a> */}
									</li>
									<li>
										<Link to="/blog">Blog</Link>
										{/* <a href="/blog">Blog</a> */}
									</li>
									<li>
										<Link to="/contactus#faq">FAQ's</Link>
										{/* <a href="/contactus#faq">FAQ's</a> */}
									</li>
								</ul>
							</div>
						</Col>
						<Col lg="2" xs="6">
							<div className="foot-links foot-mobile1">
								<h2>About</h2>
								<ul className="about-foot-link">
									<li>
										<Link to="/contactus">Contact Us</Link>
										{/* <a href="/contactus">Contact Us</a> */}
									</li>
									<li>
										<Link to="/termsofuse">Terms of Use</Link>
										{/* <a href="/termsofuse">Terms of Use</a> */}
									</li>
									<li>
										<Link to="/privacyPolicy">Privacy</Link>
										{/* <a href="/privacyPolicy">Privacy</a> */}
									</li>
									<li>
										<Link to="/RefundandCancellationPolicy">Refund & Cancellation Policy</Link>
										{/* <a href="/RefundandCancellationPolicy">
											Refund & Cancellation Policy
										</a> */}
									</li>
								</ul>
							</div>
						</Col>
						<Col lg="4" xs="12">
							<div className="foot-links address-wrap">
								<h2>CONTACT US</h2>
								<div className="address">
									<span>
										Feel free to get in touch with us via phone or send us a
										message
									</span>
									<div>
										<p>+91-9325922961</p>
										<p>contact@myresorts.in</p>
									</div>
									<div>
										{/* <p>
											Plot No - B12 Umiya Colony near Amazon warehouse Wathoda
											Nagpur 440008
										</p> */}
									</div>
								</div>
							</div>
						</Col>
					</Row>
					<div className="row"></div>
				</div>
			</div>
			<div className="copyright">
				<div className="container">
					<div className="col-lg-12 copy-right-box">
						<p>©MyResorts.in, a product of VisionMonks Pvt Ltd</p>
						<div>
							{/* <a href="#!" >
								Terms of Use
							</a> */}
							{/* <a href="#!">Security</a>
              <a href="#!">Terms</a> */}
							<Button
								variant="link"
								style={{ color: "#fff" }}
								onClick={() => TermsOfUse}
							>
								{/* Terms of Use */}
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div>
				<FloatingWhatsApp
					accountName="MyResorts.in"
					phoneNumber="+919325922961"
					statusMessage="Replies within 30 Minutes!"
					chatMessage="Welcome to MyResorts.in! Are you looking for a perfect jungle gateway?"
					avatar="/media/img/favicons/favicon.ico"
					className="floating"
					allowClickAway
					notificationSound
					allowEsc
				/>
			</div>
		</>
	);
}
